import React from 'react'
import { useDashboard } from '../../../../contexts/DashboardProvider';



export default function StatsPanel({ advancedStatsCustomer }) {
    const { colorTheme } = useDashboard()

    return (
        <div className='flex flex-col tracking-tight'>
            <div className='flex flex-row justify-between ' >
                <div className='flex card shadow-sm mb-3 p-4' style={{ borderRadius: "15px", width: '47%' }}>
                    <div >
                        <div className='flex mt-2' style={{ fontSize: "18px", fontWeight: '600', color: colorTheme.primaryColor }}>
                            € {advancedStatsCustomer.totalIncome.toFixed(2)}
                        </div>
                        <div className='flex text-secondary '
                            style={{ fontSize: '15px' }}>
                            Totale
                        </div>
                    </div>
                </div>
                <div className='flex card shadow-sm mb-3 p-4' style={{ borderRadius: "15px", width: '47%' }}>
                    <div >
                        <div className='mt-2 ' style={{ fontSize: "18px", fontWeight: '600', color: colorTheme.primaryColor }}>
                            € {advancedStatsCustomer.ficheCustomer.toFixed(2)}
                        </div>
                        <div className='flex text-secondary '
                            style={{ fontSize: '15px' }}>
                            Fiche
                        </div>
                    </div>
                </div>
            </div>


            <div className='flex flex-row justify-content-between'>
                <div className='flex card shadow-sm mb-3 p-4' style={{ borderRadius: "15px", width: '47%' }}>
                    <div >
                        <div className='mt-2' style={{ fontSize: "18px", fontWeight: '600',  color: colorTheme.primaryColor }}>
                            € {advancedStatsCustomer.servicesIncome.toFixed(2)}
                        </div>
                        <div className='flex text-secondary '
                            style={{ fontSize: '15px' }}>
                            Servizi
                        </div>
                    </div>
                </div>

                <div className='flex card shadow-sm mb-3 p-4' style={{ borderRadius: "15px", width: '47%' }}>
                    <div >
                        <div className='mt-2 ' style={{ fontSize: "18px", fontWeight: '600',  color: colorTheme.primaryColor }}>
                            € {advancedStatsCustomer.productsIncome.toFixed(2)}
                        </div>
                        <div className='flex text-secondary '
                            style={{ fontSize: '15px' }}>
                            Prodotti
                        </div>
                    </div>
                </div>



            </div>


            <div className='flex flex-row justify-content-between'>
                <div className='flex card shadow-sm mb-3 p-4' style={{ borderRadius: "15px", width: '47%' }}>
                    <div >
                        {/* {console.log(advancedStatsCustomer)} */}
                        <div className='mt-2 ' style={{ fontSize: "18px", fontWeight: '600',  color: colorTheme.primaryColor }}>
                             € {advancedStatsCustomer.giftCardsIncome.toFixed(2)} 
                        </div>
                        <div className='flex text-secondary '
                            style={{ fontSize: '15px' }}>
                            Carte Regalo
                        </div>
                    </div>
                </div>

            </div>



        </div>


    )
}