import React from 'react'
import {Navigate, Outlet} from 'react-router-dom'
import { useAuthentication } from '../contexts/AuthenticationProvider'

export default function PrivateRoute({component: Component, ...rest}
    ) {
    const {isAuthenticated} = useAuthentication()

    return  isAuthenticated ? <Outlet/> : <Navigate to="/login"/>   
}
    