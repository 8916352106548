import React from 'react'
import { useState } from "react"
import { Modal, Button, Container, InputGroup, FormControl, ListGroup } from "react-bootstrap"
import { AiOutlinePlusCircle } from "react-icons/ai"
import { BsCheckCircle, BsCircle } from 'react-icons/bs'
import { useDatabase } from '../../../../contexts/DatabaseProvider'
import { useDashboard } from '../../../../contexts/DashboardProvider'

export default function NewService({ setServicesSelected, servicesSelected }) {
    const [modalShow, setModalShow] = useState(false)
    const { services } = useDatabase()
    const { colorTheme } = useDashboard()
    const [selectedServicesModal, setSelectedServicesModal] = useState(services.reduce((servicesAll, serviceT) => { return { ...servicesAll, [serviceT.id]: false } }, {}))
    const [searchService, setSearchedService] = useState('')

    const handleServicesSelection = (id) => {
        setSelectedServicesModal(idT => {
            //  console.log(idT)
            const idUpdate = { ...idT }
            idUpdate[id] = !idUpdate[id]
            return idUpdate
        })
    }

    const checkIfActive = (id) => {
        //console.log(selectedServicesModal[id]) 
        return selectedServicesModal[id]
    }

    const addService = () => {
        const newServicesSelected = services.filter(serviceT => { return selectedServicesModal[serviceT.id] })
            .reduce((servicesAll, serviceT) => { return [...servicesAll, { ...serviceT, operator: "---", discount: 0, quantity: 1, date: new Date().toISOString(), type: 'service' }] }, [])
        // console.log('selection', newServicesSelected)
        setServicesSelected([...servicesSelected, ...newServicesSelected])
        setModalShow(false)
        setSelectedServicesModal({})
        setSearchedService('')
    }


    return (

        <>
            <Button variant="white rounded-pill flex items-center justify-center" onClick={() => setModalShow(true)}>
                <AiOutlinePlusCircle color={colorTheme.primaryColor} />
                <span className='ms-2' style={{ color: colorTheme.primaryColor }}>Aggiungi servizio</span>
            </Button>

            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="sg"
                aria-labelledby="contained-modal-title-vcenter" >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Lista Servizi
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: "0px" }}>
                    <Container style={{ padding: "1px" }}>
                        {/* {console.log('internal', selectedServicesModal)} */}
                        <div className='p-3 ps-4 pe-4' style={{ backgroundColor: "whitesmoke" }}>
                            <InputGroup >
                                <FormControl value={searchService} onChange={e => setSearchedService(e.target.value)} variant='secondary' style={{ borderRadius: "20px", border: "0px" }} placeholder="Nome servizio" />
                            </InputGroup>
                        </div>
                        <ListGroup>
                            <div >
                                {services.map((serviceT) => {
                                    if (serviceT["name"].toLowerCase().includes(searchService.toLowerCase())) {
                                        return (
                                            <ListGroup.Item
                                                action
                                                key={serviceT.id}
                                                style={{ fontSize: "18px", border: "0em" }}
                                                onClick={() => { handleServicesSelection(serviceT.id) }}>

                                                <div className='flex flex-row  items-center gap-2 p-1'>
                                                        {checkIfActive(serviceT.id) ? <BsCheckCircle color={colorTheme.primaryColor} /> : <BsCircle color="gray" />}
                                                    
                                                    <div className='flex flex-fill'>
                                                        {serviceT.name}
                                                    </div>
                                                    <div>
                                                        € {Number(serviceT.sell_price).toFixed(2)}
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                        )
                                    }

                                })}
                            </div>
                        </ListGroup>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={colorTheme.button} onClick={() => addService()} >Avanti</Button>
                </Modal.Footer>
            </Modal>

        </>


    );

}