import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function TopBarModal({ closingIcon = undefined, closeModal, activateBack, backIcon, title, classNameTitle, onClickBack = () => {} }) {
    return (
        <div className="flex flex-row items-center justify-between rounded-t-md">
            <div className={`flex grow text-[22px] !font-medium text-black/80 ${classNameTitle}`}>
                {activateBack && (
                    <button onClick={onClickBack} className="!me-4">
                        <FontAwesomeIcon icon={backIcon} size="sm" className="text-black/30 hover:text-black/50" />
                    </button>
                )}

                <div>{title}</div>
            </div>
            {closingIcon && (
                <button
                    onClick={() => {
                        closeModal()
                    }}
                >
                    <FontAwesomeIcon icon={closingIcon} size="xl" className="text-black/30 hover:text-black/50" />
                </button>
            )}
        </div>
    )
}
