import { useEffect } from "react"
import * as Sentry from "@sentry/react"
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom"
import { sentryEnvMode } from "./helper.js"
// console.log(sentryEnvMode)

Sentry.init({
    dsn: "https://cf7494c5a20a6c83f27bc42c50e8eeec@o4506694619365376.ingest.us.sentry.io/4507378667880448",
    integrations: [
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guidPes/react/configuration/integrations/react-router/
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration(),
    ],
    environment: sentryEnvMode,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["192.0.0.1", /^https:\/\/go\.ldnhair\.com\/api/],
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
})
