import React from "react"


export default function InputBox({ className, classNameInput, value, label, name, placeholder, type, onChange, suffix = undefined, prefix = undefined, isTextArea = false, classNameBox}) {
    const InputComponent = isTextArea ? 'textarea' : 'input';

    return (
        <div className={"flex flex-col " + className}>
            <div className="!ms-3 text-xs text-black/70 ">{label}</div>
            <div className={`flex flex-row rounded-2xl text-sm h-full outline outline-1 outline-slate-300 overflow-visible  ${classNameBox}`}>
                {prefix !== undefined && <div className="border-r-1 flex h-full border-r">{prefix}</div>}
                <InputComponent className={"flex " + (isTextArea ? "w-full h-full py-2" : "w-[100%] h-10") + " px-3 outline-0 " + classNameInput} type={type} name={name} placeholder={placeholder} value={value} onChange={onChange} />
                {suffix && <div className="flex h-full items-center rounded-e-2xl bg-slate-50 px-2 text-black/90">{suffix}</div>}
            </div>
        </div>
    )
}