import { parsePhoneNumber, isValidPhoneNumber, AsYouType } from "libphonenumber-js"
import { DateTime, Duration, Interval } from "luxon"

export function generateId(prefix) {
    return Math.random()
        .toString(36)
        .replace("0.", prefix || "")
}

export const HEIGHT_HOUR = 90

export function dateToLayoutAppointments(appDateString, appDuration, calendarDimensions, safeSpace = 10, SLOT_SIZE) {
    const appDate = DateTime.fromISO(appDateString)
    const widthHour = 40 // It is the wisth of the hour bar on the left
    // it is the space on the right of the rectangle

    const widthDay = (calendarDimensions.width - widthHour) / 7 - 1 - safeSpace
    const leftDay = widthHour + 1 + (appDate.weekday - 1) * (1 + safeSpace) + widthDay * (appDate.weekday - 1) + 2
    let heightApp = (appDuration * (calendarDimensions.height / 24)) / 60

    if (appDuration < SLOT_SIZE) heightApp = HEIGHT_HOUR / (60 / SLOT_SIZE)

    const startAppMinutes = appDate.hour * 60 + appDate.minute
    const startApp = (startAppMinutes * (calendarDimensions.height / 24)) / 60

    return { width: widthDay, height: heightApp, left: leftDay, top: startApp }
}

export function currentTimeToLine(calendarDimensions) {
    const widthHour = 40
    const safeSpace = 0 // it is the space on the right of the rectangle
    const currentTime = DateTime.now()
    const topMargin = ((currentTime.hour * 60 + currentTime.minute) * (calendarDimensions.height / 24)) / 60
    const widthDay = (calendarDimensions.width - widthHour) / 7 - 1 - safeSpace
    const leftDay = widthHour + 1 + (currentTime.weekday - 1) * (1 + safeSpace) + widthDay * (currentTime.weekday - 1)

    return { width: widthDay, left: leftDay, top: topMargin }
}

export function dateToLayoutOffTime(workingTime, calendarDimensions, anticipateClosure) {
    const startDay = DateTime.local(1970, 1, 1, 0, 0).startOf("day")
    const endDay = DateTime.local(1970, 1, 1, 23, 59).endOf("day")

    const workingTimeStartDay = DateTime.local(1970, 1, 1, Number(workingTime.start_time.split(":")[0]), Number(workingTime.start_time.split(":")[1]))
    const workingTimeEndDay = DateTime.local(1970, 1, 1, Number(workingTime.end_time.split(":")[0]), Number(workingTime.end_time.split(":")[1]))

    const widthHour = 40
    const widthDay = (calendarDimensions.width - widthHour) / 7 - 1
    const leftDay = widthHour + 1 + (workingTime.weekday - 1) * 1 + widthDay * (workingTime.weekday - 1)

    const startOffTimeDuration = workingTimeStartDay.diff(startDay, "minutes").toObject().minutes
    const endOffTimeDuration = endDay.diff(workingTimeEndDay, "minutes").toObject().minutes

    let heightStartOffTime = (startOffTimeDuration * (calendarDimensions.height / 24)) / 60
    let heightendOffTime = ((endOffTimeDuration + anticipateClosure) * (calendarDimensions.height / 24)) / 60

    const startEndMinutes = workingTimeEndDay.hour * 60 + workingTimeEndDay.minute
    const startEndOffTime = ((startEndMinutes - anticipateClosure) * (calendarDimensions.height / 24)) / 60

    return {
        width: widthDay,
        height: { start: heightStartOffTime, end: heightendOffTime },
        left: leftDay,
        top: { start: 0, end: startEndOffTime },
    }
}

export function stringToHslColor(str, s, l) {
    var hash = 0
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }

    var h = hash % 360
    return "hsl(" + h + ", " + s + "%, " + l + "%)"
}

export function groupAppointmentDetailsByCustomer(appointments) {
    return Object.values(
        appointments.reduce((acc, appointment) => {
            const customerId = appointment.customer.id

            if (!acc[customerId]) {
                acc[customerId] = {
                    customer: appointment.customer,
                    appointment_details: [],
                }
            }

            acc[customerId].appointment_details.push(appointment)
            return acc
        }, {})
    )
}

export function onlyNumericValue(value) {
    // console.log(value)
    return /^[0-9]*$/.test(value)
}

export function onlyPhoneValue(value) {
    // console.log(value)
    return /^[0-9+\s]*$/.test(value)
}

export function defaultAlertInfo() {
    return { status: false, msg: "", mode: "" }
}

export function formatPhoneNumber(phoneNumberString, countryISO) {
    return new AsYouType(countryISO).input(phoneNumberString)
}

export function handleListofDictionariesChangeNoState(
    key,
    value,
    instance,
    currentInstances,
    isValueValid = () => {
        return true
    }
) {
    if (!isValueValid(value)) return

    const updatedInstances = currentInstances.map((currentInstance) => {
        if (currentInstance.id !== instance.id) {
            return currentInstance
        }
        return { ...currentInstance, [key]: value }
    })

    return updatedInstances
}

export function handleListofDictionariesChange(
    key,
    value,
    instance,
    setInstances,
    isValueValid = () => {
        return true
    }
) {
    if (!isValueValid(value)) return
    // console.log("happen")
    setInstances((currentInstances) => {
        const updatedInstances = currentInstances.map((currentInstance) => {
            if (currentInstance.id !== instance.id) {
                return currentInstance
            }
            return { ...currentInstance, [key]: value }
        })

        return updatedInstances
    })
}

export function handleDictionaryChanges(key, value, setInstance) {
    setInstance((currentInstances) => {
        return { ...currentInstances, [key]: value }
    })
}

export const days = ["Lun", "Mar", "Mer", "Gio", "Ven", "Sab", "Dom"]

export function capitalizeString(str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export const colors = {
    primary: "#267DFE",
    primaryLight: "#E7F1FF",
    primaryBackground: "#267DFEAA",
    secondary: "#0000004D",
}

export const generateMonthlyDates = (calendarDate) => {
    const caledarDateList = Interval.fromDateTimes(calendarDate.startOf("month"), calendarDate.endOf("month"))
        .splitBy({ day: 1 })
        .map((d) => ({ dateday: d.start }))

    return caledarDateList
}

export function generateFreeTimeSlots(monthYear) {
    const result = []

    // Parse input month and year
    const [month, year] = monthYear.split("-")
    const firstDayOfMonth = DateTime.fromObject({ year: parseInt(year), month: parseInt(month), day: 1 })
    const lastDayOfMonth = firstDayOfMonth.endOf("month")

    // Loop through each day of the month
    for (let currentDate = firstDayOfMonth; currentDate <= lastDayOfMonth; currentDate = currentDate.plus({ days: 1 })) {
        const dateDay = currentDate
        const freeTimeSlots = generateTimeSlots(dateDay)

        result.push({ dateday: dateDay, free_timeslots: freeTimeSlots })
    }

    return result
}

export function generateTimeSlots(dateDay) {
    const timeSlots = []
    let startTime
    if (dateDay) startTime = dateDay.set({ hour: 6, minute: 0, second: 0, millisecond: 0 }) // Set start time to 6:00 AM
    else startTime = DateTime.now().set({ hour: 6, minute: 0, second: 0, millisecond: 0 })

    for (let i = 0; i < 64; i++) {
        // 38 intervals from 6:00 AM to 10:00 PM
        const timeSlot = startTime.plus({ minutes: i * 15 })
        timeSlots.push(timeSlot)
    }

    return timeSlots
}

// export function handleStartEndHourSelection(type, timeslot, workingTime, setWorkingTime) {
//     if ((type === "start_time" && timeslot > workingTime.end_time) || (type === "end_time" && timeslot < workingTime.start_time)) {
//         setWorkingTime((prev) => ({ ...prev, start_time: timeslot, end_time: timeslot }))
//     }
//     setWorkingTime((prev) => ({ ...prev, [type]: timeslot }))
// }

export function handleStartEndSelection(type, boundaries, value, workingTime, setWorkingTimeNormal, setWorkingTimeConstraint) {
    if ((type === boundaries[0] && value > workingTime[boundaries[1]]) || (type === boundaries[1] && value < workingTime[boundaries[0]])) {
        setWorkingTimeConstraint(boundaries[0], boundaries[1], value)
        // setWorkingTime((prev) => ({ ...prev, [boundaries[0]]: value, [boundaries[1]]: value }))
    }
    setWorkingTimeNormal(type, value)
    // setWorkingTime((prev) => ({ ...prev, [type]: value }))
}

export function formatRedableDate(date) {
    return date?.toLocaleString({ month: "short", day: "numeric", year: "numeric" })
}

export const defaultSelectedDayTimeInfo = { dayTimestamp: "not-selected", timeSlot: "not-selected" }

export const defaultSelectedCustomer = { email: "", first_name: "", last_name: "", phone: "", countryCode: { iso2: "it", dialCode: "39" } }

// const BASE_URL = "http://127.0.0.1:8001/api/"

// export async function getData(url) {
//     const response = await fetch(BASE_URL + url)
//     return await response.json()
// }

// export async function postData(url, content) {
//     const requestOptions = {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify(content),
//     }

//     const response = await fetch(BASE_URL + url, requestOptions)
//     return await response.json()
// }

// export async function patchData(url, content) {
//     const requestOptions = {
//         method: "PATCH",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify(content),
//     }

//     const response = await fetch(BASE_URL + url, requestOptions)

//     return await response.json()
// }

// export async function putData(url, content) {
//     const requestOptions = {
//         method: "PUT",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify(content),
//     }

//     const response = await fetch(BASE_URL + url, requestOptions)
//     // console.log(response)
//     return await response.json()
// }

export function getStartAndEndOfWeek(date) {
    return { weekStart: date.startOf("week"), weekEnd: date.endOf("week") }
}

export function getWeekDates(weekStart) {
    return Array.from(Array(7).keys()).map((weekday) => {
        return weekStart.startOf("week").plus({ day: weekday })
    })
}

export function getURLWeekInterval(date) {
    return getStartAndEndOfWeek(date).weekStart.toISO() + "/" + getStartAndEndOfWeek(date).weekEnd.toISO() + "/"
}

export function formatWeeklyString(weekDates) {
    const weekIntervalString =
        weekDates[0].toLocaleString({ month: "short", day: "numeric" }, { locale: "it" }) + " - " + weekDates[weekDates.length - 1].toLocaleString({ month: "short", day: "numeric" }, { locale: "it" })
    return weekIntervalString.replace(/\b\w/g, (x) => x.toUpperCase())
}

export function getURLDay(date) {
    return date.toLocaleString(DateTime.DATE_SHORT, { locale: "it" }).replaceAll("/", "-") + "/"
}

export function getURLMonth(date) {
    return getURLDay(date).split("-")[1] + "-" + getURLDay(date).split("-")[2]
}

export function range(value) {
    return [...Array(value).keys()]
}

export function generateCalendarTimeslots(weekDates, slotSize) {
    let timeslots = []

    for (const day of weekDates) {
        let dayTimeslots = []
        for (const timeslot of range(24 * (60 / slotSize))) {
            dayTimeslots.push(day.plus({ minutes: timeslot * slotSize }).toString())
        }
        timeslots.push(dayTimeslots)
    }

    return timeslots
}

export function isAvailableSelectionNumber(dateDay) {
    return dateDay.free_timeslots.length > 0
}

export function isSelectedSelectionNumber(dateDay, infoData) {
    return infoData.selectedDayTimeInfo.dayTimestamp === dateDay.dateday.toMillis()
}

export function onClickSelectionNumber(dateDay, setInfoData) {
    setInfoData((prev) => ({ ...prev, selectedDayTimeInfo: { dayTimestamp: dateDay.dateday.toMillis(), timeSlot: "not-selected" } }))
}

export function getSelectedAvailableTimeslotsOfADay(availableTimeslots, infoData) {
    return availableTimeslots.find((day) => {
        if (day.dateday.toMillis() === infoData.selectedDayTimeInfo.dayTimestamp) {
            return day.free_timeslots
        }
    })
}

export function getIndexofSelectedTimeslot(availableTimeslots, infoData) {
    const selectedAvailableTimeslots = getSelectedAvailableTimeslotsOfADay(availableTimeslots, infoData)
    let selectedTimeSlotIdx = selectedAvailableTimeslots?.free_timeslots.findIndex((timeslot) => timeslot.toMillis() === infoData.selectedDayTimeInfo.timeSlot)
    let availableTimeslot = true
    if (selectedTimeSlotIdx === -1) {
        const timeSlotDiffs = selectedAvailableTimeslots?.free_timeslots.map((timeslot) => Math.abs(timeslot.toMillis() - infoData.selectedDayTimeInfo.timeSlot))
        const closestTimeSlot = Math.min(...timeSlotDiffs)
        selectedTimeSlotIdx = timeSlotDiffs.indexOf(closestTimeSlot)
        availableTimeslot = false
    }
    return { selectedTimeSlotIdx, availableTimeslot }
}

export function checkOverlap(time1, time2) {
    // Extract start and end times from each object
    const startTime1 = time1.start_time
    const endTime1 = time1.end_time
    const startTime2 = time2.start_time
    const endTime2 = time2.end_time

    // console.log(startTime1, endTime2, startTime2, endTime1)

    // Check for overlap
    if (startTime1 < endTime2 && startTime2 < endTime1) {
        // console.log("overlap")
        return true // Overlapping
    } else {
        return false // Not overlapping
    }
}

export function validCustomerInfo(customer, setAlertInfo) {
    const emailRegex = /^(.+)@(.+)\.(.+)$/

    if (!emailRegex.test(customer.email) && customer.email !== "") {
        setAlertInfo({ status: true, msg: "Email non valida, controlla il formato", mode: "danger" })
        return false
    }

    if (!isValidPhoneNumber(customer.countryCode.dialCode + customer.phone, customer.countryCode.iso2.toUpperCase())) {
        setAlertInfo({ status: true, msg: "Numero di telefono non valido", mode: "danger" })
        return false
    }

    return true
}
