import React from 'react'
import { Image } from 'react-bootstrap'
import { adjustLuminosity, stringToHslColor } from '../../../../../helper'


 function ListGroupItemIconBase({ item, name, size = 60, textSize = "32px" }) {
    return (
        <>
            {item?.image ? (
                <Image src={item.image} width={size} height={size} alt={"Image of " + item.name}  />
            ) : (
                <div
                    className="flex grow items-center justify-center "
                    style={{ fontSize: textSize, backgroundColor: item?.color ? adjustLuminosity(item.color, -10) : stringToHslColor(name, 30, 80), color: "white" }}
                >
                    {name?.charAt(0)}
                </div>
            )}
        </>
    )
}

function IconItemHolder({ children, className }) {
    return <div className={`flex rounded-full w-[45px] h-[45px] overflow-clip ${className}`}>{children}</div>
}

export default  function IconHolder({ className, item, name, textSize }) {
    return (
        <IconItemHolder className={className}>
            <ListGroupItemIconBase item={item} name={name} textSize={textSize} />
        </IconItemHolder>
    )
}