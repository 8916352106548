import { IconButton } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { Button, FormControl, InputGroup } from "react-bootstrap"
import { FiSend } from "react-icons/fi"
import { useDashboard } from "../../../../contexts/DashboardProvider"
import { useMessages } from "../../../../contexts/MessagesProvider"

export default function MessageInput() {
    const { messageText, setMessageText } = useDashboard()
    const { sendMessage } = useMessages()

    const isDisabled = messageText === "" ? true : false


    return (
        <div className="d-flex flex-row bg-white align-items-center" style={{ borderRadius: "20px", height: "8vh", boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)" }}>
            <div className="d-flex flex-fill  ">
                <InputGroup>
                    <FormControl
                        value={messageText}
                        onChange={(e) => {
                            setMessageText(e.target.value)
                        }}
                        as="textarea"
                        style={{ border: "0", borderRadius: "20px", fontSize: "14px", outline: "none", boxShadow: "none" }}
                        placeholder="Scrivi messaggio..."
                    ></FormControl>
                </InputGroup>
            </div>
            <button
                className={`flex rounded-full w-[50px] h-[50px] me-2 justify-center items-center  ${isDisabled ? "bg-gray-400" : "bg-primary"}`}
                // style={{ border: "0", borderRadius: "20px", fontSize: "14px", outline: "none", boxShadow: "none" }}
                disabled={isDisabled}
                onClick={() => {
                    sendMessage(messageText)
                    setMessageText("")
                }}
            >
                <FiSend color="white" size={20} />
            </button>
        </div>
    )
}
