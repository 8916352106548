import React, { useEffect, useState } from "react"
import { useDashboard } from "../../../../contexts/DashboardProvider"
import { useDatabase } from "../../../../contexts/DatabaseProvider"
import { AiOutlineDelete } from "react-icons/ai"

import { Modal, Button, Spinner, Alert } from "react-bootstrap"
import { getDataTime, urls, patchData } from "../../../../helper"
import { IconButton } from "@mui/material"

export default function BillingSummary() {
    const defaultAlertBox = { mode: "", message: "", enabled: false }

    const { updateBillings, updateStats, billings, updateInventory, incomeStatsDaily } = useDatabase()
    const { selectedBilling, setSelectedBilling, colorTheme } = useDashboard()
    const [modalShow, setModalShow] = useState(false)
    const [alertBox, setAlertBox] = useState(defaultAlertBox)
    const [isProcessingTransaction, setProcessingTransaction] = useState(false)

    useEffect(() => {
        updateBillings()
    }, [])

    const deleteInvoice = async () => {
        setProcessingTransaction(true)
        const deletedData = {
            deleted: true,
        }

        const response = await patchData(urls.smartsaleDelete + selectedBilling.smartsale.id + "/", deletedData)
        // console.log(response)

        if (response.type === "success") {
            setModalShow(false)
            updateBillings()
            updateStats(new Date())
            updateInventory()
            setSelectedBilling("not-selected")
        } else {
            setModalShow(false)
            setAlertBox({ mode: "danger", message: response.msg, enabled: true })
        }
        setProcessingTransaction(false)
    }

    if (selectedBilling === "not-selected") {
        return (
            <div className="d-flex justify-content-center " style={{ fontSize: "48px", paddingTop: "300px", color: "lightgray" }}>
                {billings.length === 0 ? "Nessun scontrino" : "Seleziona scontrino"}
            </div>
        )
    } else if (selectedBilling === "daily-summary") {
        return (
            <div className="d-flex flex-column ">
                <div className="d-flex  justify-content-center pt-4">
                    <div className="d-flex flex-column" style={{ width: "55%" }}>
                        <div className="d-flex card p-3" style={{ borderRadius: "15px" }}>
                            <div className="d-flex mb-4 align-items-center " style={{ fontSize: "16px" }}>
                                <div className="d-flex flex-fill" style={{ fontSize: "18px", fontWeight: "600" }}>
                                    Riepilogo Giornaliero
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mb-2" style={{ fontSize: "16px" }}>
                                <div>Entrate Contanti</div>
                                <div>€ {incomeStatsDaily.dailyIncomeReceiptCash.toFixed(2)}</div>
                            </div>
                            <div className="d-flex justify-content-between mb-2" style={{ fontSize: "16px" }}>
                                <div>Entrate POS</div>
                                <div>€ {incomeStatsDaily.dailyIncomeReceiptElectronic.toFixed(2)}</div>
                            </div>
                            <div className="d-flex justify-content-between mb-3" style={{ fontSize: "16px", color: colorTheme.primaryColor, fontWeight: "600" }}>
                                <div>Entrate</div>
                                <div>€ {incomeStatsDaily.dailyIncomeReceipt.toFixed(2)}</div>
                            </div>
                            <div className="d-flex justify-content-between mb-2" style={{ fontSize: "16px", color: "black", fontWeight: "600" }}>
                                <div>Altre Entrate</div>
                                <div>€ {incomeStatsDaily.dailyIncomeNoReceipt.toFixed(2)}</div>
                            </div>

                            <div>
                                <hr className="mt-4 mb-4 bg-dark border-1 border-top border-secondary"></hr>
                            </div>

                            <div className="d-flex justify-content-between mb-3" style={{ fontSize: "18px", fontWeight: "500" }}>
                                <div>Totale Entrate</div>
                                <div>€ {incomeStatsDaily.dailyTotalIncome.toFixed(2)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="d-flex flex-column ">
            <div className="d-flex  justify-content-center pt-4">
                <div className="d-flex flex-column" style={{ width: "55%" }}>
                    <div className="d-flex ">
                        <Alert show={alertBox.enabled} className="d-flex text-center  flex-fill" variant={alertBox.mode}>
                            {alertBox.message}
                        </Alert>
                    </div>
                    <div className="d-flex card p-3" style={{ borderRadius: "15px" }}>
                        <div className="d-flex mb-4 align-items-center " style={{ fontSize: "16px" }}>
                            <div className="d-flex flex-fill" style={{ fontSize: "18px", fontWeight: "600" }}>
                                {selectedBilling.customer}
                            </div>
                            <div>
                                <IconButton
                                    onClick={() => {
                                        setModalShow(true)
                                    }}
                                >
                                    <AiOutlineDelete className="me-2" color="tomato" size={24} />
                                </IconButton>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-3" style={{ fontSize: "16px" }}>
                            <div>Data:</div>
                            <div>{getDataTime(selectedBilling.smartsale.date)}</div>
                        </div>
                        <div className="d-flex justify-content-between" style={{ fontSize: "16px" }}>
                            <div>Documento #:</div>
                            <div>{selectedBilling.smartsale.progressive_number}</div>
                        </div>
                        <div>
                            <hr className="mt-4 mb-4 bg-dark border-1 border-top border-secondary"></hr>
                        </div>
                        {selectedBilling.smartsale_details.map((smartsale_detail, index) => {
                            return (
                                <div key={index}>
                                    <div className="d-flex justify-content-between mb-1" style={{ fontSize: "16px" }}>
                                        <div>
                                            {smartsale_detail.name} x{smartsale_detail.quantity}:
                                        </div>
                                        <div>€ {smartsale_detail.sell_price.toFixed(2)}</div>
                                    </div>
                                    <div className="mb-2" style={{ color: colorTheme.primaryColor }}>
                                        {smartsale_detail.operator}
                                    </div>
                                </div>
                            )
                        })}

                        <div>
                            <hr className="mb-4 bg-dark border-1 border-top border-secondary"></hr>
                        </div>

                        <div className="d-flex justify-content-between mb-2" style={{ fontSize: "16px" }}>
                            <div>Totale parziale</div>
                            <div>€ {selectedBilling.smartsale.partial_amount.toFixed(2)}</div>
                        </div>
                        <div className="d-flex justify-content-between mb-3" style={{ fontSize: "16px" }}>
                            <div>Sconto</div>
                            <div className="text-danger">€ {selectedBilling.smartsale.total_discount.toFixed(2)}</div>
                        </div>

                        <div className="d-flex justify-content-between mb-3" style={{ fontSize: "18px", fontWeight: "600" }}>
                            <div>Netto a pagare</div>
                            <div>€ {selectedBilling.smartsale.total_amount.toFixed(2)}</div>
                        </div>
                        <div className="d-flex justify-content-between mb-2" style={{ fontSize: "16px" }}>
                            <div>Contanti:</div>
                            <div>€ {selectedBilling.smartsale.payment_cash.toFixed(2)}</div>
                        </div>
                        <div className="d-flex justify-content-between mb-2" style={{ fontSize: "16px" }}>
                            <div>Carta:</div>
                            <div>€ {selectedBilling.smartsale.payment_electronic.toFixed(2)}</div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={modalShow} onHide={() => setModalShow(false)} size="sg" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title id=" d-flex text-center contained-modal-title-vcenter">Cancella scontrino ?</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: "0px" }}>
                    <div className=" text-center p-3" style={{ fontSize: "17px" }}>
                        Sei sicuro di volere cancellare questo scontrino? La cancellazione è irreversibile
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-center flex-fill">
                        <Button variant="light" className="me-3" onClick={() => setModalShow(false)}>
                            Annulla
                        </Button>
                        <Button
                            variant="danger"
                            disabled={isProcessingTransaction}
                            onClick={() => {
                                deleteInvoice()
                            }}
                        >
                            {isProcessingTransaction && <Spinner as="span" animation="border" size="sm" role="status" className="me-1" />}
                            Avanti
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
