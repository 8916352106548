import { IconButton } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from "react"
import { FormControl, InputGroup, Dropdown, Col, Row } from "react-bootstrap"
import { AiOutlineDelete } from 'react-icons/ai'
import { MdSchool } from 'react-icons/md'
import { useDashboard } from '../../../../contexts/DashboardProvider'

export default function GiftCard({ operators, giftCardSelected, setGiftCardsSelected }) {
    const [operatorSelected, setOperatorSelected] = useState({ id: '', name: '---' })
    const [giftCard, setGiftCard] = useState(giftCardSelected)


    useEffect(() => {
        setGiftCard({ ...giftCardSelected })
    }, [giftCardSelected])


    const removeGiftCard = () => {
        setGiftCardsSelected(giftCardsT => { return [...giftCardsT.filter(giftCardTT => { return giftCardTT !== giftCardSelected })] })
    }

    const updateGiftCards = (prevVar, newVar) => {
        setGiftCardsSelected(giftCardsT => {
            giftCardsT.map(giftCardTT => {
                if (giftCardTT === giftCardSelected) {
                    giftCardTT[prevVar] = newVar
                }
                return giftCardTT
            })
            const updateGiftCards = [...giftCardsT]
            return updateGiftCards
        })
    }

    const updatePrice = (newPrice) => {
        const intPrice = Number(newPrice)
        if (intPrice >= 0) {
            updateGiftCards('sell_price', intPrice)
        }
        else {
            updateGiftCards('sell_price', giftCard.sell_price)
        }
    }


    const handleOperatorSelection = (operator) => {
        setOperatorSelected(operator)
        updateGiftCards('operator', operator.id)
    }


    const manageOperatorShown = () => { //If the services are not initialized from the appointments set it to '---'
        if (giftCardSelected.operator !== '---') {
            return operators.filter((operator) => giftCardSelected.operator === operator.id)[0].first_name
        }
        else {
            return '---'
        }
    }

    return (
        <>
            <Row className="p-2 ps-3 pe-3 align-items-center" style={{ fontSize: "15px", margin: "0px" }}>
                <Col lg="9">
                    {`Carta Regalo - ${giftCard.code}`}
                </Col>
                <Col className="d-flex justify-content-end align-items-center">
                    <IconButton size='medium' onClick={() => { removeGiftCard() }}>
                        <AiOutlineDelete color="tomato" />
                    </IconButton>
                </Col>
            </Row>
            <Row className="d-flex flex-row p-3" style={{ backgroundColor: "whitesmoke", margin: "0px", borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px" }} >
                <div className="d-flex flex-row align-items-center mb-3 " >
                    <div className='text-secondary me-3'>
                        VALORE
                    </div>
                    <div className='text-secondary ' style={{ width: "100%" }}>

                        <InputGroup >
                            <InputGroup.Text variant="light bg-white" style={{ borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px", border: "0px" }}>€</InputGroup.Text>
                            <FormControl type="number"
                                value={String(giftCardSelected.sell_price)} onChange={e => updatePrice(e.target.value)}
                                variant='light bg-white'
                                style={{ borderTopRightRadius: "20px", borderBottomRightRadius: "20px", border: "0px", color: "black" }}
                                placeholder={giftCard.sell_price} />
                        </InputGroup>
                    </div>
                </div>
                <div className="d-flex flex-row align-items-center" >
                    <div className='text-secondary me-4'>VENDUTO DA</div>
                    <div className='flex-fill' style={{ width: "0px" }}>
                        <Dropdown  >
                            <Dropdown.Toggle variant='light bg-white' className="rounded-pill" style={{ width: "100%", }} id="dropwn-basic" >
                                <span style={{ color: "black" }} >{manageOperatorShown()} </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {operators.map((operator) => (
                                    <Dropdown.Item
                                        key={operator.id}
                                        onClick={() => { handleOperatorSelection(operator) }}>
                                        {operator.first_name}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>

            </Row>

        </>
    )
}