import React from 'react'
import { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { useDashboard } from '../../../../contexts/DashboardProvider';

import BillingsList from './BillingsList';
import CustomersList from './CustomersList';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div >
                    {children}
                </div>
            )}
        </div>
    );
}

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        style={{ borderTopRightRadius: '15px', borderTopLeftRadius: '15px' }}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))
    ({
        '& .MuiTabs-indicator': {
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'transparent',
        },
        '& .MuiTabs-indicatorSpan': {
            width: '60%',
            backgroundColor: '#0d6efd',
        },
    });

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


export default function EasyRegisterSidebar() {
    const { colorTheme, setCashRegisterMode } = useDashboard()
    const [value, setValue] = useState(0);

    useEffect(() => {
        setCashRegisterMode('new-billing')
    },[])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
      
        <div>
            <StyledTabs variant='fullWidth'
                value={value}
                //TabIndicatorProps={{ style: { backgroundColor: colorTheme.primaryColor } }}
                onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Clienti" onClick={() => { setCashRegisterMode('new-billing') }} className='pt-4 pb-3' style={{ fontSize: "20px", fontWeight: "500", textTransform: 'none', fontFamily: 'Poppins', padding: '0px', margin: '0px', color: value === 0 && colorTheme.primaryColor }} />
                <Tab label="Scontrini" 
                onClick={() => { setCashRegisterMode('billings-list') }} 
                className='pt-4 pb-3' style={{ fontSize: "20px", fontWeight: "500", textTransform: 'none', fontFamily: 'Poppins', padding: '0px', margin: '0px', color: value === 1 && colorTheme.primaryColor }} />
            </StyledTabs>

            <TabPanel value={value} index={0} >
                <CustomersList/>
            </TabPanel>
            <TabPanel value={value} index={1} >
                <BillingsList />
            </TabPanel>
        </div>



    )
}