import React, { useContext, useEffect, useState } from "react"
import { getURLWeekInterval, getURLMonth } from "../utils/helpers"
import { getDataBooking, getData, sendDataBooking, putDataBooking, patchDataBooking, urlsBooking as urls, deleteDataBooking, urls as urlsMain } from "../../../../../helper"
import { DateTime } from "luxon"
import LoadingScreen from "../../../LoadingScreen"
import { useAuthentication } from "../../../../../contexts/AuthenticationProvider"
import { useSalon } from "../../../../../contexts/SalonProvider"
const DatabaseContext = React.createContext()

export function useDatabase() {
    return useContext(DatabaseContext)
}

export function DatabaseProvider({ children }) {
    const { user } = useAuthentication()
    const { selectedSalon } = useSalon()

    const [loading, setLoading] = useState(true)
    const [operators, setOperators] = useState([])
    const [services, setServices] = useState([])
    const [workingHours, setWorkingHours] = useState([])
    const [appointments, setAppointments] = useState([])
    const [appointmentsWaitingList, setAppointmentsWaitingList] = useState([])
    const [monthlyFreeTimeslots, setMonthlyFreeTimeslots] = useState([])
    const [customEvents, setCustomEvents] = useState([])
    const [specialDays, setSpecialDays] = useState([])
    const [bookingParameters, setBookingParameters] = useState([])

    // console.log(appointments)

    async function retrieveWeeklyData(date, operator) {
        const response = await Promise.all([
            retrieveAppointments(date, operator),
            retrieveWorkingHours(date, operator),
            retrieveCustomEvents(date, operator),
            retrieveSpecialDays(date, operator),
            retrieveAppointmentsWaitingList(date, operator),
        ])
        setAppointments(response[0])
        setWorkingHours(response[1])
        setCustomEvents(response[2])
        setSpecialDays(response[3].map((specialDay) => ({ ...specialDay, day: DateTime.fromISO(specialDay.day) })))
        setAppointmentsWaitingList(response[4].map((x) => ({ ...x, date: DateTime.fromISO(x.start_time).startOf("day") })))
        // setAppointmentsWaitingList(response[4])

    }

    async function retrieveAppointmentsWaitingList(date, operator) {
        const app_waiting_list = await getDataBooking(urls.listAppointmentsWaitingList + getURLWeekInterval(date) + operator + "/" + selectedSalon.id + "/")
        return app_waiting_list
    }


    async function retrieveAppointments(date, operator) {
        const app_new = await getDataBooking(urls.listAppointments + "new/" + getURLWeekInterval(date) + operator + "/" + selectedSalon.id + "/")
        return app_new
    }

    async function retrieveWorkingHours(date, operator) {
        return await getDataBooking(urls.workingHours + getURLWeekInterval(date) + operator + "/" + selectedSalon.id + "/")
    }

    async function retrieveCustomEvents(date, operator) {
        return await getDataBooking(urls.customEventsList + getURLWeekInterval(date) + operator + "/" + selectedSalon.id + "/")
    }

    async function retrieveSpecialDays(date, operator) {
        return await getData(urlsMain.retrieveSpecialDaysOperator + "generic/" + getURLWeekInterval(date) + operator + "/" + selectedSalon.id + "/")
    }

    async function retrieveData(url, setState) {
        const data = await getDataBooking(url + selectedSalon.id + "/")
        setState(data)
        return data
    }

    async function retrieveMonthlyFreeTimeslots(date, duration, operator, appointment, customEvent) {
        const url = `${urls.monthlyFreeTimeslots}${date.month}/${date.year}/${String(duration)}/${operator}/${selectedSalon.id}/${appointment}/${customEvent}/`
        const monthlyFreeTimeslots = await getDataBooking(url)

        setMonthlyFreeTimeslots(
            monthlyFreeTimeslots.map((dayTimeslot) => {
                return {
                    free_timeslots: dayTimeslot.free_timeslots.map((x) => DateTime.fromISO(x)),
                    dateday: DateTime.fromISO(dayTimeslot.dateday),
                }
            })
        )
    }

    function cleanupMonthlyFreeTimeslots() {
        setMonthlyFreeTimeslots([])
    }

    async function postAppointment(content) {
        const response = await sendDataBooking(urls.createAppointment, content)
        return response
    }

    async function deleteAppointment(id) {
        const response = await deleteDataBooking(urls.deleteAppointment + id + "/")
        return response
    }

    async function deleteAppointmentsDetailsWaitingList(id){
        const response = await deleteDataBooking(urls.deleteAppointmentsDetailsWaitingList + id + "/")
        return response
    }


    async function postCustomEvent(content) {
        const response = await sendDataBooking(urls.createCustomEvent, content)
        return response
    }

    async function updateCustomEvent(content, id) {
        const response = await putDataBooking(urls.updateCustomEvent + id + "/", content)
        return response
    }

    async function deleteCustomEvent(content, id) {
        const response = await patchDataBooking(urls.updateCustomEvent + id + "/", content)
        return response
    }

    useEffect(() => {
        const handleData = async () => {
            const dataToBeRetrieved = [
                { url: urls.listOperators, setState: setOperators },
                { url: urls.listServices, setState: setServices },
            ]
            const response = await Promise.all(dataToBeRetrieved.map((data) => retrieveData(data.url, data.setState)))
            const bookingParameters = await getDataBooking(urls.retrieveParameters)
            setBookingParameters(bookingParameters)
            const operators = response[0]
            await retrieveWeeklyData(DateTime.now(), user.is_operative ? user.id : operators[0]?.id)
            setLoading(false)
        }

        handleData()
    }, [selectedSalon])

    const value = {
        operators: operators,
        services: services,
        appointments: appointments,
        workingHours: workingHours,
        monthlyFreeTimeslots: monthlyFreeTimeslots,
        retrieveWeeklyData: retrieveWeeklyData,
        retrieveData: retrieveData,
        retrieveMonthlyFreeTimeslots: retrieveMonthlyFreeTimeslots,
        postAppointment: postAppointment,
        deleteAppointment: deleteAppointment,
        customEvents: customEvents,
        postCustomEvent: postCustomEvent,
        updateCustomEvent: updateCustomEvent,
        deleteCustomEvent: deleteCustomEvent,
        cleanupMonthlyFreeTimeslots: cleanupMonthlyFreeTimeslots,
        specialDays: specialDays,
        bookingParameters: bookingParameters,
        appointmentsWaitingList: appointmentsWaitingList,
        deleteAppointmentsDetailsWaitingList: deleteAppointmentsDetailsWaitingList
    }

    return (
        <DatabaseContext.Provider value={value}>
            <LoadingScreen text="Recupero calendario" loading={loading}>
                {children}
            </LoadingScreen>
        </DatabaseContext.Provider>
    )
}
