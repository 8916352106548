import React, { useEffect, useState } from "react"
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip } from "chart.js"
import { Bar } from "react-chartjs-2"
import { options, computeDaysList, getDataChart } from "../../../../helper"
import { useDatabase } from "../../../../contexts/DatabaseProvider"
import { useDashboard } from "../../../../contexts/DashboardProvider"

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip)

export default function TimeStatsPanel({ statsType, date }) {
    const { statsList } = useDatabase()
    const { colorTheme, receipt } = useDashboard()
    const [dataChart, setDataChart] = useState(getDataChart(computeDaysList(date), statsType.chart, colorTheme.secondaryColor))

    const updateDataChart = (date) => {
        // console.log(date)
        setDataChart(getDataChart(computeDaysList(date), statsType.chart, colorTheme.secondaryColor))
    }

    useEffect(() => {
        updateDataChart(date)
    }, [statsList, colorTheme])

    return (
        <>
            {/* {console.log(dataChart)} */}
            <div className="d-flex card shadow-sm mb-3 p-4" style={{ borderRadius: "15px" }}>
                <div className="d-flex flex-row justify-content-between ps-4 pe-4 mb-4">
                    {statsType.topTitles.map((stats) => {
                        let value = 0
                        // console.log( stats)

                        if (Array.isArray(stats.value)) value = stats.value.reduce((tot, x) => tot + x.amount, 0)
                        else value = stats.value
                        return (
                            <div key={stats.title}>
                                <div className="d-flex flex-row">
                                    <div className="d-flex flex-column ">
                                        <div style={{ fontSize: "18px", fontWeight: "600", letterSpacing: "0.5px", color: colorTheme.primaryColor }}>
                                            {stats.symbol ?? "€"}
                                            {value.toFixed(2)}
                                        </div>
                                        <div className="d-flex text-secondary" style={{ fontSize: "12px" }}>
                                            {stats.title}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {/* {console.log(dataChart)} */}
                <div>
                    <Bar height={"203px"} options={options} data={dataChart} />
                </div>
            </div>
        </>
    )
}
