import { React } from "react"
import { Container, Row, Col } from "react-bootstrap"
import MainPanel from "./dashboard/MainPanel"
import Sidebar from "./dashboard/Sidebar"
import DashboardMode from "./dashboard/DashboardMode"
import { useDashboard } from "../contexts/DashboardProvider"
import { useAuthentication } from "../contexts/AuthenticationProvider"
import { hasPermission, ROLES } from "../helper"
import ConnectionStatus from "./ConnectionStatus"
import NewVersionNotification from "./NewVersionNotification"

//TODO merge global discount with individual discount for AgE
export default function Dashboard() {
    const { dashboard } = useDashboard()
    const { user } = useAuthentication()
    // This component updates twice beacause as service change it changes also the total which re-renders everything again

    const disableHolidaysSidebar = dashboard === "operators" && hasPermission(user, [ROLES.hairdresser])
    const disableInsightsSidebar = dashboard === "insights"
    const disableCalendarSidebar = dashboard === "calendar"
    return (
        <div className="flex flex-row h-screen bg-whitesmoke p-2 gap-2">
            <DashboardMode />
            {disableHolidaysSidebar || disableInsightsSidebar || disableCalendarSidebar || <Sidebar />}
            <MainPanel />
            <ConnectionStatus/>
                      
            
        </div>
    )
}
