import React, { useEffect, useState } from "react"
import { DateTime } from "luxon"
import { CalendarButton, TimeButton, LabelledSwitch, IconComponentManager, SaveButton, Card, AlertBoxWrapper } from "./SpecialDays"
import { days, formatRedableDate, handleStartEndSelection } from "../easy-calendar/utils/helpers"
import { NavigationBar } from "./EasyOperators"
import { IconButton } from "@mui/material"
import { AiOutlinePlusCircle } from "react-icons/ai"
import { useDashboard } from "../../../../contexts/DashboardProvider"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAdd, faTrash } from "@fortawesome/free-solid-svg-icons"
import { useSalon } from "../../../../contexts/SalonProvider"
import { deleteDataBooking, getDataBooking, patchDataBooking, sendDataBooking, urlsBooking } from "../../../../helper"
import AlertBox from "../easy-calendar/elements/AlertBox"
import { CgInfinity } from "react-icons/cg"
import { Spinner } from "react-bootstrap"

// Custom hook for managing working days state
function useWorkingDays() {
    const { selectedOperator } = useDashboard()
    const { selectedSalon } = useSalon()

    const [workingDay, setWorkingDay] = useState([])

    async function fetchWorkingDays() {
        try {
            const data = await getDataBooking(`${urlsBooking.listWorkingSchedules}${selectedOperator.id}/${selectedSalon.id}`)
            const formattedData = data.map((x) => ({
                ...x,
                start_date: DateTime.fromISO(x.start_date),
                end_date: DateTime.fromISO(x.end_date),
                isInfinite: x.end_date === "2099-12-31",
                weekly_shifts: x.weekly_shifts.map((shift) => ({
                    ...shift,
                    daily_schedules: shift.daily_schedules.map((schedule) => ({
                        ...schedule,
                        start_time: DateTime.fromISO(schedule.start_time),
                        end_time: DateTime.fromISO(schedule.end_time),
                        isOpen: !(schedule.start_time === "00:00:00" && schedule.end_time === "00:00:00"),
                    })),
                })),
            }))
            return formattedData
            // setWorkingDay(formattedData)
        } catch (error) {
            console.error("Failed to fetch working days:", error)
        }
    }

    return [workingDay, setWorkingDay, fetchWorkingDays]
}

// Component for handling calendar button selection
const CalendarSelector = ({ label, date, onChange, className }) => (
    <CalendarButton label={label} text={formatRedableDate(date)} value={date} setValue={(dateDay) => onChange(dateDay.dateday)} className={className} />
)

// Component for handling time selection
const TimeSelector = ({ label, time, onChange }) => <TimeButton text={time.toLocaleString(DateTime.TIME_24_SIMPLE)} value={time} setValue={(timeslot) => onChange(timeslot)} />

// Component for rendering each row for days of the week
const DayRow = ({ day, isOpen, onSwitchChange, startTime, endTime, onTimeChange }) => (
    <div className="flex flex-row gap-4 mb-2 ms-2">
        <div className="w-10 self-center">{day}</div>
        <div className="w-20">
            <LabelledSwitch checked={isOpen} onChange={onSwitchChange} />
        </div>

        <div className="flex w-[88px]">{isOpen && <TimeSelector label="Inizio" time={startTime} onChange={(time) => onTimeChange("start_time", time)} />}</div>
        <div className="flex w-[88px]">{isOpen && <TimeSelector label="Fine" time={endTime} onChange={(time) => onTimeChange("end_time", time)} />}</div>
    </div>
)

const SummaryWorkingPeriods = ({ workingDays, iconComponent, onClick, isSelected }) => {
    if (workingDays.length === 0) {
        return <div className="flex text-gray-400 text-lg ms-1">Nessun orario lavorativo creato</div>
    }

    return (
        <div className="flex flex-col !gap-4 w-full">
            {workingDays.map((day, idx) => (
                <div key={day.id} className={`flex flex-row items-center  py-3 px-10  rounded-xl gap-3 ${isSelected(day.id) ? "bg-primary/10" : "bg-secondary/10"}`} onClick={() => onClick(idx)}>
                    <div className="flex flex-row grow gap-3">
                        <div className="flex flex-col">
                            <div className="text-secondary text-xs">Data inizio</div>
                            <div className="text-[14px] font-medium tracking-tighter text-black/80">
                                <p className="capitalize">{formatRedableDate(day.start_date)}</p>
                            </div>
                        </div>
                        {day.isInfinite || (
                            <div className="flex flex-col">
                                <div className="text-secondary text-xs">Data fine</div>
                                <div className="text-[14px] font-medium tracking-tighter text-black/80">
                                    <p className="capitalize">{formatRedableDate(day.end_date)}</p>
                                </div>
                            </div>
                        )}
                    </div>
                    {iconComponent(day)}
                </div>
            ))}
        </div>
    )
}

function formatSchedule(schedule) {
    // Helper function to format time to hh:mm:ss
    const formatTime = (dateString) => {
        return DateTime.fromISO(dateString).toFormat("HH:mm")
    }

    // Helper function to format date to YYYY-MM-DD
    const formatDate = (dateString) => {
        return DateTime.fromISO(dateString).toFormat("yyyy-MM-dd")
    }

    // Format the start_date and end_date
    schedule.start_date = formatDate(schedule.start_date)
    schedule.end_date = formatDate(schedule.isInfinite ? "2099-12-31" : schedule.end_date)

    // Format the daily_schedules times
    schedule.weekly_shifts.forEach((shift) => {
        shift.daily_schedules.forEach((daily) => {
            if (!daily.isOpen) {
                daily.start_time = "00:00:00"
                daily.end_time = "00:00:00"
            } else {
                daily.start_time = formatTime(daily.start_time)
                daily.end_time = formatTime(daily.end_time)
            }
        })
    })

    return schedule
}

function generateDailySchedules() {
    // Constants for default times in HH:mm:ss format
    const DEFAULT_START_TIME = DateTime.fromISO("09:00:00")
    const DEFAULT_END_TIME = DateTime.fromISO("18:00:00")

    // Generate daily schedules with the same start and end times
    return Array.from({ length: 7 }, (_, index) => ({
        weekday: index + 1,
        start_time: DEFAULT_START_TIME,
        end_time: DEFAULT_END_TIME,
        isOpen: true,
    }))
}

function getDefaultSelectedWorkingDay(operator, salon) {
    const daily_schedules = generateDailySchedules(1)

    // Use today's date formatted as YYYY-MM-DD for start_date and end_date
    const today = DateTime.now()

    // Construct and return the schedule object
    return {
        weekly_shifts: [
            {
                daily_schedules,
                week_pattern: 1,
            },
        ],
        start_date: today,
        end_date: today,
        isInfinite: true,
        operator,
        salon,
    }
}

function addWeeklyShift(selectedWorkingDays, setSelectedWorkingDays) {
    const new_daily_schedules = generateDailySchedules()
    setSelectedWorkingDays({
        ...selectedWorkingDays,
        weekly_shifts: [...selectedWorkingDays.weekly_shifts, { daily_schedules: new_daily_schedules, week_pattern: selectedWorkingDays.weekly_shifts.length + 1 }],
    })
}

function removeWeeklyShift(selectedWorkingDays, setSelectedWorkingDays) {
    let newSelectedWorking = { ...selectedWorkingDays }
    newSelectedWorking.weekly_shifts.pop()
    setSelectedWorkingDays(newSelectedWorking)
}

function DeleteWorkingDays({ onClickDelete, disableOnClick }) {
    const [isProcessing, setIsProcessing] = useState(false)

    async function safeOnClick(e, onClick = async () => {}) {
        e.stopPropagation()
        setIsProcessing(true)
        await onClick()
        setIsProcessing(false)
    }

    if (isProcessing) {
        return (
            <div>
                <Spinner className="me-1 text-primary" as="span" animation="border" size="sm" role="status" />
            </div>
        )
    }

    return (
        <div className="flex flex-row gap-1">
            {disableOnClick || (
                <button onClick={(e) => safeOnClick(e, () => onClickDelete())}>
                    <FontAwesomeIcon icon={faTrash} size="lg" className="text-gray-400 hover:text-gray-500" />
                </button>
            )}
        </div>
    )
}

// Main WorkingDays component
export default function WorkingDays() {
    const [workingDay, setWorkingDay, fetchWorkingDays] = useWorkingDays()
    const { colorTheme, selectedOperator } = useDashboard()
    const { selectedSalon } = useSalon()
    const [weekIdx, setWeekIdx] = useState(0)
    const newWorkingDays = () => getDefaultSelectedWorkingDay(selectedOperator.id, selectedSalon.id)
    const [selectedWorkingDays, setSelectedWorkingDays] = useState(newWorkingDays())

    function resetSelectedWorkingDays() {
        setSelectedWorkingDays(newWorkingDays())
    }

    const fetchWorkingDaysAsync = async () => {
        const workingDay = await fetchWorkingDays()
        if (workingDay.length > 0) setSelectedWorkingDays(workingDay[workingDay.length - 1])
        else resetSelectedWorkingDays()
        setWorkingDay(workingDay)
        setWeekIdx(0)
    }

    useEffect(() => {
        
        fetchWorkingDaysAsync()
    }, [selectedOperator.id, selectedSalon.id])

    const setWorkingDayTimeInfo = (type, value, weekIdx, index, isConstraint = false) => {
        setSelectedWorkingDays((prev) => {
            if (!prev) return prev

            const updated = { ...prev }
            const schedule = updated.weekly_shifts[weekIdx]?.daily_schedules[index]
            if (schedule) {
                if (isConstraint) {
                    schedule[type] = value
                    schedule[type === "start_time" ? "end_time" : "start_time"] = value
                } else {
                    schedule[type] = value
                }
            }
            return updated
        })
    }

    const handleDateChange = (type, date) => {
        handleStartEndSelection(
            type,
            ["start_date", "end_date"],
            date,
            selectedWorkingDays,
            (type, value) => setSelectedWorkingDays((prev) => (prev ? { ...prev, [type]: value } : prev)),
            (firstBoundary, lastBoundary, value) => setSelectedWorkingDays((prev) => (prev ? { ...prev, [firstBoundary]: value, [lastBoundary]: value } : prev))
        )
    }

    const handleTimeChange = (type, time, weekIdx, index) => {
        handleStartEndSelection(
            type,
            ["start_time", "end_time"],
            time,
            selectedWorkingDays?.weekly_shifts[weekIdx]?.daily_schedules[index],
            (type, value) => setWorkingDayTimeInfo(type, value, weekIdx, index),
            (firstBoundary, lastBoundary, value) => setWorkingDayTimeInfo(firstBoundary, value, weekIdx, index, true)
        )
    }

    async function saveWorkingSchedule(selectedWorkingDays, setAlertBox) {
        try {
            if ("id" in selectedWorkingDays) {
                await patchDataBooking(urlsBooking.updateWorkingSchedules + selectedWorkingDays.id + "/", { ...formatSchedule({ ...selectedWorkingDays }) })
            } else {
                await sendDataBooking(urlsBooking.createWorkingSchedules, { ...formatSchedule({ ...selectedWorkingDays }) })
            }
        } catch (error) {
            console.log(error)
            setAlertBox({ mode: "danger", message: error.message, enabled: true })
            return
        }
        setAlertBox({ mode: "success", message: "Periodo lavorativo salvato con successo", enabled: true })

        await fetchWorkingDaysAsync()
        // resetSelectedWorkingDays()
    }

    const AddWorkingHour = () => (
        <IconButton onClick={() => setSelectedWorkingDays(newWorkingDays())}>
            <AiOutlinePlusCircle color={colorTheme.primaryColor} />
        </IconButton>
    )

    async function deleteWorkingDays(selectedWorkingDaysId, setAlertBox) {
        try {
            await deleteDataBooking(urlsBooking.deleteWorkingSchedules + selectedWorkingDaysId + "/")
        } catch (error) {
            setAlertBox({ mode: "danger", message: error.message, enabled: true })
            return
        }
        await fetchWorkingDaysAsync()
        // resetSelectedWorkingDays()
    }

    const formatNameSchedule = (elements) => elements.map((e, index) => `Turno ${e.week_pattern}`)

    return (
        <AlertBoxWrapper dependecies={[selectedOperator.id]}>
            {(setAlertBox) => (
                <Card title="Orari lavorativi" extraComponent={<AddWorkingHour />}>
                    <div className="flex flex-row gap-10">
                        <div className="flex w-[50%]">
                            <SummaryWorkingPeriods
                                isSelected={(id) => selectedWorkingDays.id === id}
                                workingDays={workingDay}
                                iconComponent={(day) => (
                                    <DeleteWorkingDays
                                        disableOnClick={day.end_date.startOf("day") < DateTime.now().startOf("day")}
                                        onClickDelete={async () => {
                                            await deleteWorkingDays(day.id, setAlertBox)
                                        }}
                                    />
                                )}
                                onClick={(idx) =>{ 
                                    setSelectedWorkingDays(workingDay[idx])
                                    setWeekIdx(0)}
                                }
                            />
                        </div>
                        {selectedWorkingDays && (
                            <div className="flex flex-col w-[50%]">
                                <div className="flex flex-row gap-3 px-1 mb-2 items-center">
                                    <button
                                        onClick={() => {
                                            if (selectedWorkingDays.isInfinite)
                                                setSelectedWorkingDays({ ...selectedWorkingDays, end_date: selectedWorkingDays.start_date, isInfinite: !selectedWorkingDays.isInfinite })
                                            else setSelectedWorkingDays({ ...selectedWorkingDays, isInfinite: !selectedWorkingDays.isInfinite })
                                        }}
                                        className={`flex p-2 justify-center items-center rounded-full mt-3 !border-none !outline-none ${
                                            selectedWorkingDays.isInfinite ? "!bg-primary/50" : "!bg-slate-100"
                                        }`}
                                    >
                                        <CgInfinity className={`flex self-place-center w-4 h-4   ${selectedWorkingDays.isInfinite ? "text-white" : "!text-gray-800"}`} />
                                    </button>
                                    <div className="flex w-[50%]">
                                        <CalendarSelector label="Data inizio" date={selectedWorkingDays.start_date} onChange={(date) => handleDateChange("start_date", date)} />
                                    </div>
                                    <div className="flex w-[50%]">
                                        {selectedWorkingDays.isInfinite || (
                                            <CalendarSelector label="Data fine" date={selectedWorkingDays.end_date} onChange={(date) => handleDateChange("end_date", date)} className="!-left-24" />
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-row justify-between items-start">
                                    <NavigationBar
                                        titles={formatNameSchedule(selectedWorkingDays.weekly_shifts)}
                                        isSelected={(idx) => idx === weekIdx}
                                        onClick={(idx) => setWeekIdx(idx)}
                                        className="!px-0 z-0 flex-wrap "
                                    />
                                    <div className="flex flex-row gap-4 mt-3">
                                        {selectedWorkingDays.weekly_shifts.length > 1 && (
                                            <button
                                                onClick={() => {
                                                    removeWeeklyShift(selectedWorkingDays, setSelectedWorkingDays)
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faTrash} size="lg" className="text-gray-400 hover:text-gray-500" />
                                            </button>
                                        )}
                                        <button
                                            onClick={() => {
                                                addWeeklyShift(selectedWorkingDays, setSelectedWorkingDays)
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faAdd} size="lg" className="text-primary/70 hover:text-primary" />
                                        </button>
                                    </div>
                                </div>

                                <div className="flex flex-row gap-4 mt-4 mb-2 ms-2">
                                    <div className="w-10">Giorni</div>
                                    <div className="w-24">Apertura</div>
                                    <div className="ms-1 w-24">Inizio</div>
                                    <div className="ms-1 w-24">Fine</div>
                                </div>

                                {selectedWorkingDays.weekly_shifts[weekIdx].daily_schedules.map((schedule, index) => (
                                    <DayRow
                                        key={index}
                                        day={days[index]}
                                        isOpen={schedule.isOpen}
                                        onSwitchChange={() =>
                                            setSelectedWorkingDays((prev) => {
                                                const updated = { ...prev }
                                                updated.weekly_shifts[weekIdx].daily_schedules[index].isOpen = !schedule.isOpen
                                                return updated
                                            })
                                        }
                                        startTime={schedule.start_time}
                                        endTime={schedule.end_time}
                                        onTimeChange={(type, time) => handleTimeChange(type, time, weekIdx, index)}
                                    />
                                ))}
                                <div className="flex h-5" />
                                <SaveButton
                                    onClick={async () => {
                                        await saveWorkingSchedule(selectedWorkingDays, setAlertBox)
                                    }}
                                >
                                    Salva orari
                                </SaveButton>
                            </div>
                        )}
                    </div>
                </Card>
            )}
        </AlertBoxWrapper>
    )
}
