
import React from 'react'
import { useDashboard } from '../../../../contexts/DashboardProvider'

export default function TemplateEditor({selectedCustomerTemplate}) {
    const { selectedTemplate } = useDashboard()

    return (


        <div className='d-flex flex-column p-3 ' style={{ width: '50%' }}>
            <div className='d-flex card ' style={{ borderRadius: '15px' }}>
                <div className='p-3 '>
                    <div className='d-flex mb-4 align-items-center ' style={{ fontSize: "16px" }}>
                        <div className='d-flex flex-fill mt-2' style={{ fontSize: '18px', fontWeight: '600' }}>
                            Modello
                        </div>

                    </div>

                    <div style={{ whiteSpace: 'pre-line' }}>
                        {selectedCustomerTemplate.message ? selectedCustomerTemplate.message : selectedTemplate.text}
                    </div>

                </div>               
            </div>




        </div>




    )

}
