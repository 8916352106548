import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DateTime } from "luxon"
import { useCallback, useEffect, useRef, useState } from "react"
import { useDimensions } from "./useDimensions"

import { faAdd, faChevronLeft, faChevronRight, faRotateRight, faXmark, faTrash } from "@fortawesome/free-solid-svg-icons"
import { BsMoonStarsFill } from "react-icons/bs"
import { useDashboard } from "../../../../../contexts/DashboardProvider"
import { adjustLuminosity, getDataTime } from "../../../../../helper"
import { useDatabase } from "../contexts/DatabaseProvider"
import CalendarButton from "../elements/CalendarButton"
import MultiTabModal from "../elements/MultiTabModal"
import {
    HEIGHT_HOUR,
    checkOverlap,
    colors,
    currentTimeToLine,
    dateToLayoutAppointments,
    dateToLayoutOffTime,
    days,
    formatWeeklyString,
    generateCalendarTimeslots,
    generateId,
    getWeekDates,
    groupAppointmentDetailsByCustomer,
    range,
    stringToHslColor,
} from "../utils/helpers"
import AppointmentModal, { OperatorBadge } from "./AppointmentModal"
import CustomEventModal from "./CustomEventModal"
import useComponentVisible from "./useComponentVisible"
import { useSalon } from "../../../../../contexts/SalonProvider"
import { AiFillPlusCircle, AiOutlineCheckCircle, AiOutlinePlus } from "react-icons/ai"
import ShowEventModal from "./ShowEventModal"
import TopBarModal from "./TopBarModal"
import { parsePhoneNumber } from "libphonenumber-js"
import { IconButton } from "@mui/material"

function NonWorkingHours({ calendarDimensions, onClick, weekDates }) {
    const { workingHours } = useDatabase()
    const { selectedSalon } = useSalon()
    // console.log(selectedSalon)
    return (
        <div>
            {workingHours.map((salonWorkingHour) => {
                const layout = dateToLayoutOffTime(salonWorkingHour, calendarDimensions, selectedSalon.anticipate_booking_closure)
                return (
                    <div key={salonWorkingHour.id}>
                        <button
                            className="absolute"
                            style={{
                                width: layout.width,
                                height: layout.height.start,
                                left: layout.left,
                                top: layout.top.start,
                                backgroundColor: "#eaeaea",
                            }}
                            onClick={() => {
                                onClick(weekDates[salonWorkingHour.weekday - 1].set({ hour: 8, minute: 5 }).toString())
                            }}
                        ></button>

                        <button
                            className="absolute"
                            style={{
                                width: layout.width,
                                height: layout.height.end,
                                left: layout.left,
                                top: layout.top.end,
                                backgroundColor: "#eaeaea",
                            }}
                            onClick={() => {
                                onClick(weekDates[salonWorkingHour.weekday - 1].set({ hour: 19, minute: 5 }).toString())
                            }}
                        ></button>
                    </div>
                )
            })}
        </div>
    )
}

function AppointmentsSlots({ calendarDimensions, setShowCalendarModal, setSelectedAppointment }) {
    const { appointments, customEvents, bookingParameters } = useDatabase()
    const appointment_details = appointments
    const SLOT_SIZE = bookingParameters.SLOT_SIZE
    // console.log(appointments)
    return (
        <div>
            {appointment_details.map((appointment_detail) => {
                const isOverlapping = customEvents.some((event) => {
                    return checkOverlap(appointment_detail, event)
                })

                const safeSpace = isOverlapping ? 20 : 10
                const layout = dateToLayoutAppointments(appointment_detail.start_time, appointment_detail.duration, calendarDimensions, safeSpace, SLOT_SIZE)
                const appointmentsName = appointment_detail.service.name
                return (
                    <button
                        key={appointment_detail.id}
                        className="absolute flex flex-col rounded-md overflow-clip"
                        style={{
                            width: layout.width,
                            height: layout.height,
                            left: layout.left,
                            top: layout.top,
                            // backgroundColor: stringToHslColor(appointmentsName, 30, 90),
                            backgroundColor: appointment_detail.service.color || stringToHslColor(appointmentsName, 30, 90),

                            borderColor: "white",
                            borderWidth: "1px",
                            borderLeftColor: appointment_detail.service.color ? adjustLuminosity(appointment_detail.service.color, -15) : stringToHslColor(appointmentsName, 30, 70),
                            borderLeftWidth: "6px",
                        }}
                        onClick={() => {
                            // console.log(appointment_detail)
                            setSelectedAppointment(appointment_detail)
                            setShowCalendarModal(true)
                        }}
                    >
                        {appointment_detail.duration < SLOT_SIZE * 2 ? (
                            <div className="flex text-xs text-black/80 text-start px-2 ">{appointment_detail.customer.first_name + " " + appointment_detail.customer.last_name}</div>
                        ) : (
                            <>
                                <div className=" flex text-xs text-start text-black/80 px-2 !pt-1">{appointment_detail.customer.first_name + " " + appointment_detail.customer.last_name}</div>
                                <div className="px-2 mb-1 text-left text-[10px] text-black/70">{appointment_detail.service.name}</div>
                            </>
                        )}
                    </button>
                )
            })}
        </div>
    )
}

function CustomEvents({ calendarDimensions, setShowCalendarModal, setSelectedCustomEvent }) {
    const { customEvents, bookingParameters } = useDatabase()
    const SLOT_SIZE = bookingParameters.SLOT_SIZE
    return (
        <div>
            {customEvents.map((customEvent) => {
                const layout = dateToLayoutAppointments(customEvent.start_time, customEvent.duration, calendarDimensions, 4, SLOT_SIZE)
                const startTime = DateTime.fromISO(customEvent.start_time).toLocaleString(DateTime.TIME_24_SIMPLE)
                const endTime = DateTime.fromISO(customEvent.start_time).plus({ minutes: customEvent.duration }).toLocaleString(DateTime.TIME_24_SIMPLE)
                return (
                    <button
                        key={customEvent.id}
                        className="absolute flex flex-col rounded-md  "
                        style={{
                            width: layout.width,
                            height: layout.height,
                            left: layout.left,
                            top: layout.top,
                            backgroundColor: "#ededed",
                            borderColor: "white",
                            backgroundImage: "repeating-linear-gradient(-45deg, #fbfbfb,  #fbfbfb 15px,  #efefef 15px,  #efefef 30px)",
                            borderWidth: "2px",
                        }}
                        onClick={() => {
                            setSelectedCustomEvent(customEvent)
                            setShowCalendarModal(true)
                        }}
                    >
                        {customEvent.duration < SLOT_SIZE * 2 ? (
                            <div className="flex text-xs text-black/80 text-start px-2 ">{customEvent.name}</div>
                        ) : (
                            <div className="flex flex-col w-full text-start text-xs text-black/80 px-2 !pt-1 ">
                                <div>{customEvent.name}</div>
                                <div className="mt-1 text-[10px] text-black/80">{startTime + "-" + endTime}</div>
                            </div>
                        )}
                    </button>
                )
            })}
        </div>
    )
}

function SummaryWaitingList({ appointments, showModal, calendarOperator, setShowModal, onClickAdd, onClickDelete }) {
    const { selectedOperator } = useDashboard()

    const groupedAppointments = groupAppointmentDetailsByCustomer(appointments)
    if (groupedAppointments === undefined) return

    return (
        <ShowEventModal showModal={showModal}>
            <TopBarModal closingIcon={faXmark} closeModal={() => setShowModal(false)} title={"Lista d'attesa"} classNameTitle="text-lg tracking-tight" />

            <div className="flex flex-col mt-3 gap-3">
                {groupedAppointments.map((appointment) => {
                    return (
                        <div key={appointment.appointment_details[0].id} className={`flex flex-col text-start bg-gray-50 mb-2 p-3 ps-4 rounded-[15px] w-[350px]`}>
                            <div className="flex  rounded justify-between items-center mb-3">
                                <div className="flex   text-primary rounded-md font-medium text-sm">{appointment.customer.first_name + " " + appointment.customer.last_name}</div>
                                <div className="flex py-1 !px-4 bg-black/10 text-black/90 rounded-md  font-medium">{parsePhoneNumber(appointment.customer.phone).formatInternational()}</div>
                            </div>
                            {appointment.appointment_details.map((detail) => {
                                const formattedDetail = { ...detail, type: "waiting_list", operator: calendarOperator.id }
                                // console.log(selectedOperator)
                                return (
                                    <div key={detail.id} className="d-flex flex-row justify-content-between items-center mt-1" style={{ width: "100%", gap: "5px" }}>
                                        <div className="flex ps-1 text-secondary leading-none tracking-tight items-center mt-2 gap-2">
                                            <div className="flex flex-none flex-shrink-0 !w-[9px] !h-[9px] bg-primary rounded-full" />
                                            <div className="flex">{detail.service.name}</div>
                                        </div>

                                        <div className="flex flex-row gap-2">
                                            {detail.booked ? (
                                                <AiOutlineCheckCircle className="flex  text-primary/70 !mr-[2px]" size={24} />
                                            ) : (
                                                <>
                                                    <button className="flex p-2" onClick={() => onClickAdd(formattedDetail)}>
                                                        <FontAwesomeIcon icon={faAdd} size="lg" className="text-primary/70 hover:text-primary" />
                                                    </button>
                                                    <button
                                                        className="flex p-2"
                                                        onClick={() => {
                                                            onClickDelete(formattedDetail)
                                                            if (appointments.length === 1) setShowModal(false)
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} size="lg" className="text-secondary/70 hover:text-secondary" />
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        </ShowEventModal>
    )
}

function DayBar({ weekDates, calendarOperator, onClickAddWaitingList, onClickDeleteWaitingList }) {
    const [showModal, setShowModal] = useState(false)
    const { specialDays, appointmentsWaitingList } = useDatabase()
    const [appointmentsWaitingListDate, setAppointmentsWaitingListDate] = useState([])
    const today = DateTime.now().startOf("day").toMillis()

    function handleColor(isToday, isSpecial) {
        if (isToday) return "bg-primary/80 rounded-md text-slate-100"
        else if (isSpecial?.start_time === "00:00:00" && isSpecial?.end_time === "00:00:00") return "g-slate-200 text-slate-500"
        else if (isSpecial?.type === "variation") return "bg-violet-300 text-violet-800  rounded-md font-medium"
        else if (isSpecial?.type === "holidays") return "bg-yellow-400 text-yellow-700  rounded-md font-medium"
        else if (isSpecial?.type === "sick_days") return "bg-lime-400  text-green-800  rounded-md font-medium"
        return "bg-slate-200 text-slate-500"
    }

    const selectedAppointmentWaitingList = appointmentsWaitingList.filter((x) => x.date.toMillis() === appointmentsWaitingListDate)
    return (
        <div className="flex flex-row">
            <div className="w-[43px] flex-none rounded-tl-xl border-b-0 border-r-0 border-slate-300 bg- bg-slate-300 text-center "></div>
            <div className=" flex w-full flex-row">
                {days.map((day, idx) => {
                    const isToday = weekDates[idx].toMillis() === today
                    const isSpecial = specialDays.filter((x) => x.day.toMillis() === weekDates[idx].toMillis())[0]
                    const dateWaitingList = appointmentsWaitingList.filter((x) => x.date.toMillis() === weekDates[idx].toMillis() && !x.booked)
                    const counterWaitingList = dateWaitingList.length

                    return (
                        <div key={day} className={`relative flex  w-full text-center items-center last:rounded-tr-xl bg-slate-200 text-slate-500 px-[1px]`}>
                            <div className={`flex w-full justify-center items-center gap-2 p-2 ${handleColor(isToday, isSpecial)}`}>
                                {day} {weekDates[idx].day}
                            </div>
                            {counterWaitingList > 0 && (
                                <button
                                    className="absolute right-2 flex flex-none px-2 py-1 rounded-full bg-primary leading-none  justify-center items-center text-white gap-1 "
                                    onClick={() => {
                                        setShowModal(true)
                                        setAppointmentsWaitingListDate(weekDates[idx].toMillis())
                                    }}
                                >
                                    <FontAwesomeIcon icon={faAdd} size="sm" className="text-white" />
                                    <div className="mt-[1px]">{counterWaitingList}</div>
                                </button>
                            )}
                        </div>
                    )
                })}
            </div>
            <SummaryWaitingList
                appointments={selectedAppointmentWaitingList}
                showModal={showModal}
                setShowModal={setShowModal}
                calendarOperator={calendarOperator}
                onClickAdd={onClickAddWaitingList}
                onClickDelete={onClickDeleteWaitingList}
            />
        </div>
    )
}

function CalendarGrids({ calendarRef, onClick, timeslots }) {
    const { bookingParameters } = useDatabase()
    const SLOT_SIZE = bookingParameters.SLOT_SIZE
    // console.log(SLOT_SIZE)
    const scrollRef = useRef()

    useEffect(() => {
        scrollRef.current.scrollIntoView()
    }, [scrollRef])

    //Enable this when developing click calendar

    return (
        <div ref={calendarRef} className="relative flex flex-col ">
            {range(24).map((hour) => {
                return (
                    <div key={generateId("hour_id_")} className="flex flex-row ">
                        <div ref={hour === 9 ? scrollRef : null} className="w-[40px] flex-none text-center text-[10px] text-slate-400">
                            {hour}:00
                        </div>

                        <div className="flex w-full flex-row">
                            {range(7).map((day) => {
                                return (
                                    <div
                                        key={generateId("day_id2_")}
                                        className="flex w-full flex-col border-b-[1px] border-l-[1px] text-center border-slate-300/70 z-auto"
                                        style={{ height: HEIGHT_HOUR }}
                                    >
                                        {range(60 / SLOT_SIZE).map((timeslotIdx, idx) => {
                                            const timeSlot = timeslots[day][(hour * 60) / SLOT_SIZE + timeslotIdx]
                                            // console.log(timeSlot)
                                            return (
                                                <button
                                                    key={generateId("timeslot_id3_")}
                                                    className={`border-b-[1px]  bg-slate-50 hover:bg-white/20 z-0 hover:outline hover:outline-3 hover:outline-slate-100 hover:z-10 hover:rounded ${
                                                        idx === 1 ? "border-slate-300/40" : "border-slate-50"
                                                    }`}
                                                    onClick={() => {
                                                        onClick(timeSlot)
                                                    }}
                                                    style={{ height: HEIGHT_HOUR / (60 / SLOT_SIZE), fontSize: "8px" }}
                                                />
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

function WeekMover({ weekDates, setWeekDates }) {
    const { dayCalendar, setDayCalendar } = useDashboard()
    function moveWeek(direction) {
        let newDate
        if (direction === "next") {
            newDate = weekDates[0].plus({ week: 1 })
            setWeekDates(getWeekDates(newDate))
        } else if (direction === "back") {
            newDate = weekDates[0].minus({ week: 1 })
            setWeekDates(getWeekDates(newDate))
        }
        setDayCalendar(newDate)
    }

    return (
        <div className="flex flex-row items-center ">
            <button className="flex h-[20px] w-[20px] items-center justify-center rounded-full  !p-4 shadow-sm" style={{ height: "20px" }} onClick={() => moveWeek("back")}>
                <FontAwesomeIcon icon={faChevronLeft} style={{ color: colors.primary }} size="sm" />
            </button>

            <CalendarButton
                value={dayCalendar}
                setValue={(dateDay) => {
                    setDayCalendar(dateDay.dateday)
                    setWeekDates(getWeekDates(dateDay.dateday))
                }}
                startingMonth={weekDates[0].startOf("month")}
                autoclose
            >
                <button className="flex w-44 justify-center rounded-2xl !mx-4 hover:bg-secondary/20">{formatWeeklyString(weekDates)}</button>
            </CalendarButton>

            <button className="flex h-[20px] w-[20px]  items-center justify-center rounded-full  !p-4 shadow-sm" onClick={() => moveWeek("next")}>
                <FontAwesomeIcon icon={faChevronRight} style={{ color: colors.primary }} size="sm" />
            </button>
        </div>
    )
}

function OperatorSelection({ calendarOperator, setCalendarOperator }) {
    const { operators } = useDatabase()
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)

    function DisplayOperator({ operator }) {
        return (
            <div className="flex w-36 flex-row items-center   !p-2 px-4 gap-2">
                <OperatorBadge operator={operator} extraDetails={false} size={24} />
                <div className="flex text-sm text-black/80" style={{ fontWeight: "400" }}>
                    {operator.first_name}
                </div>
            </div>
        )
    }

    return (
        <div className="relative ">
            <button
                className="flex rounded-xl shadow-sm"
                onClick={() => {
                    setIsComponentVisible(!isComponentVisible)
                }}
            >
                <DisplayOperator operator={calendarOperator} />
            </button>
            <div ref={ref}>
                {isComponentVisible && (
                    <div className="absolute z-10 mt-2 flex max-h-48 flex-col overflow-auto rounded-md bg-white text-sm text-black/80 shadow-md">
                        {operators.map((operatorT) => {
                            if (operatorT.id === calendarOperator.id) return
                            return (
                                <button
                                    key={operatorT.id}
                                    onClick={() => {
                                        setCalendarOperator(operatorT)
                                        setIsComponentVisible(false)
                                    }}
                                >
                                    <DisplayOperator operator={operatorT} />
                                </button>
                            )
                        })}
                    </div>
                )}
            </div>
        </div>
    )
}

function TopBar({ weekDates, setWeekDates, calendarOperator, setCalendarOperator }) {
    return (
        <div className="mb-2 flex grow items-center justify-between space-x-4 text-lg ">
            <OperatorSelection calendarOperator={calendarOperator} setCalendarOperator={setCalendarOperator} />
            <WeekMover weekDates={weekDates} setWeekDates={setWeekDates} />
            <div className="flex w-32 justify-end">
                <button className="flex h-[20px] w-[20px] items-center justify-center rounded-full  !p-4 shadow-sm " onClick={() => setWeekDates(getWeekDates(DateTime.now()))}>
                    <FontAwesomeIcon icon={faRotateRight} style={{ color: colors.primary }} size="sm" />
                </button>
            </div>
        </div>
    )
}

function CurrentTimeIndicator({ calendarDimensions, weekDates }) {
    const [time, setTime] = useState(Date.now())

    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 1000 * 60)
        return () => {
            clearInterval(interval)
        }
    }, [])

    if (!(weekDates[0] < Date.now() && Date.now() < weekDates[6])) return

    const layout = currentTimeToLine(calendarDimensions)

    return (
        <div className={`absolute h-[2px] bg-blue-500 w-full`} style={{ top: layout.top, left: layout.left, width: layout.width }}>
            <div className="absolute w-[2px] h-[8px] bg-blue-500 left-0 top-[-3px] rounded" />
            <div className="absolute w-[2px] h-[8px] bg-blue-500 right-0 top-[-3px] rounded" />
        </div>
    )
}

export default function Calendar() {
    const { weekDates, setWeekDates, calendarOperator, setCalendarOperator } = useDashboard()

    const { cleanupMonthlyFreeTimeslots, bookingParameters, deleteAppointmentsDetailsWaitingList } = useDatabase()
    const SLOT_SIZE = bookingParameters.SLOT_SIZE
    const calendarRef = useRef(null)
    const calendarDimensions = useDimensions(calendarRef)
    const [showCalendarModal, setShowCalendarModal] = useState(false)

    const { retrieveWeeklyData } = useDatabase()
    const [selectedAppointment, setSelectedAppointment] = useState("not-selected")
    const [selectedCustomEvent, setSelectedCustomEvent] = useState("not-selected")

    const [calendarAccessedMode, setCalendarAccessedMode] = useState({ mode: undefined, payload: undefined })
    const [modalPage, setModalPage] = useState(1)

    function refreshDataCalendar() {
        retrieveWeeklyData(weekDates[0], calendarOperator.id)
    }

    useEffect(() => {
        // console.log("start")
        retrieveWeeklyData(weekDates[0], calendarOperator.id)
        // console.log("end")
    }, [calendarOperator, weekDates])

    function timeslotToDayTimeInfo(timeslot) {
        const timeslotDateTime = DateTime.fromISO(timeslot)
        return { dayTimestamp: timeslotDateTime.startOf("day").toMillis(), timeSlot: timeslotDateTime.toMillis() }
    }

    function onClickShowModalAppointment(timeslot) {
        setCalendarAccessedMode({ mode: "click", payload: timeslotToDayTimeInfo(timeslot) })
        setShowCalendarModal(true)
    }

    function cleanUpModalAppointment() {
        setCalendarAccessedMode({ mode: undefined, payload: undefined })
        cleanupMonthlyFreeTimeslots()
        setSelectedAppointment("not-selected")
        setShowCalendarModal(false)
        setModalPage(1)
    }

    function cleanUpModalEvent() {
        setSelectedCustomEvent("not-selected")
        setShowCalendarModal(false)
        setModalPage(1)
    }

    const appointmentModalComponent = {
        title: "Appuntamento",
        cleanUpModal: cleanUpModalAppointment,
        component: (
            <AppointmentModal
                refreshDataCalendar={refreshDataCalendar}
                selectedAppointment={selectedAppointment}
                calendarOperator={calendarOperator}
                calendarAccessedMode={calendarAccessedMode}
                modalPage={modalPage}
                setModalPage={setModalPage}
                closeModal={cleanUpModalAppointment}
            />
        ),
        dimensions: [
            { width: "1100px", height: "650px" },
            { width: "1100px", height: "650px" },
        ],
    }

    const eventModalComponent = {
        title: "Evento",
        cleanUpModal: cleanUpModalEvent,
        component: (
            <CustomEventModal
                selectedCustomEvent={selectedCustomEvent}
                calendarOperator={calendarOperator}
                calendarAccessedMode={calendarAccessedMode}
                refreshDataCalendar={refreshDataCalendar}
                closeModal={cleanUpModalEvent}
            />
        ),
        dimensions: [{ width: "780px", height: "650px" }],
    }

    let tabsData
    if (selectedAppointment !== "not-selected") tabsData = [appointmentModalComponent]
    else if (selectedCustomEvent !== "not-selected") tabsData = [eventModalComponent]
    else tabsData = [appointmentModalComponent, eventModalComponent]

    const timeslots = useCallback(generateCalendarTimeslots(weekDates, SLOT_SIZE), [weekDates])

    return (
        <div className="relative flex h-full flex-col ">
            <TopBar weekDates={weekDates} setWeekDates={setWeekDates} calendarOperator={calendarOperator} setCalendarOperator={setCalendarOperator} />
            <DayBar
                calendarOperator={calendarOperator}
                weekDates={weekDates}
                onClickAddWaitingList={(appointment_detail) => {
                    // console.log(appointment_detail)
                    setSelectedAppointment(appointment_detail)
                    setShowCalendarModal(true)
                }}
                onClickDeleteWaitingList={async (appointment_detail) => {
                    await deleteAppointmentsDetailsWaitingList(appointment_detail.id)
                    refreshDataCalendar()
                }}
            />
            <div className="hide-scroll overflow-auto border-x-2 border-b-2 border-[#eaeaea]">
                <div className="relative">
                    <CalendarGrids calendarRef={calendarRef} weekDates={weekDates} onClick={onClickShowModalAppointment} timeslots={timeslots} />
                    <NonWorkingHours calendarDimensions={calendarDimensions} weekDates={weekDates} onClick={onClickShowModalAppointment} timeslots={timeslots} />
                    <CustomEvents calendarDimensions={calendarDimensions} setShowCalendarModal={setShowCalendarModal} setSelectedCustomEvent={setSelectedCustomEvent} />
                    <AppointmentsSlots calendarDimensions={calendarDimensions} setShowCalendarModal={setShowCalendarModal} setSelectedAppointment={setSelectedAppointment} />
                    <CurrentTimeIndicator calendarDimensions={calendarDimensions} weekDates={weekDates} />
                </div>
                <MultiTabModal showModal={showCalendarModal} modalPage={modalPage} setModalPage={setModalPage} customDimensions={true} tabsData={tabsData}></MultiTabModal>
            </div>
        </div>
    )
}
