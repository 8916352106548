import React, { useContext, useEffect, useState, useRef } from "react"
import { InputGroup, Modal, FormControl, Button } from "react-bootstrap"
import { CgFileDocument } from "react-icons/cg"
import { useDashboard } from "../../../../contexts/DashboardProvider"
import { MdOutlineClose } from "react-icons/md"
import { IconButton } from "@mui/material"
import { useDatabase } from "../../../../contexts/DatabaseProvider"
import { BsCircleFill, BsCircle } from "react-icons/bs"
import { patchData, sendData, urls } from "../../../../helper"
import { handleListofDictionariesChange } from "../../main-panel/easy-calendar/utils/helpers"

export default function InventorySummary({ modalShow, setModalShow, sortedProducts }) {
    const { colorTheme } = useDashboard()
    const { providers, updateInventory } = useDatabase()
    const [providerSelected, setProviderSelected] = useState("not-selected")
    const [restockProducts, setRestockProducts] = useState([])
    const [filteredProducts, setFilteredProducts] = useState([])
    const width = "33%"

    useEffect(() => {
        if (modalShow === true) {
            const restockProductsT = JSON.parse(JSON.stringify(sortedProducts)).filter((x) => x.quantity < x.restock_limit)
            setRestockProducts(restockProductsT)
        }
    }, [modalShow])

    useEffect(() => {
        const filteredProductsT = [...restockProducts]
            .filter((productT) => {
                if (providerSelected === "not-selected") {
                    return productT
                } else {
                    return productT.provider === providerSelected
                }
            })
            .map((x) => ({ ...x, amountRestock: x.restock_limit - x.quantity }))

        setFilteredProducts(filteredProductsT)
    }, [providerSelected, restockProducts])

    const removeProduct = (code) => {
        setRestockProducts((restockProductsT) => {
            return restockProductsT.filter((x) => x.code !== code)
        })
    }

    const manageProviderSelectionProduct = (code, idx) => {
        setRestockProducts((restockProductsT) => {
            return restockProductsT.map((x) => {
                if (x.code === code) {
                    x.provider = providers[idx].id
                }
                return x
            })
        })
    }

    const manageProviderFilter = (code) => {
        if (code === providerSelected) {
            setProviderSelected("not-selected")
        } else {
            setProviderSelected(code)
        }
    }

    const updateProductProviders = async () => {
        const updatedProducts = filteredProducts.map((x) => {
            return { id: x.id, provider: x.provider }
        })

        const response = await patchData(urls.editProductProvider, updatedProducts)
        updateInventory()
    }

    function computeTotalOrderAmount() {
        const total = filteredProducts.reduce((tot, item) => tot + item.buy_price * item.amountRestock, 0)
        return total
    }

    // console.log("render")

    return (
        <Modal
            show={modalShow}
            onHide={() => {
                setModalShow(false)
            }}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <Modal.Title className="d-flex text-center contained-modal-title-vcenter align-items-center">
                    <CgFileDocument className="me-2" size={28} color={colorTheme.primaryColor} />
                    <div className="me-2">Report magazzino</div>
                    {providers.map((providerT) => {
                        return (
                            <IconButton
                                key={providerT.id}
                                className="ms-2"
                                onClick={() => {
                                    manageProviderFilter(providerT.id)
                                }}
                            >
                                {providerSelected !== providerT.id ? <BsCircle color={providerT.color} size={20} /> : <BsCircleFill color={providerT.color} size={20} />}
                            </IconButton>
                        )
                    })}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ paddingLeft: "15px", paddingTop: "0px" }}>
                <div className="d-flex flex-row justify-content-between text-secondary ps-3 p-2 pe-3">
                    <div className="ms-3"></div>
                    <div style={{ width: width }}>Prodotto</div>
                    <div className="ps-4 ms-2" style={{ width: width }}>
                        Codice
                    </div>
                    <div className="ps-2" style={{ width: width }}>
                        Quantità
                    </div>
                    <div className="ps-4" style={{ width: width }}>
                        Fornitori
                    </div>
                </div>
                {/* {products.map()

                } */}
                {filteredProducts.map((productT) => {
                    return (
                        <div key={productT.code} className="d-flex flex-row align-items-center mb-2">
                            <IconButton onClick={() => removeProduct(productT.code)}>
                                <MdOutlineClose color="red" size={18} />
                            </IconButton>
                            {/* {console.log(productT)} */}
                            <div style={{ width: width, fontWeight: "600" }}>{productT.name}</div>
                            <div className="ps-4" style={{ width: width }}>
                                {productT.code.map((e) => {
                                    return <div key={e}>{e}</div>
                                })}
                            </div>
                            <div style={{ width: width }}>
                                <InputGroup style={{ width: "50px" }}>
                                    <FormControl
                                        variant="light bg-white"
                                        style={{ borderRadius: "20px", color: "black", textAlign: "end", fontSize: "14px" }}
                                        // placeholder={productT.amountRestock}
                                        value={productT.amountRestock}
                                        onChange={(e) => handleListofDictionariesChange("amountRestock", e.target.value, productT, setFilteredProducts)}
                                    />
                                </InputGroup>
                            </div>
                            <div className="d-flex flex-row" style={{ width: width }}>
                                {providers.map((providerT, idx) => {
                                    return (
                                        <IconButton key={providerT.id} onClick={() => manageProviderSelectionProduct(productT.code, idx)}>
                                            {productT.provider !== providerT.id ? <BsCircle color={providerT.color} size={14} /> : <BsCircleFill color={providerT.color} size={14} />}
                                        </IconButton>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </Modal.Body>
            <Modal.Footer>
                {providerSelected === "not-selected" && (
                    <div className="d-flex justify-content-center flex-fill">
                        <Button variant={colorTheme.button} className="rounded-pill outline-primary" style={{ width: "33%" }} onClick={() => updateProductProviders()}>
                            Salva
                        </Button>
                    </div>
                )}
                {providerSelected !== "not-selected" && (
                    <div className="flex flex-fill ps-3">
                        <div className="flex text-xl font-semimedium">
                            Totale ordine<span className="flex font-medium ps-1"> €{computeTotalOrderAmount().toFixed(2)} </span>{" "}
                        </div>
                    </div>
                )}
            </Modal.Footer>
        </Modal>
    )
}
