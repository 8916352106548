import React, { useContext, useEffect, useState } from "react"
import LoadingScreen from "../components/dashboard/LoadingScreen"
import {
    ROLES,
    computeDailyStatsShop,
    computeStatsInventory,
    computeStatsOperators,
    computeStatsShop,
    formatStartEndDate,
    formatStartEndDateDaily,
    formatStatsListOperator,
    formatStatsListShop,
    getAdaptedAppointments,
    getData,
    getDataBooking,
    hasPermission,
    urls,
    urlsBooking,
} from "../helper"
import { useAuthentication } from "./AuthenticationProvider"
import { useSalon } from "./SalonProvider"

const DatabaseContext = React.createContext()

export function useDatabase() {
    return useContext(DatabaseContext)
}

export function DatabaseProvider({ children }) {
    const { user } = useAuthentication()
    const { selectedSalon } = useSalon()
    const [operators, setOperators] = useState([])
    const [services, setServices] = useState([])
    const [products, setProducts] = useState([])
    const [billings, setBillings] = useState([])
    const [appointments, setAppointments] = useState([])
    const [customers, setCustomers] = useState([])
    const [draftAppointments, setDraftAppointments] = useState([])
    const [anonymousCustomer, setAnonymousCustomer] = useState([])
    const [statsList, setStatsList] = useState([])
    const [incomeStatsDaily, setIncomeStatsDaily] = useState([])
    const [providers, setProviders] = useState([])
    const [loading, setLoading] = useState(true)
    const [templateMessages, setTemplateMessages] = useState([])
    const [date, setDate] = useState(new Date())

    const updateStats = async (date) => {
        if (hasPermission(user, [ROLES.manager, ROLES.owner])) {
            const [startDate, endDate] = formatStartEndDate(date)
            const [statsShop, statsOperators, statsInventory] = await Promise.all([
                computeStatsShop(startDate, endDate, selectedSalon.id),
                computeStatsOperators(startDate, endDate, undefined, selectedSalon.id),
                computeStatsInventory(selectedSalon.id),
            ])
            setStatsList(formatStatsListShop(statsShop, statsOperators, statsInventory))
        }

        if (hasPermission(user, [ROLES.manager, ROLES.salon, ROLES.owner])) {
            const [startDate, endDate] = formatStartEndDateDaily(date)
            const dailyIncomeSummary = await computeDailyStatsShop(startDate, endDate, selectedSalon.id)
            setIncomeStatsDaily(dailyIncomeSummary)
        }

        if (hasPermission(user, [ROLES.hairdresser])) {
            const [startDate, endDate] = formatStartEndDate(date)
            const statsOperator = await computeStatsOperators(startDate, endDate, user, selectedSalon.id)
            setStatsList(formatStatsListOperator(...statsOperator))
        }
    }

    const formatAppointment = (appointment) => {
        const formattedServices = appointment.appointments.map((appointmentT) => {
            return { ...appointmentT.service, operator: appointmentT.operator.id, discount: 0, quantity: 1, date: appointmentT.date, type: "service" }
        })
        const formattedAppointment = { customer: { ...appointment.customer, edited: false }, appointments: formattedServices }
        return formattedAppointment
    }

    const updateAppointments = async () => {
        const appointments = await getAdaptedAppointments(selectedSalon.id)

        const updatedDraftAppointments = appointments.map((appT) => {
            const existingCustomer = draftAppointments.find((draftAppT) => draftAppT.customer.id === appT.customer.id)
            // console.log(existingCustomer)
            if (!existingCustomer) {
                //Check if the customer already exists in the draft, avoid to overwrite a drafted customer when a new the appointments are updated
                return formatAppointment(appT)
            } else {
                return existingCustomer
            }
        })
        setAppointments(appointments)
        setDraftAppointments(updatedDraftAppointments) //This is used by the easy register to keep track of the modified customers
    }

    const initializeAppointments = async (services) => {
        const appointments = await getAdaptedAppointments(selectedSalon.id)
        const draftAppointments = appointments.map((appT) => {
            return formatAppointment(appT)
        })

        setAppointments(appointments)
        setDraftAppointments(draftAppointments)
    }

    async function retrieveData([url, setState, retrieveFunction]) {
        const data = await retrieveFunction(url + selectedSalon.id + "/")
        setState(data)
        return data
    }

    useEffect(() => {
        const handleData = async () => {
            if (hasPermission(user, [ROLES.manager, ROLES.salon, ROLES.owner])) {
                const dataToBeRetrieved = [
                    [urls.operators, setOperators, getData],
                    [urls.products, setProducts, getData],
                    [urls.providers, setProviders, getData],
                    [urls.smartsale, setBillings, getData],
                    [urls.templateMessages, setTemplateMessages, getData],
                    [urlsBooking.listServices, setServices, getDataBooking],
                    [urlsBooking.listCustomers, setCustomers, getDataBooking],
                ]

                const response = await Promise.all(dataToBeRetrieved.map((data) => retrieveData(data)))

                const anonymousCustomer = await getDataBooking(urlsBooking.retrieveCustomerAnonymous)
                setAnonymousCustomer(anonymousCustomer)
                await initializeAppointments(response[5])
                await updateStats(date)
            }

            if (hasPermission(user, [ROLES.hairdresser])) {
                await retrieveData([urlsBooking.listCustomers, setCustomers, getDataBooking])
                await updateStats(date)
            }

            setLoading(false)
        }

        handleData()
    }, [selectedSalon])

    const updateBillings = async () => {
        const billings = await getData(urls.smartsale + selectedSalon.id + "/")
        setBillings(billings)
    }

    const updateInventory = async () => {
        const products = await getData(urls.products + selectedSalon.id + "/")
        setProducts(products)
    }

    const updateCustomers = async (customer_id = undefined) => {
        if (customer_id) {
            const updatedCustomer = await getDataBooking(`${urlsBooking.retrieveCustomer}${customer_id}/`)
            setCustomers((customers) => customers.map((customer) => (customer.id === customer_id ? updatedCustomer : customer)))
        } else {
            const customers = await getDataBooking(urlsBooking.listCustomers + selectedSalon.id + "/")
            setCustomers(customers)
        }
    }

    const updateOperators = async () => {
        const operators = await getData(urls.operators + selectedSalon.id + "/")
        setOperators(operators)
    }

    const value = {
        operators: operators,
        services: services,
        products: products,
        billings: billings,
        providers: providers,
        setProviders: setProviders,
        appointments: appointments,
        customers: customers,
        draftAppointments: draftAppointments,
        setDraftAppointments: setDraftAppointments,
        setAppointments: setAppointments,
        updateBillings: updateBillings,
        updateAppointments: updateAppointments,
        updateInventory: updateInventory,
        updateStats: updateStats,
        updateCustomers: updateCustomers,
        updateOperators: updateOperators,
        statsList: statsList,
        incomeStatsDaily: incomeStatsDaily,
        anonymousCustomer: anonymousCustomer,
        templateMessages: templateMessages,
        date: date,
        setDate: setDate,
    }

    return (
        <DatabaseContext.Provider value={value}>
            <LoadingScreen text="Recupero dati" loading={loading}>
                {children}
            </LoadingScreen>
        </DatabaseContext.Provider>
    )
}
