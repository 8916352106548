import { DateTime } from "luxon"
import React, { useContext, useEffect, useState } from "react"
import { useDatabase as useBookingDatabase } from "../components/dashboard/main-panel/easy-calendar/contexts/DatabaseProvider"
import { getWeekDates } from "../components/dashboard/main-panel/easy-calendar/utils/helpers"
import { ROLES, anonymousAppointment, defaultProduct, hasPermission, lightTheme } from "../helper"
import { useAuthentication } from "./AuthenticationProvider"
import { useDatabase } from "./DatabaseProvider"
import { useSalon } from "./SalonProvider"
const DashboardContext = React.createContext()

export function useDashboard() {
    return useContext(DashboardContext)
}

export function DashboardProvider({ children }) {
    const { operators } = useBookingDatabase()
    const { anonymousCustomer } = useDatabase()
    const { selectedSalon } = useSalon()
    const { user } = useAuthentication()

    const [selectedBilling, setSelectedBilling] = useState("not-selected")
    const [cashRegisterMode, setCashRegisterMode] = useState("new-billing") // new-billing, billings-list CHECK IF TO BE REMOVED
    const [selectedAppointment, setSelectedAppointment] = useState(anonymousAppointment(anonymousCustomer))

    const [searchProduct, setSearchedProduct] = useState("")
    const [selectedProduct, setSelectedProduct] = useState(defaultProduct)

    const [selectedCustomer, setSelectedCustomer] = useState("not-selected")
    const [selectedOperator, setSelectedOperator] = useState("not-selected")

    const [messagesMode, setMessagesMode] = useState("conversations")
    const [messageText, setMessageText] = useState("")


    const [dashboard, setDashboard] = useState() // 'inventory, customers, messaging, cash-register'
    const [receipt, setReceipt] = useState(true)
    const [colorTheme, setColorTheme] = useState(lightTheme)

    const [selectedTemplate, setSelectedTemplate] = useState("not-selected")
    const [weekDates, setWeekDates] = useState(getWeekDates(DateTime.now()))
    const [calendarOperator, setCalendarOperator] = useState(user.is_operative ? operators.filter((operator) => operator.id === user.id)[0] : operators[0])
    const [dayCalendar, setDayCalendar] = useState(DateTime.now().startOf("day"))

    useEffect(() => {
        setSelectedBilling("not-selected")
        setSelectedTemplate("not-selected")
        setSelectedAppointment(anonymousAppointment(anonymousCustomer))
    }, [selectedSalon])

    useEffect(() => {
        // console.log(operators)
        setCalendarOperator(user.is_operative ? operators.filter((operator) => operator.id === user.id)[0] : operators[0])
    }, [operators])

    useEffect(() => {
        if (hasPermission(user, [ROLES.hairdresser, ROLES.manager, ROLES.owner])) {
            setDashboard("insights")
        } else if (hasPermission(user, [ROLES.salon])) {
            setDashboard("cash-register")
        }
    }, [])

    const value = {
        selectedBilling: selectedBilling,
        setSelectedBilling: setSelectedBilling,
        selectedAppointment: selectedAppointment,
        setSelectedAppointment: setSelectedAppointment,
        cashRegisterMode: cashRegisterMode,
        setCashRegisterMode: setCashRegisterMode,
        selectedProduct: selectedProduct,
        setSelectedProduct: setSelectedProduct,
        dashboard: dashboard,
        setDashboard: setDashboard,
        receipt: receipt,
        setReceipt: setReceipt,
        colorTheme: colorTheme,
        setColorTheme: setColorTheme,
        searchProduct: searchProduct,
        setSearchedProduct: setSearchedProduct,
        selectedCustomer: selectedCustomer,
        setSelectedCustomer: setSelectedCustomer,
        selectedTemplate: selectedTemplate,
        setSelectedTemplate: setSelectedTemplate,
        messagesMode: messagesMode,
        setMessagesMode: setMessagesMode,
        setSelectedOperator: setSelectedOperator,
        selectedOperator: selectedOperator,
        weekDates: weekDates,
        setWeekDates: setWeekDates,
        calendarOperator: calendarOperator,
        setCalendarOperator: setCalendarOperator,
        dayCalendar: dayCalendar,
        setDayCalendar: setDayCalendar,
        messageText: messageText,
        setMessageText: setMessageText
    }

    return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>
}
