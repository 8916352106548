import React, { useEffect, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { CgFileDocument } from "react-icons/cg"
import { useDashboard } from "../../../../contexts/DashboardProvider"
import { formatStartEndDateVariableMonth, getData, urls } from "../../../../helper"
import { useSalon } from "../../../../contexts/SalonProvider"

export default function InventoryUsage({ modalShow, setModalShow, sortedProducts }) {
    const { colorTheme } = useDashboard()
    const { selectedSalon } = useSalon()
    const timeframes = [
        { id: 1, title: "1 mese", monthCounter: -1 },
        { id: 2, title: "3 mesi", monthCounter: -3 },
        { id: 3, title: "6 mesi", monthCounter: -6 },
    ]

    const [selectedTimeframeID, setSelectedTimeframeID] = useState(timeframes[0])
    const [comsumedProducts, setComsumedProducts] = useState([])
    const width = "33%"

    const handleTimeframe = async (timeframe) => {
        setSelectedTimeframeID(timeframe.id)
        const [startDate, endDate] = formatStartEndDateVariableMonth(new Date(), timeframe.monthCounter)
        const response = await getData(urls.inventoryComsumption + startDate + "/" + endDate + "/" + selectedSalon.id + "/")
        setComsumedProducts(response)
    }

    useEffect(() => {
        handleTimeframe(timeframes[0])
    }, [selectedSalon])

    return (
        <Modal
            show={modalShow}
            onHide={() => {
                setModalShow(false)
            }}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <Modal.Title className="d-flex text-center contained-modal-title-vcenter align-items-center">
                    <CgFileDocument className="me-2" size={28} color={colorTheme.primaryColor} />
                    <div className="me-4">Storico prodotti</div>

                    {timeframes.map((timeframe) => {
                        const selected = timeframe.id === selectedTimeframeID
                        return (
                            <Button key={timeframe.id} variant={`${selected ? "primary" : "outline-primary"}`} className="me-2" size="sm" onClick={() => handleTimeframe(timeframe)}>
                                {timeframe.title}
                            </Button>
                        )
                    })}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ paddingLeft: "15px", paddingTop: "0px" }}>
                <div className="d-flex flex-row justify-content-between text-secondary ps-3 p-2 pe-3">
                    <div className="ms-2"></div>
                    <div style={{ width: width }}>Prodotto</div>
                    <div className="ps-4 ms-2" style={{ width: width }}>
                        Codice
                    </div>
                    <div className="ps-2 " style={{ width: width }}>
                        Quantità
                    </div>
                </div>
                {comsumedProducts.map((productT) => {
                    return (
                        <div key={productT.code} className="d-flex flex-row align-items-center mb-3">
                            <div className="me-4"></div>

                            <div style={{ width: width, fontWeight: "600" }}>{productT.name}</div>
                            <div className="ps-4" style={{ width: width }}>
                                {productT.code.map((e) => {
                                    return <div key={e}>{e}</div>
                                })}
                            </div>
                            <div className="ps-4" style={{ width: width }}>
                                {productT.quantity}
                            </div>
                        </div>
                    )
                })}
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </Modal>
    )
}
