import React, { useContext, useEffect, useState, useRef } from "react"
import { useDashboard } from "../../../../contexts/DashboardProvider"
import { getDataBooking, stringToHslColor, urlsBooking, getDataTime, ROLES, adjustLuminosity } from "../../../../helper"
import BioPanel from "./BioPanel"
import BillingHistory from "./BillingHistory"
import StatsPanel from "./StatsPanel"
import CircularProgress from "@mui/material/CircularProgress"
import { getData, urls, computeStatsCustomer, defaultadvancedStatsCustomer } from "../../../../helper"
import { Alert } from "react-bootstrap"
import { BsDot } from "react-icons/bs"
import PermissionGate from "../../../permissions/PermissionGate"
import { OperatorBadge } from "../easy-calendar/components/AppointmentModal"
import { DateTime } from "luxon"
import { useSalon } from "../../../../contexts/SalonProvider"

function IncomingAppointments({ futureAppointments, classNameCard }) {
    return (
        <div>
            {futureAppointments.map((appointment) => {
                const datetime = getDataTime(appointment.start_time).split("-")
                const startTime = datetime[1].slice(0, 5)
                const endTime = getDataTime(appointment.end_time).split("-")[1].slice(0, 5)

                return (
                    <div key={appointment.id} className={`flex flex-col text-start bg-gray-50 mb-2 p-3 ps-4 rounded-[15px] w-full ${classNameCard}`}>
                        <div className="flex  rounded justify-between items-center mb-1">
                            <div className="flex py-1 !px-4 bg-primary/90 text-white rounded-md ">{datetime[0]}</div>
                            <div className="flex py-1 !px-4 text-primary  font-semibold  ">
                                {startTime}-{endTime}
                            </div>
                        </div>
                        {appointment.appointment_details.map((detail) => {
                            return (
                                <div key={detail.id} className="d-flex flex-row justify-content-between mt-1 items-center " style={{ width: "100%", gap: "5px" }}>
                                    <div className="flex ps-1 text-secondary  tracking-tight items-center mt-2 gap-2">
                                        <OperatorBadge operator={detail.operator} size={24} extraDetails={false} />

                                        {detail.service.name}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )
            })}
        </div>
    )
}

export function AppointmentsPanel({ pastAppointments, futureAppointments, classNameCard }) {
    if (pastAppointments.length === 0 && futureAppointments.length === 0) {
        return <div className="text-secondary">Nessun appuntamento presente</div>
    }
    // Function to remove future appointments with the same date as past appointments
    function removeFutureAppointmentsWithSameDate(pastAppointments, futureAppointments) {
        const pastDates = pastAppointments.map((appointment) => {
            return DateTime.fromISO(appointment.date).toISODate() // Convert to ISO date
        })

        const filteredFutureAppointments = futureAppointments.filter((appointment) => {
            const appointmentDate = DateTime.fromISO(appointment.start_time).toISODate() // Convert to ISO date
            return !pastDates.includes(appointmentDate)
        })

        return filteredFutureAppointments
    }

    // Call the function and log the result
    const updatedFutureAppointments = removeFutureAppointmentsWithSameDate(pastAppointments, futureAppointments)

    return (
        <>
            <IncomingAppointments futureAppointments={updatedFutureAppointments} classNameCard={classNameCard} />
            <BillingHistory pastAppointments={pastAppointments} classNameCard={classNameCard} />
        </>
    )
}

export default function EasyCustomers() {
    const {selectedSalon} = useSalon()
    const { selectedCustomer } = useDashboard()
    const [futureAppointments, setFutureAppointments] = useState([])
    const [statsCustomer, setStatsCustomer] = useState([])
    const [advancedStatsCustomer, setAdvancedStatsCustomer] = useState(defaultadvancedStatsCustomer)

    useEffect(() => {
        const getStatsCustomer = async () => {
            if (selectedCustomer !== "not-selected") {
                const statsCustomer = await getData(urls.statsCustomers + selectedCustomer.id + "/" + selectedSalon.id + "/")
                const futureAppointments = await getDataBooking(urlsBooking.getFutureAppointments + selectedCustomer.id + "/" + selectedSalon.id + "/")
                setFutureAppointments(futureAppointments)
                setStatsCustomer(statsCustomer)
                setAdvancedStatsCustomer(computeStatsCustomer(statsCustomer))
            }
        }
        getStatsCustomer()
    }, [selectedCustomer, selectedSalon])

    // console.log(selectedCustomer)
    if (selectedCustomer === "not-selected") {
        return (
            <div className="d-flex justify-content-center " style={{ fontSize: "48px", paddingTop: "300px", color: "lightgray" }}>
                Seleziona cliente
            </div>
        )
    }

    return (
        <div className="d-flex flex-row">
            <div className="d-flex flex-column ps-3 pe-3 " style={{ width: "60%" }}>
                <PermissionGate permissions={[ROLES.manager, ROLES.owner]}>
                    <div className="mb-4" style={{ fontSize: "22px", fontWeight: "600" }}>
                        Statistiche
                    </div>

                    <StatsPanel advancedStatsCustomer={advancedStatsCustomer} />
                </PermissionGate>

                <div className="mb-4 mt-4" style={{ fontSize: "22px", fontWeight: "600" }}>
                    Appuntamenti
                </div>
                <AppointmentsPanel pastAppointments={statsCustomer} futureAppointments={futureAppointments} classNameCard="bg-white" />
            </div>
            <div className="d-flex flex-column ps-3 pe-3" style={{ width: "60%" }}>
                <div className="mb-4" style={{ fontSize: "22px", fontWeight: "600" }}>
                    Contatto
                </div>
                <BioPanel advancedStatsCustomer={advancedStatsCustomer} selectedCustomer={selectedCustomer} />
            </div>
        </div>
        // </div>
    )
}
