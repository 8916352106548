import React, { useEffect, useRef } from 'react'
import { useState } from "react"
import { ListGroup, FormControl, InputGroup } from "react-bootstrap"
import { useDatabase } from '../../../../contexts/DatabaseProvider';
import { useDashboard } from '../../../../contexts/DashboardProvider';
import { CgFileDocument } from "react-icons/cg";
import { AiOutlineHistory, AiOutlinePlusCircle } from 'react-icons/ai'
import { FiShoppingCart } from 'react-icons/fi'
import { BsFilterLeft } from 'react-icons/bs'
import { IconButton } from '@mui/material';
import { defaultProduct } from '../../../../helper';

import InventorySummary from './InventorySummary'
import { MdHistory } from 'react-icons/md';
import InventoryUsage from './InventoryUsage';

export default function InventoryList() {
    const { products } = useDatabase()
    const { selectedProduct, setSelectedProduct, setSearchedProduct, searchProduct, colorTheme } = useDashboard()
    const [filterState, setFilterState] = useState(0) //States ['all','sellable','non-sellable']
    const [sortedProducts, setSortedProducts] = useState([])
    const [filteredProducts, setFilteredProducts] = useState([])
    const searchProductRef = useRef(null)
    const [modalShowSummary, setModalShowSummary] = useState(false)
    const [modalShowHistory, setModalShowHistory] = useState(false)

    const sortByLowQuantityAndRestock = (a, b) => {
        return (a.quantity - a.restock_limit) - (b.quantity - b.restock_limit)
    }

    useEffect(() => {

        const productsT = [...products]
        switch (filterState) {
            case 0: //all
                setSortedProducts(productsT.sort((a, b) => sortByLowQuantityAndRestock(a, b)))
                break;
            case 1: //sellable
                setSortedProducts(productsT.filter(productT => productT.sellable === true).sort((a, b) => sortByLowQuantityAndRestock(a, b)))
                break;
            case 2: //non-sellable
                setSortedProducts(productsT.filter(productT => productT.sellable === false).sort((a, b) => sortByLowQuantityAndRestock(a, b)))
                break;
            default:

        }
        searchProductRef.current.focus()
    }, [filterState, products])

    useEffect(() => {
        const sortedProductsT = [...sortedProducts]
        const filteredProducts = sortedProductsT.filter((sortedProduct) => {
            return (String(sortedProduct["code"]).includes(searchProduct) || String(sortedProduct["name"]).toLowerCase().includes(searchProduct.toLowerCase()))
        })

        setFilteredProducts(filteredProducts)

        if (filteredProducts.length === 1) {
            setSelectedProduct(filteredProducts[0])
        }

    }, [searchProduct, sortedProducts])

    const filterProduct = () => {
        if (filterState === 2) {
            setFilterState(0)
        }
        else {
            setFilterState(filterState + 1)
        }
    }



    return (
        <>
            <InventorySummary modalShow={modalShowSummary} setModalShow={setModalShowSummary} sortedProducts={sortedProducts} />
            <InventoryUsage modalShow={modalShowHistory} setModalShow={setModalShowHistory} sortedProducts={sortedProducts} />

            {/* {console.log(defaultProduct)} */}
            <div className='d-flex flex-row pt-3 ps-3 pe-3 text-center align-items-center justify-content-between' style={{ fontSize: "20px", fontWeight: "500", color: colorTheme.primaryColor, paddingBottom: '9px' }}>
                <div></div>
                <div>
                    Prodotti
                </div>
                <div>
                <IconButton
                    onClick={() => { setModalShowHistory(true) }}>
                    <AiOutlineHistory color={colorTheme.primaryColor} />                    
                </IconButton>
                <IconButton onClick={() => setSelectedProduct({...defaultProduct})}>
                    <AiOutlinePlusCircle color={colorTheme.primaryColor} />
                </IconButton>
                </div>

            </div>
            <div className='d-flex flex-row align-items-center p-3 ps-4 pe-4' style={{ backgroundColor: colorTheme.thirdColor }}>

                <IconButton
                    onClick={() => { setModalShowSummary(true) }}>
                    <CgFileDocument color={colorTheme.primaryColor} />
                </IconButton>


                <InputGroup className='ms-2'>
                    <FormControl autoFocus ref={searchProductRef} value={searchProduct} onChange={e => setSearchedProduct(e.target.value)} variant='secondary' style={{ borderRadius: "20px", border: "0px" }} placeholder="Cerca prodotto" />
                </InputGroup>
                <div className='ms-2'>
                    <IconButton onClick={() => filterProduct()}>
                        <BsFilterLeft />
                    </IconButton>
                </div>
            </div>
            <div className='overflow-auto' >
                <ListGroup className='d-flex flex-fill' variant="flush" style={{ fontSize: "18px" }} >
                    {/* {console.log(filteredProducts)} */}
                    {filteredProducts.map((filteredProduct) => {
                        const selected = selectedProduct.id === filteredProduct.id
                        return (
                            <ListGroup.Item
                                action
                                key={filteredProduct.id}
                                onClick={() => { setSelectedProduct(filteredProduct) }}
                                active={selected}
                                style={{ paddingLeft: "35px", paddingRight: "35px", backgroundColor: selected && colorTheme.primaryColor }}>
                                <div className='d-flex flex-row justify-content-between align-items-end'>
                                    <div className='flex-fill text-base'>
                                        {filteredProduct.name}
                                    </div>
                                    <div className='me-3' style={{ fontSize: "15px" }}>
                                        x{filteredProduct.quantity}
                                    </div>
                                    <div >
                                        {filteredProduct.sellable ? <FiShoppingCart color={selected ? "white" : colorTheme.primaryColor} /> : <FiShoppingCart color={selected ? colorTheme.primaryColor : "white"} />}
                                    </div>
                                </div>
                            </ListGroup.Item>
                        )

                    })}
                </ListGroup>
            </div>


            
        </>
    );

}