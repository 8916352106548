import React, { useEffect, useState } from "react"
import { getData } from "../../helper"

export default function useNewVersionNotification() {
    const [currentVersion, setCurrentVersion] = useState(null)
    const [newVersionAvailable, setNewVersionAvailable] = useState(false)

    useEffect(() => {
        // Fetch the current version at load time
        const fetchCurrentVersion = async () => {
            const response = await fetch("/build_version.json")
            const data = await response.json(); // Extract JSON data

            setCurrentVersion(data.version)
        }

        fetchCurrentVersion()

        const interval = setInterval(
            async () => {
                const response = await fetch("/build_version.json")
                // Check if the version has changed
                const data = await response.json(); // Extract JSON data

                if (currentVersion && data.version !== currentVersion) {
                    // console.log("here")
                    setNewVersionAvailable(true) // New build available
                }
            },
            // 5 * 60 * 1000 = 5 minutes
            5*60*1000
        )

        // Clear the interval when component unmounts
        return () => clearInterval(interval)
    }, [])

    const forceUpdate = () => {
        window.location.reload(true)
    }

    return { newVersionAvailable, forceUpdate }
}
