import { IconButton } from '@mui/material'
import React,{useEffect} from 'react'
import { useState } from "react"
import { FormControl, InputGroup, Dropdown, Col, Row } from "react-bootstrap"
import { AiOutlineDelete } from 'react-icons/ai'
import { MdSchool } from 'react-icons/md'
import { useDashboard } from '../../../../contexts/DashboardProvider'

export default function Service({ operators, serviceSelected, setServicesSelected }) {
    const [operatorSelected, setOperatorSelected] = useState({ id: '', name: '---' })
    const [service, setService] = useState(serviceSelected)
    const { colorTheme } = useDashboard()

    // console.log(operators)


    useEffect(() => {
        setService({ ...serviceSelected })
    }, [serviceSelected])

    const removeService = () => {
        setServicesSelected(servicesT => { return [...servicesT.filter(serviceTT => { return serviceTT !== serviceSelected })] })
    }

    const updateServices = (prevVar, newVar) => {
        setServicesSelected(servicesT => {
            // the setSelectedService was not working so it was decided to import all the services 
            // and update the selected one here, for some reason it works even if not in state mode 
            servicesT.map(serviceTT => {
                if (serviceTT === serviceSelected) {
                    //If there are two Piega it is not going to be changed the operator in both of them beacause each object has its own pointer        
                    serviceTT[prevVar] = newVar
                }
                return serviceTT
            })
            const updateServices = [...servicesT]
            return updateServices
        })
    }

    const handleOperatorSelection = (operator) => {
        setOperatorSelected(operator)
        updateServices('operator', operator.id)
    }

    const updatePrice = (newPrice) => {
        const intPrice = Number(newPrice)
        if (intPrice >= 0) {                  
            updateServices('sell_price', intPrice)
        }
        else {        
            updateServices('sell_price', service.sell_price)
        }
    }

    const updateDiscount = (newDiscount) => {
        const intDiscount = Number(newDiscount)
        if (intDiscount > 0 && intDiscount <= 100) {

            
            updateServices('discount', intDiscount)
        }
        else if (intDiscount > 100) {
            
            updateServices('discount', 100)
        }
        else if (intDiscount <= 0) {
            
            updateServices('discount', 0)
        }
    }

    const manageOperatorShown = () => { //If the services are not initialized from the appointments set it to '---'
        // console.log(operators)
        if (serviceSelected.operator !== '---') {
            return operators.filter((operator) => serviceSelected.operator === operator.id)[0].first_name
        }
        else {
            return '---'
        }
    }


    return (
        <>
            <Row className="p-2 ps-3 pe-3 align-items-center" style={{ fontSize: "15px", margin: "0px" }}>
                <Col lg="9"> {serviceSelected.name}
                </Col>
                <Col className="d-flex flex-row justify-content-end align-items-center ">
                    <IconButton size='medium' onClick={() => { removeService() }}>
                        <AiOutlineDelete color="tomato" />
                    </IconButton>
                </Col>
            </Row>
            <Row className="d-flex flex-row p-3" style={{ backgroundColor: "whitesmoke", margin: "0px", borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px" }} >
                <div className="d-flex flex-row align-items-center mb-3 " >
                    <div className='text-secondary'>CON</div>
                    <div className="ps-3 pe-3 flex-fill">
                        <Dropdown  >
                            <Dropdown.Toggle variant='light bg-white' className="rounded-pill" style={{ width: "100%", }} id="dropwn-basic" >
                                <span style={{ color: "black" }} > {manageOperatorShown()} </span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {operators.map((operator) => (
                                    <Dropdown.Item
                                        key={operator.id}
                                        onClick={() => { handleOperatorSelection(operator) }}
                                        >
                                        {operator.first_name}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>


                    </div>
                    <div className='text-secondary' style={{ width: "100px" }}>
                        <InputGroup >
                            <InputGroup.Text variant="light bg-white" style={{ borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px", border: "0px" }}>€</InputGroup.Text>
                            <FormControl type="number"
                                value={String(serviceSelected.sell_price)} onChange={e => updatePrice(e.target.value)}
                                variant='light bg-white'
                                style={{ borderTopRightRadius: "20px", borderBottomRightRadius: "20px", border: "0px", color: "black" }}
                                placeholder={service.sell_price} />
                        </InputGroup>
                    </div>
                </div>
                <div className="d-flex flex-row align-items-center" >
                    <div className='text-secondary me-4'>SCONTO</div>
                    <div className='flex-fill' style={{ width: "0px" }}>                   
                        <InputGroup >
                            <InputGroup.Text style={{ borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px", border: "0px" }}>%</InputGroup.Text>
                            <FormControl  value={serviceSelected.discount} onChange={e => updateDiscount(e.target.value)} variant="secondary" style={{ borderTopRightRadius: "20px", borderBottomRightRadius: "20px", border: "0px", textAlign: "end" }} placeholder='0' />
                        </InputGroup>
                    </div>
                    <IconButton onClick={() => {serviceSelected.discount === 10 ? updateDiscount(0) : updateDiscount(10)}} >
                        <MdSchool color={serviceSelected.discount !== 10 ? 'gray' : colorTheme.primaryColor}/>
                    </IconButton>
                </div>
            </Row>

        </>

        // style={{width: "25px"}}


    );

}