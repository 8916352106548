import React, { useRef, useEffect, useState, useCallback } from "react"
import { useDashboard } from "../../../../contexts/DashboardProvider"
import { defaultAlertBox, formatPhone, conversations, getDataTime } from "../../../../helper"
import { Alert, Button } from "react-bootstrap"
import { useMessages } from "../../../../contexts/MessagesProvider"
import { isTimeExpired } from "../../../../helper"
import { CircularProgress, IconButton } from "@mui/material"
import { FiDelete } from "react-icons/fi"
import { AiFillDelete, AiFillPauseCircle, AiFillPlayCircle, AiFillStop, AiOutlineDelete } from "react-icons/ai"
import { useAuthentication } from "../../../../contexts/AuthenticationProvider"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"

function AudioPlayer({ src }) {
    const playerRef = useRef(null)
    const [isPlaying, setIsPlaying] = useState(false)

    useEffect(() => {
        // Dynamically load the ogv.js script
        const script = document.createElement("script")
        script.src = `/ogvjs-1.9.0/ogv.js`
        script.async = true

        script.onload = () => {
            // Initialize the OGVPlayer after the script is loaded
            playerRef.current = new window.OGVPlayer({
                forceWebGL: true,
            })
            playerRef.current.src = src
        }

        document.body.appendChild(script)

        // Cleanup script and player on component unmount
        return () => {
            document.body.removeChild(script)
        }
    }, [])

    const playAudio = () => {
        if (playerRef.current) {
            playerRef.current.play()
            setIsPlaying(true)
        }
    }

    const pauseAudio = () => {
        if (playerRef.current) {
            playerRef.current.pause()
            setIsPlaying(false)
        }
    }

    return (
        <div>
            {isPlaying ? (
                <button onClick={pauseAudio}>
                    <AiFillPauseCircle className="h-8 w-8" />
                </button>
            ) : (
                <button onClick={playAudio}>
                    <AiFillPlayCircle className="h-8 w-8" />
                </button>
            )}
        </div>
    )
}

export default function MessagesBubbles() {
    const { colorTheme } = useDashboard()
    const { messages, deleteConversation, selectedConversation } = useMessages()
    const [isProcessing, setIsProcessing] = useState(false)
    const [listUrls, setListUrls] = useState([])
    const { user } = useAuthentication()

    // const [mediaUrls, setMediaUrls] = useState({})

    // const [alertBox, setAlertBox] = useState(defaultAlertBox)
    const alertBox = { mode: "warning", message: "Messaggi non inviabili. Finestra di 24 ore scaduta" }

    async function safeDeleteConversation() {
        setIsProcessing(true)
        await deleteConversation()
        setIsProcessing(false)
    }

    useEffect(() => {
        const getMediaUrls = async (messages) => {
            const tempListUrls = messages.map(async (message) => {
                if (message.state.type === "media") {
                    const mediaUrl = await message.media.getContentTemporaryUrl()
                    const mediaInfo = { id: message.state.sid, url: mediaUrl }
                    // console.log(mediaInfo)
                    return mediaInfo
                }
                return { id: null, url: "it's not a media" }
            })
            setListUrls(await Promise.all(tempListUrls))
            // setListUrls(tempListUrls)
        }

        getMediaUrls(messages)

        // console.log(temp)
    }, [messages])

    const scrollToView = useCallback((node) => {
        if (node) {
            node.scrollIntoView({ behavior: "instant" })
        }
    }, [])

    const showMedia = (message, type) => {
        const mediaUrl = listUrls.find((element) => element.id === message.state.sid)
        if (mediaUrl === undefined) {
            return (
                <div className="d-flex flex-fill justify-content-center">
                    <CircularProgress style={{ color: "white" }} />
                </div>
            )
        }
        // console.log(type)

        if (type === "image/jpeg") {
            // console.log(mediaUrl)
            return <img width={250} className="img-fluid" src={mediaUrl.url} alt="" />
        } else {
            return (
                // <a href={mediaUrl.url} target="_blank" rel="noreferrer noopener">
                // <Button variant="primary">Clicca qui per aprire il file</Button>

                // </a>

                <AudioPlayer src={mediaUrl.url} />
            )
        }
    }

    const lastUserMessage = messages.filter((x) => x.state.author !== "LDN").sort((a, b) => b.state.timestamp - a.state.timestamp)[0]
    let isExpired
    if (lastUserMessage === undefined) isExpired = false
    else isExpired = isTimeExpired(lastUserMessage?.state.timestamp)

    return (
        <>
            <div className="flex flex-col overflow-auto mb-1 pt-4 ps-4 pe-4 pb-2 h-full">
                <div className="flex pb-4 ">
                    <div className="flex flex-row">
                        {selectedConversation._internalState.attributes.salon.map((salon) => {
                            const salonName = user.salon.find((salonTT) => salonTT.id === salon)?.name
                            return <div className="flex flex-row p-2 rounded-xl bg-gray-200 mr-2 text-gray-600 font-medium w-20 text-center justify-center">{salonName}</div>
                        })}
                    </div>

                    
                    <button
                        className="flex p-2"
                        disabled={isProcessing}
                        onClick={() => safeDeleteConversation()}

                    >
                        <FontAwesomeIcon icon={faTrash} size="lg" className="text-red-600/70" />
                    </button>
                </div>
                {messages.map((message, idx) => {
                    // console.log(message)
                    const isLastMessage = idx === messages.length - 1 ? true : false
                    return (
                        <div key={idx}>
                            <div ref={isLastMessage ? scrollToView : null} className={`d-flex mb-4 ${message.author === "LDN" && "justify-content-end"} `}>
                                <div className="d-flex flex-column" style={{ maxWidth: "80%" }}>
                                    <div className="ps-2 mb-1 text-secondary" style={{ fontWeight: "600", fontSize: "12px" }}>
                                        {getDataTime(message.state.timestamp).slice(0, 16)}
                                    </div>
                                    <div
                                        className={`p-3 ${message.author !== "LDN" ? "text-white bg-primary" : "text-dark bg-white"} `}
                                        style={{ whiteSpace: "pre-wrap", borderRadius: "20px", fontSize: "14px", boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)" }}
                                    >
                                        <div className="mb-2">{message.state.type === "text" ? message.body : showMedia(message, message.state.media.state.contentType)}</div>

                                        <div className="text-end" style={{ fontSize: "12px" }}>
                                            {message.author !== "LDN" ? formatPhone(message.author.split(":")[1]) : "LDN Team"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
                {isExpired && (
                    <div>
                        <Alert ref={isExpired ? scrollToView : null} className="text-center  mb-3" variant={alertBox.mode}>
                            <div>{alertBox.message}</div>
                        </Alert>

                        <div className="d-flex justify-content-center mb-3">
                            <Button
                                disabled={isProcessing}
                                variant={colorTheme.button}
                                style={{ borderRadius: "25px", fontSize: "15px", paddingLeft: "25px", paddingRight: "25px" }}
                                onClick={() => {
                                    safeDeleteConversation()
                                }}
                            >
                                Archivia chat
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}
