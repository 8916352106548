import React, { useContext, useEffect, useState } from 'react'
import { Button, InputGroup, FormControl, Alert } from "react-bootstrap"
import { LDNlogoHorizontal, sendData, urls, TextButton } from '../../helper'
import { useNavigate } from 'react-router-dom';
import { useAuthentication } from '../../contexts/AuthenticationProvider';
import logo from "../../logo-ldn-black.png"

export default function Login() {
    const { logIn } = useAuthentication()

    const defaultAlertBox = { mode: "", message: "", enabled: false }
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [alertBox, setAlertBox] = useState(defaultAlertBox)
    const navigate = useNavigate()


    const handleLogin = async () => {
        setAlertBox(defaultAlertBox)
        const regexEmail = new RegExp('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$')
        const regexPassword = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})')

        if (regexEmail.test(email) ) {
            const authResponse = await logIn(email, password)
            if (authResponse.type === 'error') {
                setAlertBox({ mode: 'danger', message: authResponse.msg, enabled: true })
            }
        }
        else {
            setAlertBox({ mode: "danger", message: 'Formato dati di accesso non validi', enabled: true })
        }
    }

    return (

        <div className='d-flex justify-content-center' style={{ paddingTop: '12vh' }}>
            <div className='d-flex flex-column mb-3 p-4' style={{ borderRadius: "15px" }}>
                <div className='d-flex justify-content-center mb-2' >
                    <div className="flex rounded overflow-clip ">
                        {/* <LDNlogoHorizontal />
                         */}
                         <img src={logo} alt='Logo LDN Hair' width={150} />
                    </div>
                </div>


                <div className='d-flex justify-content-center pt-3 mb-3 ' style={{ fontSize: 18, textAlign: 'center', color: 'dimgray' }}>
                    Accedi per scoprire le novità di LDN Hair Salon
                </div>

                <div className='d-flex justify-content-center pt-3 mt-2'>
                    <InputGroup style={{ width: '350px' }}>
                        <FormControl
                            variant='secondary'
                            style={{ borderRadius: "20px", width: '350px', height: '50px' }}
                            type='email'
                            placeholder={'Email'}
                            value={email}
                            onChange={e => { setEmail(e.target.value) }}
                        />
                    </InputGroup>
                </div>

                <div className='d-flex justify-content-center pt-3 mt-2'>
                    <InputGroup style={{ width: '350px' }}>
                        <FormControl
                            variant='secondary'
                            style={{ borderRadius: "20px", width: '350px', height: '50px' }}
                            type='password'
                            placeholder={'Password'}
                            value={password}
                            onChange={e => { setPassword(e.target.value) }}
                        />
                    </InputGroup>
                </div>
                <div className='d-flex justify-content-center pt-3 mt-3'>
                    <Button
                        variant={'primary'}
                        disabled={email === '' || password === ''}
                        style={{ borderRadius: "15px", width: '350px', fontSize: '16px', height: '45px' }}
                        size="lg"
                        onClick={() => { handleLogin() }}
                    >
                        Accedi
                    </Button>
                </div>
                <div className='d-flex justify-content-center mt-2'>
                    {alertBox.enabled && <Alert
                        className='text-center'
                        variant={alertBox.mode}
                        style={{ width: '350px' }}
                    >
                        {alertBox.message}</Alert>}
                </div>

                <div className='d-flex justify-content-center pt-2' style={{ fontSize: '14px', color: 'gray' }}>
                    <TextButton onClick={() => navigate('/reset-password')}>
                        Password dimenticata?
                    </TextButton>
                </div>

            </div>
        </div >
    )
}