import { CircularProgress, IconButton } from "@mui/material"
import React, { useEffect, useRef, useState, useTransition } from "react"
import { Alert, Button, Container, FormControl, InputGroup, ListGroup, Modal, Spinner } from "react-bootstrap"
import { BsCheckCircle, BsCircle, BsCircleFill } from "react-icons/bs"
import { FiPlus } from "react-icons/fi"
import { IoMdMail } from "react-icons/io"
import { useDashboard } from "../../../../contexts/DashboardProvider"
import { fidelityScoreMapping, loyaltyCategories, sendData, urls } from "../../../../helper"
import { NavigationBar } from "../easy-operators/EasyOperators"

export default function CustomerHandler({ selectedCustomerTemplate, setSelectedCustomerTemplate, customers, unselectedCustomers }) {
    const { colorTheme, selectedTemplate } = useDashboard()
    const defaultAlertBox = { mode: "", message: "", enabled: false }

    const [isPending, startTransition] = useTransition()
    const [customersSelected, setCustomersSelected] = useState([])
    const [alertBox, setAlertBox] = useState(defaultAlertBox)
    const [modalShowCustomer, setModalShowCustomer] = useState(false)
    const [modalShowConfirmation, setModalShowConfirmation] = useState(false)
    const [searchCustomer, setSearchedCustomer] = useState("")
    const [filteredCustomers, setFilteredCustomers] = useState([])
    const [isProcessingTransaction, setProcessingTransaction] = useState(false)
    const [selectedCustomersModal, setSelectedCustomersModal] = useState([])
    const searchCustomerRef = useRef(null)
    const [selectedCategoryIdx, setSelectedCategoryIdx] = useState(0)


    const filterCustomers = () => {
        const customersT = [...customers]
        // console.log(customersT)
        const filteredCustomers = customersT
            .filter((customer) => {
                return String(customer.full_name).toLowerCase().includes(searchCustomer.toLowerCase())
            })
            .filter((x) => x.fidelity_score === loyaltyCategories[selectedCategoryIdx][1])

        setFilteredCustomers(filteredCustomers)
    }

    useEffect(() => {
        setSelectedCustomersModal({ ...unselectedCustomers })
        setCustomersSelected([])
    }, [selectedTemplate])

    useEffect(() => {
        startTransition(() => {
            filterCustomers()
        })
    }, [searchCustomer, selectedCategoryIdx])

    useEffect(() => {
        if (modalShowCustomer) {
            searchCustomerRef.current.focus()
        }
    }, [modalShowCustomer])

    useEffect(() => {
        setSelectedCustomerTemplate({ id: "" })
    }, [customersSelected])

    const handleCustomersSelection = (id) => {
        setSelectedCustomersModal((idT) => {
            const idUpdate = { ...idT }
            idUpdate[id] = !idUpdate[id]
            return idUpdate
        })
    }

    const checkIfActive = (id) => {
        return selectedCustomersModal[id]
    }

    const formatMessage = (customerT) => {
        const editedMessage = selectedTemplate.text.replace("{{1}}", customerT.first_name)
        return { ...customerT, message: editedMessage }
    }

    const addCustomer = () => {
        const newCustomersSelected = customers
            .filter((customerT) => {
                return selectedCustomersModal[customerT.id]
            })
            .map((customerT) => formatMessage(customerT))
        setCustomersSelected(newCustomersSelected)
        setModalShowCustomer(false)
        setSearchedCustomer("")
    }

    const selectCustomers = () => {
        for (let customer of filteredCustomers) {
            handleCustomersSelection(customer.id)
        }
    }

    const sendMessages = async () => {
        setProcessingTransaction(true)
        const messages = customersSelected.map((customerT) => {
            return {
                customer: customerT.id,
                template_message: selectedTemplate.id,
                message: customerT.message,
            }
        })

        // Multiple requests are needed so to not trigger the request timeout of gunicorn
        const chunkSize = 20
        for (let i = 0; i < messages.length; i += chunkSize) {
            const messages_chunk = messages.slice(i, i + chunkSize)
            const response = await sendData(urls.createMessages, messages_chunk)

            if (response.type === "success") {
                setModalShowConfirmation(false)
                setCustomersSelected([])
                setSelectedCustomerTemplate({ id: "" })
                setSelectedCustomersModal(
                    customers.reduce((customersAll, customerT) => {
                        return { ...customersAll, [customerT.id]: false }
                    }, {})
                )

                let tracking_label
                if (i + chunkSize <= messages.length) {
                    tracking_label = i
                } else {
                    tracking_label = messages.length
                }

                setAlertBox({ mode: "success", message: String(tracking_label) + "/" + String(messages.length) + " " + response.msg, enabled: true })
            } else {
                setModalShowConfirmation(false)
                setAlertBox({ mode: "danger", message: response.msg, enabled: true })
            }
            setProcessingTransaction(false)
        }
    }


    const checkIfAlreadySent = (messagesSent) => {
        if (messagesSent.length === 0) return false
        const hasBeenSent = messagesSent.some((x) => x.template_message === selectedTemplate.id)
        return hasBeenSent
    }

    return (
        <>
            <div className="d-flex flex-column p-3 " style={{ width: "50%" }}>
                <div className="d-flex card p-3" style={{ borderRadius: "15px", maxHeight: "550px" }}>
                    <div className="d-flex mb-3 align-items-center " style={{ fontSize: "16px" }}>
                        <div className="d-flex flex-fill" style={{ fontSize: "18px", fontWeight: "600" }}>
                            Clienti
                        </div>
                        <div>
                            <IconButton
                                style={{ backgroundColor: "whitesmoke" }}
                                onClick={() => {
                                    setModalShowCustomer(true)
                                }}
                            >
                                <FiPlus size={25} color={colorTheme.primaryColor} />
                            </IconButton>
                        </div>
                    </div>

                    {customersSelected.length === 0 ? (
                        <div className="d-flex text-secondary text-center">Nessun cliente in lista</div>
                    ) : (
                        <div className="overflow-auto">
                            <ListGroup className="d-flex flex-fill" variant="flush" style={{ fontSize: "18px" }}>
                                {customersSelected.map((customerT) => {
                                    const selected = selectedCustomerTemplate.id === customerT.id
                                    return (
                                        <ListGroup.Item
                                            action
                                            active={selected}
                                            key={customerT.id}
                                            style={{ border: "0px", borderRadius: "15px", marginTop: "5px", fontSize: "15px", padding: "7px" }}
                                            onClick={() => {
                                                setSelectedCustomerTemplate(customerT)
                                            }}
                                        >
                                            <div className="d-flex flex-row ">
                                                <div className="flex-fill">{customerT.full_name}</div>
                                            </div>
                                        </ListGroup.Item>
                                    )
                                })}
                            </ListGroup>
                        </div>
                    )}
                </div>

                <div className="d-flex card shadow-sm mt-2" style={{ borderRadius: "25px" }}>
                    <Button
                        variant={colorTheme.button}
                        style={{ borderRadius: "25px" }}
                        size="lg"
                        onClick={() => {
                            setModalShowConfirmation(true)
                        }}
                        disabled={customersSelected.length === 0}
                    >
                        Invia messaggio
                    </Button>
                </div>
                <Alert show={alertBox.enabled} className="text-center mt-2" variant={alertBox.mode}>
                    {alertBox.message}
                </Alert>
            </div>

            <Modal
                show={modalShowConfirmation}
                onHide={() => {
                    setModalShowConfirmation(false)
                }}
                size="sg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex text-center contained-modal-title-vcenter align-items-center">Inviare messaggio?</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: "0px" }}>
                    <div className=" text-center p-3 pb-0 mb-1" style={{ fontSize: "17px" }}>
                        Sei sicuro di volere inviare questo messaggio? <br></br>
                    </div>
                    <div className=" text-center p-3 pb-0 mb-3" style={{ fontSize: "17px" }}>
                        Ci sono <b>{customersSelected.length}</b> destinatari in lista
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-center flex-fill">
                        <Button variant="light" className="me-3" onClick={() => setModalShowConfirmation(false)}>
                            Annulla
                        </Button>
                        <Button
                            variant={colorTheme.button}
                            disabled={isProcessingTransaction}
                            onClick={() => {
                                sendMessages()
                            }}
                        >
                            {isProcessingTransaction && <Spinner as="span" animation="border" size="sm" role="status" />} Avanti
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal show={modalShowCustomer} onHide={() => setModalShowCustomer(false)} size="sg" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Lista Clienti</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: "0px" }}>
                    <Container className="d-flex flex-column " style={{ padding: "1px" }}>
                        <div className="p-3 ps-4 pe-4" style={{ backgroundColor: "whitesmoke" }}>
                            <InputGroup>
                                <FormControl
                                    ref={searchCustomerRef}
                                    value={searchCustomer}
                                    onChange={(e) => setSearchedCustomer(e.target.value)}
                                    variant="secondary"
                                    style={{ borderRadius: "20px", border: "0px" }}
                                    placeholder="Cerca cliente"
                                />
                            </InputGroup>

                            <div className="d-flex w-full mt-3 justify-content-between align-items-center">
                                <div className="d-flex ms-2 align-items-center">
                                    <div className="me-3" style={{ color: "grey" }}>
                                        Fedeltà
                                    </div>



                                    {/* <div className="d-flex "> */}
                                        {/* {Object.keys(fidelityScoreMapping).map((key) => {
                                            return (
                                                <IconButton
                                                    key={key}
                                                    onClick={() => {
                                                        manageFidelityFilter(key)
                                                    }}
                                                >
                                                    {selectedCategoryIdx !== key ? (
                                                        <BsCircle color={fidelityScoreMapping[key].filter} size={15} />
                                                    ) : (
                                                        <BsCircleFill color={fidelityScoreMapping[key].filter} size={15} />
                                                    )}
                                                </IconButton>
                                            )
                                        })} */}
                                    {/* </div> */}
                                </div>

                                <Button className="d-flex" variant={colorTheme.button} size="sm" onClick={() => selectCustomers()} style={{ height: "30px" }}>
                                    Seleziona Tutti
                                </Button>
                            </div>
                            <NavigationBar showArtifact={false} className="flex-wrap mt-2 bg-transparent !px-0" titles={loyaltyCategories.map((x) => x[0])} isSelected={(idx) => idx === selectedCategoryIdx} onClick={(idx) => setSelectedCategoryIdx(idx)} />

                        </div>

                        {isPending ? (
                            <div className="d-flex justify-content-center align-items-center p-4" style={{ height: "100%" }}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <ListGroup>
                                <div>
                                    {filteredCustomers.map((customerT) => {
                                        // console.log(customerT)
                                        return (
                                            <ListGroup.Item
                                                action
                                                key={customerT.id}
                                                variant="white"
                                                style={{ fontSize: "18px", border: "0em" }}
                                                onClick={() => {
                                                    {
                                                        handleCustomersSelection(customerT.id)
                                                    }
                                                }}
                                            >
                                                <div className="flex flex-row justify-between p-2 items-center">
                                                    <div className="me-2">
                                                        {checkIfActive(customerT.id) ? <BsCheckCircle color={colorTheme.primaryColor} className="pb-1" /> : <BsCircle color="gray" className="pb-1" />}
                                                    </div>
                                                    <div className="d-flex flex-fill">{customerT.full_name}</div>
                                                    {checkIfAlreadySent(customerT.messages_sent) && (
                                                        <div className="me-4">
                                                            <IoMdMail color={colorTheme.primaryColor} size={20} />
                                                        </div>
                                                    )}

                                                    {/* <div>{customerT.fidelity_score}</div> */}
                                                </div>
                                            </ListGroup.Item>
                                        )
                                    })}
                                </div>
                            </ListGroup>
                        )}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={colorTheme.button} onClick={() => addCustomer()}>
                        Avanti
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
