import React from "react"
import { generateTimeIntervalsInMinSlots } from "../../../../../helper"
import useComponentVisible from "../components/useComponentVisible"

export function ExpandingSection({ children, isComponentVisible, setIsComponentVisible, refSection, extraComponent, classNameExpandingSection, className }) {
    return (
        <div
            className={`grow ${className}`}
            onClick={(e) => {
                e.stopPropagation()
                setIsComponentVisible(true)
            }}
        >
            {children}
            <div className="relative" ref={refSection}>
                {isComponentVisible && (
                    <div
                        className={`absolute max-h-48 overflow-auto py-2 mt-2 gap-2 flex flex-col w-[100px] px-2 rounded-lg bg-white shadow-md z-10 outline outline-1 outline-secondary/20 ${classNameExpandingSection}`}
                    >
                        {extraComponent}
                    </div>
                )}
            </div>
        </div>
    )
}

export default function TimeSelector({ value, setValue, children, classNameExpandingSection, slotSize }) {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
    // console.log(setIsComponentVisible)


    function TimeList() {
        const timeIntervals = generateTimeIntervalsInMinSlots(10, slotSize)
        return (
            <>
                {timeIntervals.map((time) => {
                    return (
                        <div
                            key={"time_" + time.min}
                            className={` hover:bg-primary/10 tracking-tighter p-2 rounded-xl text-primary/80 text-center ${time.min === value ? "bg-primary text-white" : "bg-slate-100"}`}
                            onClick={(e) => {
                                //The propagation allows the closure of the component
                                e.stopPropagation()
                                setValue(time.min)
                                setIsComponentVisible(false)
                            }}
                        >
                            {time.formatMin}
                        </div>
                    )
                })}
            </>
        )
    }

    return (
        <ExpandingSection
            refSection={ref}
            isComponentVisible={isComponentVisible}
            setIsComponentVisible={setIsComponentVisible}
            extraComponent={<TimeList />}
            classNameExpandingSection={classNameExpandingSection}
        >
            {children}
        </ExpandingSection>
    )
}
