import React from 'react'
import { Col } from "react-bootstrap"
import { useDashboard } from '../../contexts/DashboardProvider';
import EasyMessages from './main-panel/easy-messages/EasyMessages';
import EasyRegister from './main-panel/easy-register/EasyRegister';
import EasyCustomerSidebar from './sidebar/easy-customers/EasyCustomerSidebar';
import EasyMessagesSidebar from './sidebar/easy-messages/EasyMessagesSidebar';
import BillingsList from './sidebar/easy-register/BillingsList';
import CustomersList from './sidebar/easy-register/CustomersList';
import EasyRegisterSidebar from './sidebar/easy-register/EasyRegisterSidebar';
import EasyOperatorsSidebar from './sidebar/easy-operators/EasyOperatorsSidebar';
import InventoryList from './sidebar/inventory/InventoryList';

export default function Sidebar() {
    const { dashboard } = useDashboard()
    return (

        <div className='d-flex flex-column flex-none bg-white pb-3' style={{ padding: "0px", width:'30%', height:"99%",borderRadius:"15px" }}  >
            {dashboard === "inventory" && <InventoryList/> }       
            {dashboard === "messages" && <EasyMessagesSidebar/>} 
            {dashboard === "customers" && <EasyCustomerSidebar/>}
            {dashboard === "cash-register" && <EasyRegisterSidebar/>} 
            {dashboard === "operators" && <EasyOperatorsSidebar/>}
        </div>

    );

}