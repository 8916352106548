import React, { useContext, useEffect, useState } from "react"
import TimeStats from "./TimeStats"
import TeamStats from "./TeamStats"
import IndividualStats from "./IndividualStats"
import { useDatabase } from "../../../../contexts/DatabaseProvider"
import InventoryStats from "./InventoryStats"
import PermissionGate from "../../../permissions/PermissionGate"
import { ROLES, beginningOfMonth, computeDaysList, endOfMonth, fillListWithZeros, formatStartEndDate, getDataBooking, getDataChartBookings, options, urlsBooking } from "../../../../helper"
import { DateTime } from "luxon"
import { Bar } from "react-chartjs-2"
import { useSalon } from "../../../../contexts/SalonProvider"

function BookingStats({ date }) {
    const { selectedSalon } = useSalon()
    const [dataChart, setDataChart] = useState()
    const [recapStats, setRecapStats] = useState()

    useEffect(() => {
        const appointmentDetailsStats = async () => {
            // console.log(date)
            const [startDate, endDate] = formatStartEndDate(date)
            const appointmentDetailsStats = await getDataBooking(urlsBooking.getAppointmentDetailsStats + startDate + "/" + endDate + "/" + selectedSalon.id + "/")
            setRecapStats(appointmentDetailsStats.extra)
            setDataChart(
                getDataChartBookings(
                    computeDaysList(date),
                    fillListWithZeros(appointmentDetailsStats.data_chart.internal_app_details, date),
                    fillListWithZeros(appointmentDetailsStats.data_chart.webpage_app_details, date)
                )
            )
        }

        appointmentDetailsStats()
    }, [date, selectedSalon])

    if (dataChart === undefined) return

    return (
        <div className="flex flex-col bg-white shadow-md rounded-[15px] p-4">
            <div className="flex flex-row justify-between ps-4 pe-4 mb-4 gap-4">
                {recapStats.map((stats, idx) => {
                    return (
                        <div key={"stat_booking_" + idx} className="flex flex-col">
                            <div className="flex text-primary text-[18px] font-semibold">{stats.value}</div>
                            <div className="flex text-secondary text-[12px]">{stats.title}</div>
                        </div>
                    )
                })}
            </div>
            <div>
                <Bar height={"203px"} options={options} data={dataChart} />
            </div>
        </div>
    )
}

export default function EasyInsights() {
    const { statsList, date, setDate } = useDatabase()

    const [selectedOperator, setSelectedOperator] = useState(statsList.operators[0])

    useEffect(() => {
        setSelectedOperator(statsList.operators[0])
    }, [statsList])


    return (
        <div className="d-flex flex-row">
      
            {/* {console.log(statsList)}  */}
            <PermissionGate permissions={[ROLES.manager, ROLES.owner]}>
                <div className="flex flex-col p-3 " style={{ width: "70%" }}>
                    <div className="mb-2" style={{ fontSize: "22px", fontWeight: "600" }}>
                        Negozio
                    </div>
                    <div className="mb-4">
                        <TimeStats statsList={statsList} date={date} setDate={setDate} />
                    </div>
                    <div className="mb-2" style={{ fontSize: "22px", fontWeight: "600" }}>
                        Team
                    </div>
                    {selectedOperator ? (
                        <TeamStats statsList={statsList} selectedOperator={selectedOperator} setSelectedOperator={setSelectedOperator} />
                    ) : (
                        <div className="text-black/50 mb-5">Nessun fatturato disponibile</div>
                    )}
                    <div className="mt-4 mb-2" style={{ fontSize: "22px", fontWeight: "600" }}>
                        Prenotazioni
                    </div>
                    <div className="mb-4">
                        <BookingStats date={date} />
                    </div>
                </div>
                <div className="d-flex flex-column p-3 " style={{ width: "30%" }}>
                    <div className="mb-4" style={{ fontSize: "22px", fontWeight: "600" }}>
                        Magazzino
                    </div>
                    <div className="mb-4">
                        <InventoryStats statsList={statsList} />
                    </div>
                    <div className="mt-2">{selectedOperator !== undefined && <IndividualStats selectedOperator={selectedOperator} />}</div>
                </div>
            </PermissionGate>

            <PermissionGate permissions={[ROLES.hairdresser]}>
                <div className="d-flex flex-column p-3 " style={{ width: "70%" }}>
                    <div className="mb-2" style={{ fontSize: "22px", fontWeight: "600" }}>
                        Statistiche
                    </div>
                    <div className="mb-4">
                        <TimeStats statsList={statsList} date={date} setDate={setDate} />
                    </div>
                </div>
                {/* {console.log(statsList)} */}
                <div className="d-flex flex-column p-3 " style={{ width: "30%" }}>
                    <div>{selectedOperator !== undefined && <IndividualStats selectedOperator={selectedOperator} />}</div>
                </div>
            </PermissionGate>
        </div>
    )
}
