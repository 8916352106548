import Button from "@mui/material/Button"
import Switch from "@mui/material/Switch"
import { styled } from "@mui/material/styles"
import axios from "axios"
import { Buffer } from "buffer"
import { parsePhoneNumber } from "libphonenumber-js"
import { DateTime } from "luxon"
import moment from "moment/moment"
import * as React from "react"
import tinycolor from "tinycolor2"
export const ACCESS_TOKEN = "ldn_access_token"
export const REFRESH_TOKEN = "ldn_refresh_token"
export const USER = "ldn_user"
export let baseURL, baseURLBooking, sentryEnvMode

// console.log(process.env.NODE_ENV)

if (process.env.NODE_ENV === "development") {
    baseURL = process.env.REACT_APP_BASE_URL_DEV
    baseURLBooking = process.env.REACT_APP_BASE_URL_BOOKING_DEV
    sentryEnvMode = process.env.REACT_APP_SENTRY_ENV_MODE_DEV
} else {
    baseURL = process.env.REACT_APP_BASE_URL_PROD
    baseURLBooking = process.env.REACT_APP_BASE_URL_BOOKING_PROD
    sentryEnvMode = process.env.REACT_APP_SENTRY_ENV_MODE_PROD
}

export const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 10 * 60 * 1000,
    headers: {
        "Content-Type": "application/json",
    },
})

//This one is needed to be wrapped in a function since a state is passed from AuthProvider
export const decodeToken = (tokenEncoded) => {
    return JSON.parse(Buffer.from(tokenEncoded.split(".")[1], "base64").toString())
}

export const setInterceptorRequest = () => {
    axiosInstance.interceptors.request.use(
        function (config) {
            const accessToken = localStorage.getItem(ACCESS_TOKEN)
            config.headers.authorization = accessToken ? "JWT " + accessToken : null
            return config
        },
        function (error) {
            return Promise.reject(error)
        }
    )
}

export const removeStorageTokens = () => {
    localStorage.removeItem(ACCESS_TOKEN)
    localStorage.removeItem(REFRESH_TOKEN)
    localStorage.removeItem(USER)
    window.dispatchEvent(new Event("storage"))
}

let isRefreshing = false
let failedQueue = []

export const setInterceptorResponse = () => {
    axiosInstance.interceptors.response.use(
        (response) => {
            return response
        },
        async function (error) {
            const originalRequest = error.config

            if (error.code === "ECONNABORTED") {
                alert("Timeout della risposta del server, ricaricare la pagina e verificare se la richiesta è stata soddisfatta.")
                return Promise.reject(error)
            }

            if (error.response.status === 401 && originalRequest.url === baseURL + urls.refreshToken) {
                window.location.href = "/login"
                return Promise.reject(error)
            }
            // console.log(error.response.data.code, error.response.status,  error.response.statusText)

            if (error.response.data.code === "token_not_valid" && error.response.status === 401) {
                if (error.response.data.detail === "Token is blacklisted") {
                    removeStorageTokens()
                    // setIsAuthenticated(false)
                    return error.response
                }

                if (!isRefreshing) {
                    isRefreshing = true

                    const refreshToken = localStorage.getItem(REFRESH_TOKEN)

                    if (refreshToken) {
                        const tokenParts = decodeToken(refreshToken)
                        const now = Math.ceil(Date.now() / 1000)

                        if (tokenParts.exp > now) {
                            try {
                                const { data } = await axiosInstance.post(urls.refreshToken, { refresh: refreshToken })
                                localStorage.setItem(ACCESS_TOKEN, data.access)
                                localStorage.setItem(REFRESH_TOKEN, data.refresh)
                                console.log("Token refreshed")
                                processQueue()
                                return axiosInstance(originalRequest)
                            } catch (err) {
                                console.log("Token refresh error")
                                console.log(err)
                            } finally {
                                isRefreshing = false
                            }
                        } else {
                            console.log("Refresh token is expired", tokenParts.exp, now)
                            removeStorageTokens()
                        }
                    } else {
                        console.log("Refresh token not available.")
                        removeStorageTokens()
                    }
                } else {
                    // If already refreshing, queue the failed request along with the original request
                    const retryPromise = new Promise((resolve, reject) => {
                        failedQueue.push({ originalRequest, resolve, reject })
                    })

                    return retryPromise
                }
            }

            return error.response
        }
    )
}

function processQueue() {
    failedQueue.forEach(({ originalRequest, resolve, reject }) => {
        axiosInstance(originalRequest).then(resolve).catch(reject)
    })
    failedQueue = []
}

setInterceptorResponse() // This was added as here to test check if the authentication error Anonymous user is solved. Sometimes it looks like the interceptors are not working if only set in the AuthenticationProvider, in future check why this does not provide error since
setInterceptorRequest() // Enable these two lines to use CTRL+S, but perhaps ideally could be moved somewhere. If this stay outside of here when the helper is updated the components are

export async function getData(path) {
    const response = await axiosInstance.get(path)
    return response.data
}

export async function sendData(path, content) {
    const response = await axiosInstance.post(path, content)
    // Error first argument is store into message by default, to add other variables you have to create a CustomError
    if (response.status >= 400) throw new Error(response.data)
    return response.data
}

export async function putData(path, content) {
    const response = await axiosInstance.put(path, content)
    return response.data
}

export async function patchData(path, content) {
    const response = await axiosInstance.patch(path, content)
    return response.data
}
///////////////////////////////////////////////////////////////////////////
// export let baseURLBooking

export const axiosInstanceBooking = axios.create({
    baseURL: baseURLBooking,
    timeout: 10 * 60 * 1000,
    headers: {
        "Content-Type": "application/json",
    },
})

export const setInterceptorRequestBooking = () => {
    axiosInstanceBooking.interceptors.request.use(
        function (config) {
            const accessToken = JSON.parse(localStorage.getItem(USER)).ldn_booking_token
            config.headers.authorization = accessToken ? "Api-Key " + accessToken : null
            return config
        },
        function (error) {
            return Promise.reject(error)
        }
    )
}

export const setInterceptorResponseBooking = () => {
    axiosInstanceBooking.interceptors.response.use(
        (response) => {
            return response
        },
        async function (error) {
            return error.response
        }
    )
}

setInterceptorRequestBooking()
setInterceptorResponseBooking()

export async function getDataBooking(path) {
    const response = await axiosInstanceBooking.get(path)
    return response.data
}

export async function sendDataBooking(path, content) {
    // console.log("sending")
    const response = await axiosInstanceBooking.post(path, content)
    // console.log(response)
    if (response.status >= 400) throw new Error(JSON.stringify(response.data))
    return response.data
}

export async function putDataBooking(path, content) {
    const response = await axiosInstanceBooking.put(path, content)
    if (response.status >= 400) throw new Error(JSON.stringify(response.data))

    return response.data
}

export async function patchDataBooking(path, content) {
    const response = await axiosInstanceBooking.patch(path, content)
    if (response.status >= 400) throw new Error(JSON.stringify(response.data))

    return response.data
}

export async function deleteDataBooking(path) {
    const response = await axiosInstanceBooking.delete(path)
    if (response.status >= 400) throw new Error(JSON.stringify(response.data))

    return response.data
}

export const urlsBooking = {
    listOperators: "operators/list/",
    listServices: "services/list/",
    listAppointments: "appointments/list/",
    listAppointmentsWaitingList: "appointments-waiting-list/list/",
    deleteAppointmentsDetailsWaitingList: "appointments-waiting-list/details/delete/",

    getFutureAppointments: "appointments/future/",
    getAppointmentDetailsStats: "appointment-details/stats/",

    listCustomers: "customers/list/",
    retrieveCustomer: "customers/retrieve/",
    retrieveCustomerAnonymous: "customers/anonymous/",
    updateCustomer: "customers/update/",

    createAppointment: "appointments/create/",
    deleteAppointment: "appointments/delete/",
    monthlyFreeTimeslots: "freetimeslots/monthly/",
    workingHours: "workinghours/",
    listWorkingSchedules: "workingschedules/list/",
    createWorkingSchedules: "workingschedules/create/",
    updateWorkingSchedules: "workingschedules/update/",
    deleteWorkingSchedules: "workingschedules/delete/",
    customEventsList: "customevents/list/",
    createCustomEvent: "customevents/create/",
    updateCustomEvent: "customevents/update/",
    retrieveParameters: "parameters/",
}

export async function getAdaptedAppointments(selectedSalon) {
    let appointments = await getDataBooking(urlsBooking.listAppointments + DateTime.now().startOf("day").toISO() + "/" + DateTime.now().endOf("day").toISO() + "/all/" + selectedSalon + "/")
    appointments = appointments.map((appointment) => ({ ...appointment, appointments: appointment.appointment_details.map((x) => ({ ...x, date: x.start_time })) }))
    return appointments
}

////////// Formatting Fuctions

export function percentageToAbsoluteDiscount(purchases, globalDiscount, originalGiftCardDiscount) {
    let giftCardDiscount = originalGiftCardDiscount
    const formatted_purchases = purchases.map((purchase) => {
        const totalPrice = purchase.sell_price * purchase.quantity
        const individualDiscountIncluded = (totalPrice * purchase.discount) / 100
        // console.log(individualDiscountIncluded)
        const globalDiscountIncluded = individualDiscountIncluded + ((totalPrice - individualDiscountIncluded) * globalDiscount) / 100

        const giftCardDiscountIncluded = globalDiscountIncluded + giftCardDiscount
        let finalDiscount = 0

        if (totalPrice - giftCardDiscountIncluded >= 0) {
            //if the service costs more than the discount giftCard
            finalDiscount = giftCardDiscountIncluded
            giftCardDiscount = 0
        } else {
            //if the giftCard is greater than the service, a part of the price needs to be subtracted on the next service
            finalDiscount = totalPrice
            giftCardDiscount = giftCardDiscountIncluded - totalPrice
        }

        return { ...purchase, discount: finalDiscount }
    })

    return { smartsale_details_formatted: formatted_purchases, gift_card_left_over: giftCardDiscount }
}

export function getDataTime(rawDate) {
    const date = new Date(rawDate)
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()
    let hour = date.getHours()
    let minute = date.getMinutes()
    let second = date.getSeconds()
    if (month.toString().length === 1) {
        month = "0" + month
    }
    if (day.toString().length === 1) {
        day = "0" + day
    }
    if (hour.toString().length === 1) {
        hour = "0" + hour
    }
    if (minute.toString().length === 1) {
        minute = "0" + minute
    }
    if (second.toString().length === 1) {
        second = "0" + second
    }
    var dateTime = day + "/" + month + "/" + year + "-" + hour + ":" + minute + ":" + second
    return dateTime
}

export function isTimeExpired(date) {
    //Check if a date is older than 24 from now
    const timestamp = date.getTime()
    const currentTimestamp = new Date().getTime()

    // if (currentTimestamp - timestamp >= (5 * 1000)) {
    if (currentTimestamp - timestamp >= 24 * 60 * 60 * 1000) {
        return true
    } else {
        return false
    }
}

////////// DefaultProduct
export const defaultProduct = {
    id: "new",
    name: "",
    code: [""],
    buy_price: "",
    sell_price: "",
    sellable: false,
    quantity: 0,
    restock_limit: "",
    active: true,
    provider: "",
}

export const defaultCustomer = {
    id: "new",
    name: "",
}

////////// URLs API
export const urls = {
    services: "services/",
    salons: "salons/",
    products: "products/",
    customersTemplateMessage: "customers/template-messages/", //Used only when specific data from the main is needed such as messages and fideliy score
    editProductProvider: "products/edit-providers/",
    inventoryTransactionNew: "inventory/new-transaction/",
    inventoryTransactionEdit: "inventory/edit-transaction/",
    inventoryTransactionList: "inventory/list/",
    inventoryComsumption: "inventory/comsumption/",
    operators: "operators/",
    retrieveOperator: "operators/retrieve/",
    statsRevenueBonus: "operators/stats/",

    providers: "providers/",
    smartsale: "smartsale/",
    smartsaleDelete: "smartsale/delete/",
    smartsaleDetailsShop: "smartsale/details/shop/",
    smartsaleDetailsOperators: "smartsale/details/operators/",
    dailyAppointments: "appointments/daily/",

    statsCustomers: "customers/stats/",
    templateMessages: "messages/templates/",
    createMessages: "messages/create/",

    retrieveNotIssuedGiftCards: "giftcard/retrieve/not-issued/",
    retrieveIssuedValidGiftCards: "giftcard/retrieve/issued/",
    retrieveRedeemedGiftCardsOperator: "giftcard/retrieve/redeemed/",
    retrieveAllIssuedGiftCards: "giftcard/retrieve/issued-all/",

    retrieveSpecialDaysOperator: "special-days/list/",
    requestedSpecialDaysOperators: "special-days/requested/",
    updateSpecialDaysOperators: "special-days/update/",
    computeSpecialDaysOperators: "special-days/compute/",

    createAcceptSpecialDaysOperators: "special-days/create/accept/",
    createPendingSpecialDaysOperators: "special-days/create/pending/",
    rejectSpecialDaysOperators: "special-days/reject/",
    deleteSpecialDaysOperators: "special-days/delete/",

    acceptSpecialDaysOperators: "special-days/accept/",
    retrieveStatsSpecialDaysOperators: "special-days/stats/",

    retrieveBonusOperator: "bonus-operator/",

    pairsToken: "auth/token/",
    refreshToken: "auth/token/refresh/",
    blacklistToken: "auth/token/blacklist/",
    resetPassword: "auth/operators/reset-password/",
    setNewPassword: "auth/operators/set-new-password/",

    twilioToken: "twilio/get-token/",
    deleteConversation: "twilio/delete-conversation/",

    retrieveLoyaltyStatus: "loyalty/all/",
    retrieveLoyaltyRecordsCustomer: "loyalty/customer/",
}

export const loyaltyCategories = [
    ["Stabili", "stable"],
    ["Rischio", "potential_loss"],
    ["Persi recenti", "recent_loss"],
    ["Persi", "permanent_loss"],
    ["Recuperati", "recovered"],
]

///////Round
export function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2")
}

/////////Logo

export const LDNlogo = (props) => (
    <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300" {...props}>
        <defs>
            <style>{".cls-1{fill:#fff}"}</style>
        </defs>
        <path
            className="cls-1"
            d="M-.12-.36h115.05c71.09.13 119.5 47.61 119.5 114.59 0 66.14-48.41 117.76-119.5 117.91H68.88v-165h43.45l62.13 82.26c-3.56 6.37-10.29 14.39-17.45 20.46L95.88 88.92v119.22h18.75c47.64 0 90.09-33.59 90.09-92.25 0-59.24-42.45-92.25-90.09-92.25H28.38v250.5h166.5v25.5h-195Z"
            transform="translate(.12 .36)"
        />
        <path className="cls-1" d="m221.71 211.9 49.67 65.87V-.36h28.5v300H255l-53-70.23a106.33 106.33 0 0 0 19.71-17.51Z" transform="translate(.12 .36)" />
    </svg>
)

export const LDNlogoHorizontal = (props) => (
    <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350 85.29">
        <path d="M0,0H32.71c20.21,0,34,13.54,34,32.58,0,18.81-13.76,33.48-34,33.52H19.62V19.19H32L49.64,42.58a23.45,23.45,0,0,1-5,5.81l-17.38-23v33.9h5.34c13.54,0,25.61-9.55,25.61-26.23,0-16.84-12.07-26.23-25.61-26.23H8.1V78H55.44v7.25H0Z" />
        <path d="M63.07,60.35,77.19,79.08V0h8.1V85.29H72.53l-15.06-20A30.27,30.27,0,0,0,63.07,60.35Z" />
        <path d="M191.49,7H197V78.26h-5.49V42.85H151.65V78.26h-5.49V7h5.49V37.36h39.84Z" />
        <path d="M235.18,7h7.23l25.94,71.22H262.5l-5.85-16.08H221l-5.85,16.08h-5.85ZM223,56.69h31.65L238.8,13.29Z" />
        <path d="M282.19,78.26V7h5.49V78.26Z" />
        <path d="M306.2,78.26V7h23.2a19.56,19.56,0,0,1,10.22,2.72,20,20,0,0,1,7.25,7.4,20.76,20.76,0,0,1,2.67,10.48,20.67,20.67,0,0,1-2.26,9.72,20,20,0,0,1-6.13,7.09,19.63,19.63,0,0,1-8.65,3.49L350,78.26h-6.36l-17.4-30H311.69v30Zm5.49-35.46H329a14.54,14.54,0,0,0,7.53-2,15.15,15.15,0,0,0,7.48-13.35,15.25,15.25,0,0,0-2-7.86,15,15,0,0,0-5.44-5.5,14.54,14.54,0,0,0-7.53-2H311.69Z" />
    </svg>
)

///////////Stats formatter

export const incomeFunction = (smartsaleDetailT) => {
    return smartsaleDetailT.sell_price * smartsaleDetailT.quantity - smartsaleDetailT.discount
}

export const costFunction = (inventoryTransactionT) => {
    return -1 * inventoryTransactionT.buy_price * inventoryTransactionT.quantity
}

export const countFunction = (element) => {
    return element.quantity
}

export const sumElements = (elements, sumFunction) => {
    return elements.reduce((total, element) => {
        return total + sumFunction(element)
    }, 0)
}

const moveDataRange = (tempStartDate, tempEndDate) => {
    tempStartDate.setDate(tempStartDate.getDate() + 1)
    tempEndDate.setDate(tempEndDate.getDate() + 1)
    return [tempStartDate, tempEndDate]
}

const sumPerDay = (elements, startDate, endDate, sumFunction, date = "date") => {
    let tempStartDate = new Date(startDate)
    let tempEndDate = new Date(endDate)
    tempEndDate.setDate(1)

    const dailySum = Array(new Date(endDate).getDate())
        .fill(1)
        .map((x, y) => x + y)
        .map(() => {
            const dailyElements = elements.filter((element) => {
                //console.log(new Date(element[date]).getTime() >= tempStartDate.getTime(), new Date(element['date']).getTime() <= tempEndDate.getTime())
                return new Date(element[date]).getTime() >= tempStartDate.getTime() && new Date(element[date]).getTime() <= tempEndDate.getTime()
            })
            const dailySum = dailyElements.reduce((tot, element) => {
                return tot + sumFunction(element)
            }, 0)
            moveDataRange(tempStartDate, tempEndDate)
            return dailySum
        })

    return dailySum
}

export const countPerItemType = (elements, itemTypeID) => {
    //Counts the number of services or product per type
    const countItems = elements
        .reduce((tot, element) => {
            const keyExists = tot.some((totT) => totT[itemTypeID] === element[itemTypeID])
            if (keyExists) {
                const newTot = tot.map((totT) => {
                    if (totT[itemTypeID] === element[itemTypeID]) {
                        totT.count = totT.count + element.quantity
                        return totT
                    } else {
                        return totT
                    }
                })
                return [...newTot]
            } else {
                return [
                    ...tot,
                    {
                        [itemTypeID]: element[itemTypeID],
                        name: element.name,
                        count: element.quantity,
                    },
                ]
            }
        }, [])
        .sort((a, b) => {
            return a.count - b.count
        })
        .reverse()
    return countItems
}

export const computeStatsShop = async (startDate, endDate, selectedSalon) => {
    // const smartsalesDetailsAll = await getData(urls.smartsaleDetailsShop + "all/" + startDate + "/" + endDate + "/" + selectedSalon + "/")
    const smartsalesDetailsServices = await getData(urls.smartsaleDetailsShop + "services/" + startDate + "/" + endDate + "/" + selectedSalon + "/")
    const smartsalesDetailsProducts = await getData(urls.smartsaleDetailsShop + "products/" + startDate + "/" + endDate + "/" + selectedSalon + "/")
    const smartsalesDetailsGiftCards = await getData(urls.smartsaleDetailsShop + "gift-cards/" + startDate + "/" + endDate + "/" + selectedSalon + "/")
    const inventoryTransactions = await getData(urls.inventoryTransactionList + startDate + "/" + endDate + "/" + selectedSalon + "/")
    const smartsalesAll = await getData(urls.smartsale + startDate + "/" + endDate + "/" + selectedSalon + "/")

    // let totalIncome = sumElements(smartsalesDetailsAll, incomeFunction)
    // console.log(totalIncome)

    const servicesReceipt = smartsalesDetailsServices.filter((x) => x.receipt)
    const servicesNoReceipt = smartsalesDetailsServices.filter((x) => !x.receipt)
    const servicesIncomeReceipt = sumElements(servicesReceipt, incomeFunction)
    const servicesIncomeNoReceipt = sumElements(servicesNoReceipt, incomeFunction)
    const servicesIncome = servicesIncomeReceipt + servicesIncomeNoReceipt

    const giftCardsReceipt = smartsalesDetailsGiftCards.filter((x) => x.receipt)
    const giftCardsNoReceipt = smartsalesDetailsGiftCards.filter((x) => !x.receipt)
    const giftCardsIncomeReceipt = sumElements(giftCardsReceipt, incomeFunction)
    const giftCardsIncomeNoReceipt = sumElements(giftCardsNoReceipt, incomeFunction)
    const giftCardsIncome = giftCardsIncomeReceipt + giftCardsIncomeNoReceipt

    const productsReceipt = smartsalesDetailsProducts.filter((x) => x.receipt)
    const productsNoReceipt = smartsalesDetailsProducts.filter((x) => !x.receipt)
    const productsIncomeReceipt = sumElements(productsReceipt, incomeFunction)
    const productsIncomeNoReceipt = sumElements(productsNoReceipt, incomeFunction)
    const productsIncome = productsIncomeReceipt + productsIncomeNoReceipt

    const productsCosts = sumElements(inventoryTransactions, costFunction)
    const productsSellableCosts = sumElements(
        inventoryTransactions.filter((x) => x.sellable),
        costFunction
    )

    const productsNonSellableCosts = sumElements(
        inventoryTransactions.filter((x) => !x.sellable),
        costFunction
    )
    const dailyCost = sumPerDay(inventoryTransactions, startDate, endDate, costFunction)

    const dailyIncomeNoReceipt = sumPerDay(
        smartsalesAll.filter((x) => !x.receipt),
        startDate,
        endDate,
        (x) => x.total_amount
    ) //The calculations are done for the entire month, but only the day itself is needed, consider to remove
    const dailyIncomeReceiptCash = sumPerDay(
        smartsalesAll.filter((x) => x.receipt),
        startDate,
        endDate,
        (x) => {
            return x.payment_cash
        }
    ) //The calculations are done for the entire month, but only the day itself is needed, consider to remove
    const dailyIncomeReceiptElectronic = sumPerDay(
        smartsalesAll.filter((x) => x.receipt),
        startDate,
        endDate,
        (x) => {
            return x.payment_electronic
        }
    ) //The calculations are done for the entire month, but only the day itself is needed, consider to remove
    const dailyIncomeReceipt = dailyIncomeReceiptCash.map((dailyIncomeReceiptCashT, index) => dailyIncomeReceiptCashT + dailyIncomeReceiptElectronic[index])

    const dailyIncome = dailyIncomeReceipt.map((dailyIncomeReceiptT, index) => dailyIncomeReceiptT + dailyIncomeNoReceipt[index])

    const totalIncome = dailyIncome.reduce((tot, x) => tot + x, 0)
    const ficheShop = totalIncome / (smartsalesAll.length === 0 ? 1 : smartsalesAll.length)

    let productsAllCostsPerc = (productsCosts / totalIncome) * 100
    if (isNaN(productsAllCostsPerc)) productsAllCostsPerc = 0

    const stats = {
        totalIncome: totalIncome,
        servicesIncome: servicesIncome,
        servicesIncomeReceipt: servicesIncomeReceipt,
        servicesIncomeNoReceipt: servicesIncomeNoReceipt,
        giftCardsIncome: giftCardsIncome,
        giftCardsIncomeReceipt: giftCardsIncomeReceipt,
        giftCardsIncomeNoReceipt: giftCardsIncomeNoReceipt,
        productsIncome: productsIncome,
        productsIncomeReceipt: productsIncomeReceipt,
        productsIncomeNoReceipt: productsIncomeNoReceipt,
        productsCosts: productsCosts,
        productsSellableCosts: productsSellableCosts,
        productsNonSellableCosts: productsNonSellableCosts,
        productsAllCostsPerc: productsAllCostsPerc,
        dailyIncome: dailyIncome,
        dailyCost: dailyCost,
        dailyIncomeNoReceipt: dailyIncomeNoReceipt,
        dailyIncomeReceiptCash: dailyIncomeReceiptCash,
        dailyIncomeReceiptElectronic: dailyIncomeReceiptElectronic,
        dailyIncomeReceipt: dailyIncomeReceipt,
        ficheShop: ficheShop,
    }
    return stats
}

//TODO add gift cards in the fron among the statistics and on the operator

export const computeDailyStatsShop = async (startDate, endDate, selectedSalon) => {
    const smartsalesAll = await getData(urls.smartsale + startDate + "/" + endDate + "/" + selectedSalon + "/")

    const dailyIncomeNoReceipt = smartsalesAll
        .filter((x) => !x.receipt)
        .reduce((tot, x) => {
            return tot + x.total_amount
        }, 0)

    const dailyIncomeReceiptCash = smartsalesAll
        .filter((x) => x.receipt)
        .reduce((tot, x) => {
            return tot + x.payment_cash
        }, 0)
    const dailyIncomeReceiptElectronic = smartsalesAll
        .filter((x) => x.receipt)
        .reduce((tot, x) => {
            return tot + x.payment_electronic
        }, 0)
    const dailyIncomeReceipt = dailyIncomeReceiptCash + dailyIncomeReceiptElectronic
    const dailyTotalIncome = dailyIncomeNoReceipt + dailyIncomeReceipt

    const stats = {
        dailyIncomeNoReceipt: dailyIncomeNoReceipt,
        dailyIncomeReceiptCash: dailyIncomeReceiptCash,
        dailyIncomeReceiptElectronic: dailyIncomeReceiptElectronic,
        dailyIncomeReceipt: dailyIncomeReceipt,
        dailyTotalIncome: dailyTotalIncome,
    }

    return stats
}

export const computeStatsOperators = async (startDate, endDate, operator, selectedSalon) => {
    const operators = operator === undefined ? await getData(urls.operators + startDate + "/" + endDate + "/" + selectedSalon + "/") : [operator]

    const stats = operators.map(async (operator) => {
        // const response = await getData(urls.statsRevenueBonus + operator.id + "/" + startDate + "/" + endDate + "/" + selectedSalon + "/")
        // console.log(response)
        const smartsale_details_operator = await getData(urls.smartsaleDetailsOperators + operator.id + "/" + startDate + "/" + endDate + "/" + selectedSalon + "/")
        const gift_cards_redeemed_operator = await getData(urls.retrieveRedeemedGiftCardsOperator + operator.id + "/" + startDate + "/" + endDate + "/" + selectedSalon + "/")
        const stats_operator = await getData(urls.retrieveBonusOperator + operator.id + "/" + startDate + "/" + endDate + "/" + selectedSalon + "/")

        const servicesFilter = smartsale_details_operator.filter((x) => {
            return x.service_id !== null
        })
        const productsFilter = smartsale_details_operator.filter((x) => {
            return x.product_id !== null
        })

        const countPerServiceType = countPerItemType(servicesFilter, "service_id")
        const countPerProductType = countPerItemType(productsFilter, "product_id")

        const servicesIncome = sumElements(servicesFilter, incomeFunction)
        const productsIncome = sumElements(productsFilter, incomeFunction)

        //This row will cause discrepancies in the amount of redeemed gift card on the operator and on the amount of the income of the salon, this will happen if the card is sold a discounted price
        const giftCardsIncome = sumElements(gift_cards_redeemed_operator, (e) => {
            return e.amount
        })

        const numberServicesSold = sumElements(servicesFilter, countFunction)
        const numberProductsSold = sumElements(productsFilter, countFunction)

        const totalIncome = servicesIncome + productsIncome + giftCardsIncome

        const dailyIncomeServices = sumPerDay(servicesFilter, startDate, endDate, incomeFunction)
        const dailyIncomeProducts = sumPerDay(productsFilter, startDate, endDate, incomeFunction)

        //This row will cause discrepancies in the amount of redeemed gift card on the operator and on the amount of the income of the salon, this will happen if the card is sold a discounted price
        const dailyIncomeRedeemedGiftCards = sumPerDay(
            gift_cards_redeemed_operator,
            startDate,
            endDate,
            (e) => {
                return e.amount
            },
            "redeem_date"
        )

        let dailyIncome = []

        for (let i = 0; i < dailyIncomeProducts.length; i++) {
            dailyIncome.push(dailyIncomeServices[i] + dailyIncomeProducts[i] + dailyIncomeRedeemedGiftCards[i])
        }

        // console.log(dailyIncome)
        const statsOperator = {
            id: operator.id,
            name: operator.first_name,
            jobTitle: operator.job_title,
            totalIncome: totalIncome,
            servicesIncome: servicesIncome,
            productsIncome: productsIncome,
            giftCardsIncome: giftCardsIncome,
            numberServicesSold: numberServicesSold,
            numberProductsSold: numberProductsSold,
            countPerServiceType: countPerServiceType,
            countPerProductType: countPerProductType,
            dailyIncome: dailyIncome,
            monthlyBonus: stats_operator.bonuses,
            revenueRate: stats_operator.revenue_rate,
        }

        return statsOperator
    })

    return await Promise.all(stats)
}

const sumFunctionInventory = (products, sellable, attribute) => {
    return products.reduce((total, element) => {
        if (element.active && element.sellable === sellable) return total + element.quantity * element[attribute]
        else return total
    }, 0)
}

export const computeStatsInventory = async (selectedSalon) => {
    const products = await getData(urls.products + selectedSalon + "/")
    const giftCardsIssued = await getData(urls.retrieveAllIssuedGiftCards + selectedSalon + "/")

    const sellableProductsValue = sumFunctionInventory(products, true, "sell_price")
    const nonSellableProductsValue = sumFunctionInventory(products, false, "buy_price")
    const allProductsValue = nonSellableProductsValue + sumFunctionInventory(products, true, "buy_price")
    const giftCardIssuedNumber = giftCardsIssued.length

    return {
        sellableProductsValue: sellableProductsValue,
        nonSellableProductsValue: nonSellableProductsValue,
        allProductsValue: allProductsValue,
        giftCardIssuedNumber: giftCardIssuedNumber,
    }
}

export const computeStatsCustomer = (statsCustomer) => {
    const smartsale_details_customer = statsCustomer
        .map((smartsale) => {
            return [...smartsale.smartsale_details]
        })
        .flat()
    const servicesFilter = smartsale_details_customer
        .filter((x) => {
            return x.service_id !== null
        })
        .map((x) => {
            return { ...x, service_id: x.service_id.id }
        })
    const productsFilter = smartsale_details_customer
        .filter((x) => {
            return x.product_id !== null
        })
        .map((x) => {
            return { ...x, product_id: x.product_id.id }
        })
    const giftCardsFilter = smartsale_details_customer
        .filter((x) => {
            return x.gift_card_id !== null
        })
        .map((x) => {
            return { ...x, gift_card_id: x.gift_card_id.id }
        })

    const servicesIncome = sumElements(servicesFilter, incomeFunction)
    const productsIncome = sumElements(productsFilter, incomeFunction)
    const giftCardsIncome = sumElements(giftCardsFilter, incomeFunction)

    const numberServicesSold = sumElements(servicesFilter, countFunction)
    const numberProductsSold = sumElements(productsFilter, countFunction)

    const totalIncome = servicesIncome + productsIncome + giftCardsIncome

    const ficheCustomer = totalIncome / (statsCustomer.length === 0 ? 1 : statsCustomer.length)

    const stats = {
        servicesIncome: servicesIncome,
        productsIncome: productsIncome,
        giftCardsIncome: giftCardsIncome,
        totalIncome: totalIncome,
        ficheCustomer: ficheCustomer,
        numberServicesSold: numberServicesSold,
        numberProductsSold: numberProductsSold,
        // countPerServiceType: countPerServiceType,
        // countPerProductType: countPerProductType,
    }
    return stats
}

export const defaultadvancedStatsCustomer = {
    servicesIncome: 0,
    productsIncome: 0,
    giftCardsIncome: 0,
    totalIncome: 0,
    ficheCustomer: 0,
    numberServicesSold: 0,
    numberProductsSold: 0,
    countPerServiceType: [],
    countPerProductType: [],
}

export const formatStatsListShop = (statsShop, statsOperators, statsInventory) => {
    // {console.log(statsShop)}

    return {
        timePanel: [
            {
                topTitles: [
                    { title: "Totale", value: statsShop.totalIncome },
                    { title: "Servizi", value: statsShop.servicesIncome },
                    { title: "Prodotti", value: statsShop.productsIncome },
                    { title: "Carte Regalo", value: statsShop.giftCardsIncome },
                    { title: "Fiche", value: statsShop.ficheShop },
                ],
                chart: statsShop.dailyIncome,
                detailedDailySummary: [
                    { title: "Entrate", value: statsShop.dailyIncomeReceipt },
                    { title: "Entrate Cash", value: statsShop.dailyIncomeReceiptCash },
                    { title: "Entrate POS", value: statsShop.dailyIncomeReceiptElectronic },
                    { title: "Altre Entrate", value: statsShop.dailyIncomeNoReceipt },
                ],
            },
            {
                topTitles: [
                    { title: "Totale", value: statsShop.productsCosts },
                    { title: "Prod. vendibili", value: statsShop.productsSellableCosts },
                    { title: "Prod. non vendibili", value: statsShop.productsNonSellableCosts },
                    { title: "Costi/Fatt.", value: statsShop.productsAllCostsPerc, symbol: "%" },
                ],
                chart: statsShop.dailyCost,
            },
        ],
        operators: statsOperators,
        inventory: statsInventory,
    }
}

export const formatStatsListOperator = (statsOperator) => {
    if (statsOperator) {
        return {
            timePanel: [
                {
                    topTitles: [
                        { title: "Totale", value: statsOperator.totalIncome },
                        { title: "Servizi", value: statsOperator.servicesIncome },
                        { title: "Prodotti", value: statsOperator.productsIncome },
                        { title: "Carte Regalo", value: statsOperator.giftCardsIncome },
                        // { title: "Rate", value: statsOperator.revenueRate },
                        { title: "Bonus", value: statsOperator.monthlyBonus },
                    ],
                    chart: statsOperator.dailyIncome,
                },
            ],
            operators: [statsOperator],
        }
    }
}

//////////////////////////////////////// Chart

export const options = {
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            titleFont: {
                size: 16,
                family: "Poppins",
            },
            bodyFont: {
                size: 16,
                family: "Poppins",
            },
        },
    },
    scales: {
        x: {
            grid: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                // Include a dollar sign in the ticks
                autoSkip: false,
                callback: function (value) {
                    return value + 1
                },
                maxRotation: 0,
                font: {
                    family: "Poppins",
                },
                color: "#b7b7b7",
            },
        },

        y: {
            display: true,
            ticks: {
                font: {
                    family: "Poppins",
                },
                color: "#b7b7b7",
            },
            grid: {
                display: false,
                color: "rgba(217,143,7,0.1)",
            },
        },
    },
}

export function fillListWithZeros(appointments, date) {
    // console.log(startDateString)
    // Convert appointments to object with dates as keys
    const startDate = new Date(beginningOfMonth(date))
    const endDate = new Date(endOfMonth(date))

    const appointmentMap = {}
    appointments.forEach((appointment) => {
        appointmentMap[appointment.appointment_date] = appointment.count
    })

    // Create an array to store the result
    const extendedAppointments = []
    // console.log(startDate)
    // Loop through each day between start and end dates
    for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
        const formattedDate = DateTime.fromISO(date.toISOString()).setZone("Europe/Rome").toISODate()
        const count = appointmentMap[formattedDate] || 0
        extendedAppointments.push(count)
    }

    return extendedAppointments
}

export const getDataChart = (labels, data, color) => {
    return {
        labels: labels,
        datasets: [
            {
                label: "  Totale",
                data: data,
                backgroundColor: color,
                borderRadius: "5",
                borderSkipped: false,
                // barThickness: 12,
            },
        ],
    }
}

export const getDataChartBookings = (labels, internalData, webpageData) => {
    return {
        labels: labels,
        datasets: [
            {
                label: "Interni",
                data: internalData,
                backgroundColor: "#87cefa",
                borderRadius: "5",
                borderSkipped: false,
                // barThickness: 12,
            },
            {
                label: "Webpage",
                data: webpageData,
                backgroundColor: "#86de7b",
                borderRadius: "5",
                borderSkipped: false,
                // barThickness: 12,
            },
        ],
    }
}

/////////////////////////////////////////// Calendar helpers
export function beginningOfMonth(myDate) {
    let date = new Date(myDate)
    date.setDate(1)
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    return date
}

export function beginningOfMonthVariable(myDate, counter) {
    let date = new Date(myDate)

    date.setDate(1)
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    date.setMonth(date.getMonth() + counter)
    return date
}

export function endOfMonth(myDate) {
    let date = new Date(myDate)
    date.setDate(1) // Avoids edge cases on the 31st day of some months
    date.setMonth(date.getMonth() + 1)
    date.setDate(0)
    date.setHours(23)
    date.setMinutes(59)
    date.setSeconds(59)
    date.setMilliseconds(999)
    return date
}

export function beginningOfDay(myDate) {
    let date = new Date(myDate)
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    return date
}

export function endOfDay(myDate) {
    let date = new Date(myDate)
    date.setHours(23)
    date.setMinutes(59)
    date.setSeconds(59)
    date.setMilliseconds(999)
    return date
}

export const monthsName = ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dec"]

function capitalizeEachWord(str) {
    // Split the string into an array of words
    const words = str.split(" ")

    // Capitalize the first letter of each word
    const capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1)
    })

    // Join the words back together into a single string
    return capitalizedWords.join(" ")
}

export function computeDaysList(date) {
    // {console.log(dateNew)}
    // Compute the start of the month

    const luxonDatetime = DateTime.fromJSDate(date)

    const startDate = luxonDatetime.startOf("month")

    // Compute the end of the month
    const endDate = luxonDatetime.endOf("month")

    // Iterate over each day between the start and end dates
    const formattedDates = []

    for (let date = startDate; date <= endDate; date = date.plus({ days: 1 })) {
        // Format the date as "Day Number Month" (e.g., "Monday 1 March")
        const formattedDate = capitalizeEachWord(date.toLocaleString({ weekday: "short", day: "numeric", month: "short" }))
        formattedDates.push(formattedDate)
    }

    return formattedDates
}

export function computeDaysListUpToday(date) {
    // {console.log(dateNew)}
    return Array(date.getDate())
        .fill(1)
        .map((x, y) => x + y)
        .map((x) => monthsName[date.getMonth()] + " " + x)
}

export function formatListSingleDay(date) {
    return [monthsName[date.getMonth()] + " "]
}

export function formatStartEndDate(date) {
    const startDate = new Date(beginningOfMonth(date)).toISOString()
    const endDate = new Date(endOfMonth(date)).toISOString()

    return [startDate, endDate]
}

export function formatStartEndDateDaily(date) {
    const startDate = new Date(beginningOfDay(date)).toISOString()
    const endDate = new Date(endOfDay(date)).toISOString()

    return [startDate, endDate]
}

export function formatStartEndDateVariableMonth(date, month) {
    const startDate = new Date(beginningOfMonthVariable(date, month)).toISOString()
    const endDate = new Date().toISOString()

    return [startDate, endDate]
}
/////////////////////////// Avatar colors

export function stringToHslColor(str, s, l) {
    var hash = 0
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }

    var h = hash % 360
    return "hsl(" + h + ", " + s + "%, " + l + "%)"
}

export function adjustLuminosity(hexCode, delta) {
    // Parse the hex code using TinyColor
    const color = tinycolor(hexCode)

    // Adjust the saturation
    const newColor = color.lighten(delta)

    // Return the new hex code
    return newColor.toHexString()
}

///////////////////////////////////////////////////// Switchstyle
export const MaterialUISwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.mode === "dark" ? "dimgray" : "lightskyblue",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "light" ? "dimgray" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}))

export const MaterialUISwitchInventory = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.mode === "dark" ? "purple" : "dimgray", //Second argument left color
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "light" ? "lightskyblue" : "#39393D", //First argument right color
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}))

export const TextButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: "13px",
    letterSpacing: "0.17px",
    backgroundColor: "#FFFFFF",
    paddingLeft: "10px",
    paddingRight: "10px",
    color: "grey",
    fontFamily: ['"Poppins"', "sans-serif"].join(","),
    "&:hover": {
        backgroundColor: "#fafafa",
        boxShadow: "none",
        borderRadius: "15px",
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: "#0062cc",
        borderColor: "#005cbf",
    },
    "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
})

export const darkTheme = { button: "dark", primaryColor: "#000000", secondaryColor: "#a8a8a8", thirdColor: "gainsboro", tabcolor: "#000000", dashboardMode: "#525252" }
export const lightTheme = { button: "primary", primaryColor: "#0d6efd", secondaryColor: "#87cefa", thirdColor: "aliceblue", tabcolor: "#3f86d6", dashboardMode: "#3184ff" }

export const anonymousAppointment = (anonymousCustomer) => {
    return {
        customer: anonymousCustomer[0],
        appointments: [],
    }
}

//////////////////////////////////////   Permissions    //////////////////////////////////////////

export const ROLES = {
    //Maps django groups to react roles
    owner: "owner",
    manager: "manager",
    salon: "salon",
    hairdresser: "hairdresser",
}

export const hasPermission = (user, permissions) => {
    //The second aregument are the permissions Required
    // return user.permissions.some(permission => permissions.some(permissionT => permission === permissionT)) old version when Groups where used
    return permissions.some((permissionT) => user.permissions === permissionT)
}

export const formatPhone = (phone) => {
    const myPhone = phone.match(/(\d)/g)?.join(``)
    if (myPhone === undefined) return ``
    if (myPhone?.length === 11) {
        return myPhone.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, `+$1 $2 $3 $4`)
    }
    if (myPhone?.length === 12) {
        return myPhone.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, `+$1 $2 $3 $4`)
    }
    if (myPhone?.length === 13) {
        return myPhone.replace(/(\d{3})(\d{3})(\d{3})(\d{4})/, `+$1 $2 $3 $4`)
    }

    return myPhone
}

export const defaultAlertBox = { mode: "", message: "", enabled: false }

export const fidelityScoreMapping = {
    3: { filter: "#ff4e11", marker: "#ff4e11" },
    2: { filter: "#f78914", marker: "#f78914" },
    1: { filter: "#b6ee56", marker: "#b6ee56" },
    // 1: { filter: '#92cd28', marker: '#92cd28' },
    0: { filter: "darkgrey", marker: "whitesmoke" },
}

export const randstr = (prefix) => {
    return Math.random()
        .toString(36)
        .replace("0.", prefix || "")
}

export const totalHolidays = 24

export function range(lowEnd, highEnd) {
    var list = []
    for (var i = lowEnd; i <= highEnd; i++) {
        list.push(i)
    }
    return list
}

export const formatStringDate = (dateString) => {
    const splitDate = dateString.split("-")
    const date = DateTime.local(Number(splitDate[2]), Number(splitDate[1]), Number(splitDate[0])).toISO()
    return date
}

export const formatSpecialDays = (specialDaysListEdit) => {
    const formattedSpecialDays = [...specialDaysListEdit]
    return formattedSpecialDays.map((specialDays) => {
        return {
            id: specialDays.id,
            deleted: specialDays.deleted,
            operator: specialDays.operator,
            start_date: formatStringDate(specialDays.startDate),
            end_date: formatStringDate(specialDays.endDate),
            type: specialDays.type,
            exclude_days: specialDays.exclude_days,
            status: specialDays.status,
        }
    })
}

export const validateDates = (setAlertBox, specialDaysListEdit) => {
    for (let idx in specialDaysListEdit) {
        let specialDay = specialDaysListEdit[idx]
        const startDate = moment(specialDay.startDate, "DD-MM-YYYY")
        const endDate = moment(specialDay.endDate, "DD-MM-YYYY")

        if (!(startDate.isValid() && endDate.isValid())) {
            setAlertBox({ mode: "danger", message: "Data non valida controllare i valori ed il formato", enabled: true })
            return false
        }

        if (startDate.isAfter(endDate)) {
            setAlertBox({ mode: "danger", message: "La data di inizio deve essere precedente alla data di fine", enabled: true })
            return false
        }

        if (endDate.diff(startDate, "days") < specialDay.exclude_days) {
            setAlertBox({ mode: "danger", message: "Il numero di giorni esclusi non può superare il numero di giorni tra le date", enabled: true })
            return false
        }
    }

    return true
}

export const saveSpecialDays = async (specialDaysListEdit, setAlertBox, retrieveSpecialDaysOperator) => {
    if (!validateDates(setAlertBox, specialDaysListEdit)) {
        return
    }

    const formattedSpecialDays = formatSpecialDays(specialDaysListEdit)

    // console.log(formattedSpecialDays)
    const response = await sendData(urls.acceptSpecialDaysOperators, formattedSpecialDays)

    if (response.type === "success") {
        setAlertBox({ mode: "success", message: response.msg, enabled: true })
        await retrieveSpecialDaysOperator()
        // const statsSpecialDays = computeHolidays(newSpecialDays)
        // setStatsSpecialDays(statsSpecialDays)
    } else {
        setAlertBox({ mode: "danger", message: response.msg, enabled: true })
    }
}

export const removeSpecialPeriod = (specialPeriod, updateSpecialDaysList, setSpecialDaysListEdit) => {
    if (specialPeriod.id.includes("sd_")) {
        //Removing a non saved date
        setSpecialDaysListEdit((x) => x.filter((xx) => xx.id !== specialPeriod.id))
    } else {
        //Removing a saved period
        updateSpecialDaysList("deleted", true, specialPeriod)
    }
}

export function formatTimeToMinHourString(minutes) {
    if (minutes < 60) {
        return `${minutes} min`
    } else {
        const hours = Math.floor(minutes / 60)
        const remainingMinutes = minutes % 60
        if (remainingMinutes === 0) {
            return `${hours} h`
        } else {
            return `${hours} h ${remainingMinutes} min`
        }
    }
}

export function generateTimeIntervalsInMinSlots(maxHours, SLOT_SIZE) {
    const intervals = []
    const maxMinutes = maxHours * 60
    for (let minutes = SLOT_SIZE; minutes <= maxMinutes; minutes += SLOT_SIZE) {
        intervals.push({ min: minutes, formatMin: formatTimeToMinHourString(minutes) })
    }
    return intervals
}

///////BOOKING ADAPTATION

// const BASE_URL = "http://127.0.0.1:8001/api/"

// export async function getDataBooking(url) {
//     const response = await fetch(BASE_URL + url)
//     return await response.json()
// }
export function customerFormatter(customer) {
    const customerPhone = parsePhoneNumber(customer.phone)
    const customerFormatted = {
        ...customer,
        phone: customerPhone.nationalNumber,
        countryCode: { iso2: customerPhone.country.toLowerCase(), dialCode: customerPhone.countryCallingCode },
    }

    return customerFormatted
}
