import React, { useCallback, useEffect, useState } from "react"
import { Alert, Button, Col, FormControl, InputGroup, Modal, Row, Spinner, Stack } from "react-bootstrap"
import { BiSpreadsheet } from "react-icons/bi"
import { IoMdWarning } from "react-icons/io"
import { MdNoAccounts, MdOutlineEmail } from "react-icons/md"
import { useDashboard } from "../../../../contexts/DashboardProvider"
import { useDatabase } from "../../../../contexts/DatabaseProvider"
import { anonymousAppointment, percentageToAbsoluteDiscount, putDataBooking, roundToTwo, sendData, urls, urlsBooking } from "../../../../helper"
import GiftCard from "./GiftCard"
import NewGiftCard from "./NewGiftCard"
import NewProduct from "./NewProduct"
import NewService from "./NewService"
import Product from "./Product"
import Service from "./Service"
import Total from "./Total"
import { useSalon } from "../../../../contexts/SalonProvider"

export default function EasyRegister() {
    const defaultTotal = [
        { name: "Bruto", amount: 0 },
        { name: "Net", amount: 0 },
    ]
    const defaultPaymentOptions = [
        { name: "CONTANTI", amount: 0, selected: false },
        { name: "CARTE", amount: 0, selected: false },
    ]

    const defaultGiftCardRedeem = { code: "", sell_price: 0, residual_amount: 0, status: "toVerify", selected: false, operator: "---" }
    const defaultAlertBox = { mode: "", message: "", enabled: false }

    const { operators, updateBillings, updateAppointments, updateInventory, updateStats, setDraftAppointments, anonymousCustomer, billings, appointments } = useDatabase()
    const { receipt, colorTheme, selectedAppointment, setSelectedAppointment } = useDashboard()
    const { selectedSalon } = useSalon()
    const [servicesSelected, setServicesSelected] = useState([])
    const [productsSelected, setProductsSelected] = useState([])
    const [giftCardsSelected, setGiftCardsSelected] = useState([])
    const [total, setTotal] = useState(defaultTotal)
    const [paymentOptions, setPaymentOptions] = useState(defaultPaymentOptions)
    const [isBilled, setIsBilled] = useState(false)
    const [giftCardRedeem, setGiftCardRedeem] = useState(defaultGiftCardRedeem) //Gift card to be redeemed
    const [globalDiscount, setGlobalDiscount] = useState("")
    const [alertBox, setAlertBox] = useState(defaultAlertBox)
    const [modalShow, setModalShow] = useState(false)
    const [modalWarningShow, setModalWarningShow] = useState(false)

    const [isProcessingTransaction, setProcessingTransaction] = useState(false)
    const [temporaryEmail, setTemporaryEmail] = useState("")

    useEffect(() => {
        if (selectedAppointment.length !== 0) {
            setProductsSelected(
                selectedAppointment.appointments.filter((appT) => {
                    return appT.type === "product"
                })
            )
            setServicesSelected(
                selectedAppointment.appointments.filter((appT) => {
                    return appT.type === "service"
                })
            )
            setGiftCardsSelected(
                selectedAppointment.appointments.filter((appT) => {
                    return appT.type === "giftCard"
                })
            )
        }
        setGlobalDiscount("")
        setGiftCardRedeem(defaultGiftCardRedeem)
        const isBilled = billings.some((billing) => billing.smartsale.customer === selectedAppointment.customer.id && selectedAppointment.manual !== true)
        setIsBilled(isBilled)
        setTemporaryEmail("")
    }, [selectedAppointment])

    useEffect(() => {
        saveDraftAppointment()
    }, [servicesSelected, productsSelected, giftCardsSelected])

    const scrollToView = useCallback((node) => {
        if (node) {
            node.scrollIntoView({ behavior: "smooth" })
        }
    }, [])

    const handleTransaction = async () => {
        setProcessingTransaction(true)
        const purchases = [
            ...servicesSelected.map((x) => {
                return { ...x, service_id: x.id, product_id: "", gift_card_id: "" }
            }),
            ...productsSelected.map((x) => {
                return { ...x, product_id: x.id, gift_card_id: "", service_id: "" }
            }),
            ...giftCardsSelected.map((x) => {
                return { ...x, gift_card_id: x.id, product_id: "", service_id: "" }
            }),
        ].map((purchase) => {
            return { ...purchase, receipt: receipt }
        })

        const { smartsale_details_formatted, gift_card_left_over } = percentageToAbsoluteDiscount(purchases, globalDiscount, giftCardRedeem.residual_amount)

        // console.log(smartsale_details_formatted, gift_card_left_over )
        // extra.email is useful to add email to users or anon users not having it
        const smartSaleFull = {
            smartsale: {
                date: new Date().toISOString(),
                customer: selectedAppointment.customer.id,
                partial_amount: total[0].amount,
                total_amount: total[1].amount,
                payment_cash: paymentOptions[0].amount,
                payment_electronic: paymentOptions[1].amount,
                total_discount: roundToTwo(total[0].amount - total[1].amount),
                receipt: receipt,
                extra_email: temporaryEmail,
                salon: selectedSalon.id,
            },
            smartsale_details: smartsale_details_formatted,
            extra: {
                gift_card: { ...giftCardRedeem, gift_card_left_over: roundToTwo(gift_card_left_over) },
            },
        }
        try {
            await sendData(urls.smartsale + selectedSalon.id + "/", smartSaleFull)
            if (!(temporaryEmail === "" || selectedAppointment.customer.phone === "+390000000000"))
                await putDataBooking(urlsBooking.updateCustomer + selectedAppointment.customer.id + "/", { ...selectedAppointment.customer, email: temporaryEmail })
            setModalShow(false)
            setSelectedAppointment(anonymousAppointment(anonymousCustomer))
            updateBillings()
            updateInventory()
            updateAppointments()
            updateStats(new Date())
            setTemporaryEmail("")
        } catch (error) {
            setModalShow(false)
            setAlertBox({ mode: "danger", message: error.message, enabled: true })
        }
        setProcessingTransaction(false)
    }

    // console.log(selectedAppointment.customer)

    const checkValiditySmartSale = () => {
        setAlertBox(defaultAlertBox)
        const purchases = [...servicesSelected, ...productsSelected, ...giftCardsSelected]
        let noOperator = undefined
        noOperator = purchases.some((purchase) => purchase["operator"] === "---")
        const noOperatorGiftCard = giftCardRedeem.status === "valid" && giftCardRedeem.operator === "---"
        if (noOperator || noOperatorGiftCard) {
            setAlertBox({ mode: "danger", message: "Operatore non assegnato a servizio, prodotto, carta regalo o codice sconto", enabled: true })
            return
        }
        setModalShow(true)
    }

    const saveDraftAppointment = () => {
        //TODO was deleting appointments of an anonymous client
        setDraftAppointments((appT) => {
            appT.map((appTT) => {
                if (appTT.customer.id === selectedAppointment.customer.id) {
                    appTT.appointments = [...servicesSelected, ...productsSelected, ...giftCardsSelected]
                    appTT.customer.edited = true
                    // const originalApp = appointments.find(oAppT => { return oAppT.customer.id === appTT.customer.id })
                    // console.log(originalApp.appointments.length)
                    // console.log(appTT.appointments)

                    // if (originalApp.appointments.length !== appTT.appointments.length){

                    // }
                }
            })
            const updateAppointments = [...appT]
            return updateAppointments
        })
    }

    const saveModalType = () => {
        if (receipt) {
            if (selectedAppointment.customer.email === "") {
                return (
                    <div className="d-flex flex-column text-center mb-4 justify-content-center " style={{ fontSize: "17px" }}>
                        <span> Aggiungi email di destinazione per lo scontrino</span>
                        <div className="d-flex justify-content-center pt-3">
                            <InputGroup style={{ width: "65%" }}>
                                <FormControl
                                    variant="secondary"
                                    style={{ borderRadius: "20px", width: "350px", height: "50px" }}
                                    type="email"
                                    placeholder={"Email"}
                                    value={temporaryEmail}
                                    onChange={(e) => {
                                        setTemporaryEmail(e.target.value)
                                    }}
                                />
                            </InputGroup>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className=" text-center" style={{ fontSize: "17px" }}>
                        <div className="mb-2">Lo scontrino sarà inviato alla seguente email: </div>
                        <span style={{ fontSize: "17px", fontWeight: "600" }}>
                            {" "}
                            {selectedAppointment.customer.email}
                            <br></br>
                            <br></br>{" "}
                        </span>
                    </div>
                )
            }
        }
        return
    }

    if (isBilled && selectedAppointment.customer.phone !== "+390000000000") {
        return (
            <div className="d-flex justify-content-center " style={{ fontSize: "48px", paddingTop: "300px", color: "lightgray" }}>
                Scontrino emesso
            </div>
        )
    }

    return (
        <div>
            {/* {console.log(selectedAppointment)} */}
            <div className="d-flex flex-row mb-2">
                <div className="flex-grow-1 ps-2 text-secondary">Trattamenti</div>
                <div className="flex-grow-1 ps-2 text-secondary">Totale</div>
            </div>
            <Row>
                <Col className="me-1">
                    {/* {console.log(servicesSelected)} */}
                    {servicesSelected.map((serviceSelected, index) => (
                        <div key={index} className="d-flex card shadow-sm mb-3" style={{ borderRadius: "15px" }}>
                            <Service operators={operators} serviceSelected={serviceSelected} setServicesSelected={setServicesSelected} />
                        </div>
                    ))}
                    <div className="d-flex card shadow-sm mb-4" style={{ borderRadius: "15px" }}>
                        <NewService setServicesSelected={setServicesSelected} servicesSelected={servicesSelected} />
                    </div>
                    <div>
                        <div className="ps-2 mb-2 text-secondary mt-2">Prodotti</div>
                        {productsSelected.map((productSelected, index) => (
                            <div key={index} className="d-flex card shadow-sm mb-3" style={{ borderRadius: "15px" }}>
                                <Product operators={operators} productSelected={productSelected} setProductsSelected={setProductsSelected} />
                            </div>
                        ))}
                        <div className="d-flex card shadow-sm mb-4" style={{ borderRadius: "15px" }}>
                            <NewProduct setProductsSelected={setProductsSelected} productsSelected={productsSelected} />
                        </div>

                        <div className="ps-2 mb-2 text-secondary mt-2">Promozioni</div>
                        {giftCardsSelected.map((giftCardSelected, index) => (
                            <div key={index} className="d-flex card shadow-sm mb-3" style={{ borderRadius: "15px" }}>
                                <GiftCard operators={operators} giftCardSelected={giftCardSelected} setGiftCardsSelected={setGiftCardsSelected} />
                            </div>
                        ))}

                        <div className="d-flex card shadow-sm" style={{ borderRadius: "15px" }}>
                            <NewGiftCard giftCardsSelected={giftCardsSelected} setGiftCardsSelected={setGiftCardsSelected} />
                        </div>
                    </div>
                </Col>

                <Col>
                    <Stack gap={3}>
                        <div className="d-flex card shadow-sm" style={{ borderRadius: "15px" }}>
                            <Total
                                productsSelected={productsSelected}
                                giftCardsSelected={giftCardsSelected}
                                servicesSelected={servicesSelected}
                                total={total}
                                setTotal={setTotal}
                                paymentOptions={paymentOptions}
                                setPaymentOptions={setPaymentOptions}
                                defaultPaymentOptions={defaultPaymentOptions}
                                giftCardRedeem={giftCardRedeem}
                                setGiftCardRedeem={setGiftCardRedeem}
                                defaultGiftCardRedeem={defaultGiftCardRedeem}
                                globalDiscount={globalDiscount}
                                setGlobalDiscount={setGlobalDiscount}
                                operators={operators}
                            />
                        </div>
                        {/* {console.log(paymentOptions[1].selected)} */}
                        <div className="d-flex card shadow-sm" style={{ borderRadius: "25px" }}>
                            <Button
                                variant={colorTheme.button}
                                style={{ borderRadius: "25px" }}
                                size="lg"
                                disabled={
                                    !(paymentOptions[0].selected || paymentOptions[1].selected) || (productsSelected.length === 0 && servicesSelected.length === 0 && giftCardsSelected.length === 0)
                                }
                                onClick={() => {
                                    checkValiditySmartSale()
                                }}
                                className="flex items-center justify-center"
                            >
                                {selectedAppointment.customer.phone === "+390000000000" && <MdNoAccounts size={20} />}
                                <span className="ms-2">Salva Scontrino</span>
                            </Button>
                        </div>
                        <Alert ref={alertBox.enabled ? scrollToView : null} show={alertBox.enabled} className="text-center" variant={alertBox.mode}>
                            {alertBox.message}
                        </Alert>
                    </Stack>
                </Col>
            </Row>

            {/* {console.log(selectedAppointment.customer.appointment)} */}

            <Modal
                show={modalShow}
                onHide={() => {
                    setModalShow(false)
                    setProcessingTransaction(false)
                }}
                size="sg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex text-center contained-modal-title-vcenter align-items-center">
                        {selectedAppointment.customer.email === "" || !receipt ? (
                            <BiSpreadsheet className="me-2" size={28} color={colorTheme.primaryColor} />
                        ) : (
                            <MdOutlineEmail className="me-2" size={28} color={colorTheme.primaryColor} />
                        )}{" "}
                        Salva scontrino?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: "0px" }}>
                    <div className=" text-center p-3 pb-0 mb-3" style={{ fontSize: "17px" }}>
                        Sei sicuro di volere salvare questo scontrino? <br></br>
                    </div>
                    {saveModalType()}
                </Modal.Body>
                <Modal.Footer>
                    {/* {console.log('xx', !isProcessingTransaction)} */}
                    <div className="d-flex justify-content-center flex-fill">
                        <Button variant="light" className="me-3" onClick={() => setModalShow(false)}>
                            Annulla
                        </Button>
                        <Button
                            variant={colorTheme.button}
                            disabled={isProcessingTransaction}
                            onClick={() => {
                                handleTransaction()
                            }}
                        >
                            {isProcessingTransaction && <Spinner as="span" animation="border" size="sm" role="status" />} Avanti
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal
                show={modalWarningShow}
                onHide={() => {
                    setModalWarningShow(false)
                }}
                size="sg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex text-center contained-modal-title-vcenter align-items-center">
                        <IoMdWarning className="me-2" size={28} color={colorTheme.primaryColor} />
                        Invio scontrino fallito
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: "0px" }}>
                    <div className=" text-center p-3 pb-0 mb-3" style={{ fontSize: "17px" }}>
                        Lo scontrino è stato creato con successo ma non inviato a causa di un problema. <br />
                        <br />
                        Lo scontrino verrà inviato più tardi.
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="p-2"></div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
