import React, { useContext, useEffect, useState } from "react"
import LoadingScreen from "../components/dashboard/LoadingScreen"
import { ROLES, getData, hasPermission, urls } from "../helper"
import { useAuthentication } from "./AuthenticationProvider"

const SalonContext = React.createContext()

export function useSalon() {
    return useContext(SalonContext)
}

export function SalonProvider({ children }) {
    const { user } = useAuthentication()
    const [loading, isLoading] = useState(true)
    const [salons, setSalons] = useState([])
    const [selectedSalon, setSelectedSalon] = useState()

    useEffect(() => {
        const handleData = async () => {
            if (hasPermission(user, [ROLES.owner])) {
                const salons = await getData(urls.salons)
                setSalons(salons)
            }
            setSelectedSalon(user.salon[0])
            isLoading(false)
        }

        handleData()
    }, [])

    const value = {
        salons: salons,
        selectedSalon: selectedSalon,
        setSelectedSalon: setSelectedSalon,
    }

    return (
        <SalonContext.Provider value={value}>
            <LoadingScreen text="Recupero saloni" loading={loading}>
                {children}
            </LoadingScreen>
        </SalonContext.Provider>
    )
}
