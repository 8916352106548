import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { CgFileDocument } from "react-icons/cg";
import { useDashboard } from "../../../../contexts/DashboardProvider";
import { computeDaysList } from "../../../../helper";
import { useDatabase } from "../../../../contexts/DatabaseProvider";

export default function IncomeSummary({ modalShow, setModalShow, date }) {
  const { statsList } = useDatabase();
  const { colorTheme } = useDashboard();
  const [dataSummary, setDataSummary] = useState({
    days: computeDaysList(date),
    stats: statsList.timePanel[0].detailedDailySummary,
  });
  const width = "15%";
  // console.log(dataSummary)
  const updateDataSummary = (date) => {
    setDataSummary({
      days: computeDaysList(date),
      stats: statsList.timePanel[0].detailedDailySummary,
    });
  };

  useEffect(() => {
    updateDataSummary(date);
  }, [statsList]);

  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title className="d-flex text-center contained-modal-title-vcenter align-items-center">
          <CgFileDocument
            className="me-2"
            size={28}
            color={colorTheme.primaryColor}
          />
          Report giornaliero
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "0px" }}>
        <div className="d-flex flex-row justify-content-between text-secondary ps-3 p-2 pe-3 ">
          {/* {console.log(statsTime)} */}
          <div className="ps-2" style={{ width: width }}>
            Giorni
          </div>
          {dataSummary.stats.map((statsTypes, idx) => {
            return (
              <div key={idx} style={{ width: width }}>
                {statsTypes.title}
              </div>
            );
          })}
        </div>
        {dataSummary.days.map((day, idx) => {

        // This has been added to solve crashing.
        // The issues is that when accessing insights, moving to one month back, clicking on inventory or anything else and then reacessing the insights there is a crash
        // because the dates go back to the initial month while the stats stay as the last request was done. So there can be a month of 31 days trying to print values 
        // for stats that have only 30 days, due to the mismatch. If the values is undefined just do not access it and wait for the stats to be refreshed.
          if (dataSummary.stats[0].value[idx] === undefined) return; 

          if (dataSummary.stats[0].value[idx].toFixed(2) !== "0.00" || dataSummary.stats[3].value[idx].toFixed(2) !== "0.00" ) {
            return (
              <div
                key={day}
                className="d-flex flex-row justify-content-between text-secondary ps-3 p-2 pe-3 "
              >
                <div
                  className="ps-2"
                  style={{ fontWeight: "600", width: width }}
                >
                  {day}
                </div>

                {dataSummary.stats.map((statsTypes, jdx) => {
                  let colorT;
                  let fontWeightT;
                  // console.log(jdx)
                  if (jdx === 0) {
                    colorT = colorTheme.primaryColor;
                    fontWeightT = "600";
                  } else if (jdx === 0) {
                    colorT = "grey";
                    fontWeightT = "600";
                  } else if (jdx > 0 && jdx < 3) {
                    colorT = "grey";
                    fontWeightT = "500";
                  } else if (jdx > 2) {
                    colorT = "black";
                    fontWeightT = "600";
                  }
                  // console.log(statsTypes)
                  return (
                    <div
                      key={day + jdx}
                      style={{
                        fontWeight: fontWeightT,
                        color: colorT,
                        width: width,
                      }}
                    >
                      €{statsTypes.value[idx].toFixed(2)}
                    </div>
                  );
                })}
              </div>
            );
          }
        })}
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}
