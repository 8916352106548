import React from "react"

export default function ShowEventModal({ showModal, modalPage, children, customDimensions = undefined }) {
    const handleSizeModal = (modalPage) => {
        if (customDimensions) return customDimensions[modalPage - 1]
    }

    return (
        <>
            {showModal && (
                <div className="fixed bottom-0 left-0 right-0 top-0 z-30 flex items-center justify-center bg-black/30">
                    <div className="flex  flex-col rounded-xl bg-white !px-8 !py-5 shadow-md transition-size  duration-100 ease-in-out" style={handleSizeModal(modalPage)}>
                        {children}
                    </div>
                </div>
            )}
        </>
    )
}
