import React from "react"
import { ButtonBase } from "@mui/material"
import { useDashboard } from "../../../../contexts/DashboardProvider"
import { BsDot } from "react-icons/bs"
import { adjustLuminosity, getDataTime } from "../../../../helper"
import { useDatabase } from "../easy-calendar/contexts/DatabaseProvider"
import { OperatorBadge } from "../easy-calendar/components/AppointmentModal"

export default function BillingHistory({ pastAppointments, classNameCard }) {
    const { colorTheme } = useDashboard()
    const { services, operators } = useDatabase()
    return (
        <div>
            {pastAppointments.map((statCustomer) => {
                return (
                    <div
                        key={statCustomer.id}
                        className={`flex flex-col card text-start bg-gray-50 mb-2 p-3 ps-4 ${classNameCard}`}
                        style={{ borderRadius: "15px", width: "100%", border: "5px", borderColor: "black", outline: "5px" }}
                    >
                        <div className="d-flex flex-row justify-content-between mb-1" style={{ width: "100%" }}>
                            <div className="flex py-1 !px-4 bg-black/10 text-black/90 rounded-md" style={{ fontWeight: "500" }}>
                                {getDataTime(statCustomer.date).split("-")[0]}
                            </div>
                            <div className="ms-2 pe-3 text-end self-center" style={{ color: colorTheme.primaryColor, fontWeight: "600" }}>
                                €{statCustomer.total_amount.toFixed(2)}
                            </div>
                        </div>

                        {statCustomer.smartsale_details.map((detail) => {
                            const service = services.filter((x) => detail.name === x.name)
                            const operator = operators.filter((x) => detail.operator.id === x.id)[0]

                            let serviceColor
                            if (service[0]?.color === "") serviceColor = "#eaeaea"
                            else if (service.length > 0) serviceColor = service[0].color
                            else serviceColor = "#eaeaea"

                            // console.log(operator)
                            return (
                                <div key={detail.id} className="d-flex flex-row justify-content-between mt-2 items-center" style={{ width: "100%", gap: "5px" }}>
                                    <div className="flex  ps-1 tracking-tighter text-secondary gap-2 items-center " style={{}}>
                                        <OperatorBadge operator={operator ? operator : detail.operator} size={24} extraDetails={false} />

                                        {detail.name}
                                    </div>
                                   
                                    <div className=" ms-2 pe-3 text-end " style={{ color: colorTheme.primaryColor }}>
                                        €{(detail.sell_price * detail.quantity - detail.discount).toFixed(2)}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )
            })}
        </div>
    )
}
