import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { sendData, urls, REFRESH_TOKEN, ACCESS_TOKEN, USER, getData, decodeToken, removeStorageTokens } from "../helper"

const AuthenticationContext = React.createContext()

export function useAuthentication() {
    return useContext(AuthenticationContext)
}

export function AuthenticationProvider({ children }) {
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [user, setUser] = useState(undefined)

    const navigate = useNavigate()

    const checkAuthentication = async () => {
        const user = JSON.parse(localStorage.getItem(USER))
        if (user) {
            setUser(user)
            setIsAuthenticated(true)
            navigate("/dashboard")
        }
    }

    useEffect(() => {
        const unAuthenticate = () => {
            //It is necessary to unauthenticate when the refresh token is expired
            if (user === undefined || localStorage.getItem(REFRESH_TOKEN) === undefined || localStorage.getItem(ACCESS_TOKEN) === undefined) {
                console.log("unauthenticating")

                setIsAuthenticated(false)
            }
        }

        window.addEventListener("storage", unAuthenticate)
        checkAuthentication() // It is necessary to skip the credentials process and login if you have already tokens in the storage.
        return () => {
            window.removeEventListener("storage", unAuthenticate)
        }
    }, [])

    const logIn = async (email, password) => {
        const content = {
            email: email,
            password: password,
        }
        const authResponse = await sendData(urls.pairsToken, content)

        if (authResponse.access && authResponse.refresh) {
            localStorage.setItem(ACCESS_TOKEN, authResponse.access)
            localStorage.setItem(REFRESH_TOKEN, authResponse.refresh)

            const tokenParts = decodeToken(authResponse.access)
            const user = await getData(urls.retrieveOperator + tokenParts.user_id + "/")
            localStorage.setItem(USER, JSON.stringify(user))
            window.dispatchEvent(new Event("storage"))
            setUser(user)

            setIsAuthenticated(true)
            navigate("/dashboard")
            return { type: "success", msg: "Autenticazione avvenuta con successo" }
        } else {
            return { type: "error", msg: "Autenticazione fallita, credenziali errate" }
        }
    }

    const logOut = async () => {
        const content = {
            refresh_token: localStorage.getItem(REFRESH_TOKEN),
        }
        await sendData(urls.blacklistToken, content)
        removeStorageTokens()
        setIsAuthenticated(false)
    }

    const value = {
        logIn: logIn,
        logOut: logOut,
        isAuthenticated: isAuthenticated,
        user: user,
    }

    return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>
}
