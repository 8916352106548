import { React, useState, createElement } from 'react'
import { useAuthentication } from '../../contexts/AuthenticationProvider'
import { ROLES, hasPermission } from '../../helper'

export default function PermissionGate({ children, permissions }) {
    const {user} = useAuthentication()
    // {console.log(permissions, hasPermission(user, permissions))}
    if (hasPermission(user, permissions)) {
        return <>{children}</>
    }
    else {
        return <></>
    }
}
