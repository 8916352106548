import React, { useEffect, useRef, useState, useTransition } from 'react'
import { ListGroup, FormControl, InputGroup } from "react-bootstrap"
import { useDatabase } from '../../../../contexts/DatabaseProvider';
import { useDashboard } from '../../../../contexts/DashboardProvider';
import CircularProgress from '@mui/material/CircularProgress';
import { IconButton } from '@mui/material';
import { CgFileDocument } from 'react-icons/cg';
import CustomerLoyaltySummary from '../../main-panel/easy-customers/CustomerLoyaltySummary';



export default function EasyCustomerSidebar() {

    
    const { customers } = useDatabase()
    const { colorTheme, setSelectedCustomer, selectedCustomer } = useDashboard()
    const [searchCustomer, setSearchedCustomer] = useState('')
    const [filteredCustomers, setFilteredCustomers] = useState([])
    const [showModalLoyalty, setShowModalLoyalty] = useState(false)

    const searchCustomerRef = useRef(null)
    const [isPending, startTransition] = useTransition()

    const filterCustomers = () => {
        const customersT = [...customers]
        
        const filteredCustomers = customersT.filter((customer) => {
            return (String(customer["full_name"]).toLowerCase().includes(searchCustomer.toLowerCase()) || String(customer["phone"]).includes(searchCustomer))
        })
        setFilteredCustomers(filteredCustomers)
        if (filteredCustomers.length === 1) {
            setSelectedCustomer(filteredCustomers[0])
        }
    }

    useEffect(() => {
        startTransition(() => {
            filterCustomers()
        })
    }, [searchCustomer])

    useEffect(() => {

        filterCustomers()

    }, [customers])



    return (
        <>  

            <CustomerLoyaltySummary modalShow={showModalLoyalty} setModalShow={setShowModalLoyalty}/>
            <div className='flex flex-row pt-4 ps-3 pe-3 text-center align-items-center  justify-between' style={{ fontSize: "20px", fontWeight: "500", color: colorTheme.primaryColor, paddingBottom: '9px' }}>
                <div></div>
                Clienti
                <IconButton
                    onClick={() => { setShowModalLoyalty(true) }}
                    >
                    <CgFileDocument color={colorTheme.primaryColor} />
                </IconButton>
            </div>

            <div className='d-flex flex-row align-items-center p-3 ps-4 pe-4' style={{ backgroundColor: colorTheme.thirdColor }}>

                <InputGroup >
                    <FormControl autoFocus ref={searchCustomerRef} value={searchCustomer} onChange={e => setSearchedCustomer(e.target.value)} variant='secondary' style={{ borderRadius: "20px", border: "0px" }} placeholder="Cerca cliente" />
                </InputGroup>
            </div>

            <div className='overflow-auto' >
                {isPending
                    ? <div className='d-flex justify-content-center align-items-center' style={{ height: '100%' }}>
                        <CircularProgress />
                    </div>
                    : <ListGroup className='d-flex flex-fill' variant="flush" style={{ fontSize: "18px" }} >

                        {filteredCustomers.map((filteredCustomer) => {
                            const selected = selectedCustomer.id === filteredCustomer.id
                            return (
                                <ListGroup.Item
                                    action
                                    key={filteredCustomer.id}
                                    onClick={() => { setSelectedCustomer(filteredCustomer) }}
                                    active={selected}
                                    style={{ paddingLeft: "35px", paddingRight: "35px", backgroundColor: selected && colorTheme.primaryColor }}>
                                    <div className='d-flex flex-row justify-content-between align-items-end'>
                                        <div className='flex-fill text-base'>
                                            {filteredCustomer.full_name}
                                        </div>

                                    </div>
                                </ListGroup.Item>
                            )
                        })}

                    </ListGroup>}
            </div>

        </>

    )

}