import React from "react"
import { ListGroup } from "react-bootstrap"
import { useDashboard } from "../../../../contexts/DashboardProvider"
import { useDatabase } from "../../../../contexts/DatabaseProvider"

export default function TemplatesList() {
    const { colorTheme, setSelectedTemplate, selectedTemplate } = useDashboard()
    const { templateMessages } = useDatabase()


    return (
        <div>
            <ListGroup className="d-flex flex-fill" variant="flush" style={{ fontSize: "18px" }}>
                {templateMessages.map((template) => {
                    const selected = selectedTemplate.id === template.id
                    return (
                        <ListGroup.Item
                            action
                            key={template.id}
                            onClick={() => {
                                setSelectedTemplate(template)
                            }}
                            active={selected}
                            style={{ paddingLeft: "35px", paddingRight: "35px", backgroundColor: selected && colorTheme.primaryColor }}
                        >
                            <div className="d-flex flex-row justify-content-between align-items-end">
                                <div className="flex-fill text-base">{template.title}</div>
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </div>
    )
}
