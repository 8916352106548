import React from "react"


export default function ExpandingSection({ children, isComponentVisible, refSection, extraComponent }) {
    return (
        <div className="relative  z-10">
            {children}
            <div ref={refSection}>{isComponentVisible && <div className="absolute left-2 right-2 mt-2 flex flex-col w-[275px] !p-4 rounded-md bg-white shadow-md ">{extraComponent}</div>}</div>
        </div>
    )
}
