import React from "react"
import { useState, useRef, useEffect } from "react"
import { Modal, Button, Container, InputGroup, FormControl, ListGroup } from "react-bootstrap"
import { AiOutlinePlusCircle } from "react-icons/ai"
import { useDashboard } from "../../../../contexts/DashboardProvider"
import { BsCheckCircle, BsCircle, BsCircleFill } from "react-icons/bs"
import { IconButton } from "@mui/material"
import { useDatabase } from "../../../../contexts/DatabaseProvider"

export default function NewCustomer({ setCustomersSelected }) {
    const [modalShow, setModalShow] = useState(false)
    const { customers, anonymousCustomer } = useDatabase()
    const { colorTheme } = useDashboard()
    const [searchCustomer, setSearchedCustomer] = useState("")
    const searchCustomerRef = useRef(null)

    useEffect(() => {
        if (modalShow) {
            searchCustomerRef.current.focus()
        }
    }, [modalShow])

    const addCustomer = (customer) => {
        setCustomersSelected({customer:customer, appointments: [], manual:true})
        setModalShow(false)
        setSearchedCustomer("")
    }

    return (
        <>
            <IconButton variant="white rounded-pill flex items-center justify-center" onClick={() => setModalShow(true)}>
                <AiOutlinePlusCircle color={colorTheme.primaryColor} size={24} />
            </IconButton>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="sg"
                aria-labelledby="contained-modal-title-vcenter"
                // style={{height:'80%'}}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Lista Clienti</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: "0px" }}>
                    <Container style={{ padding: "1px" }}>
                        {/* {console.log('internal', selectedcustomersModal)} */}
                        <div className="p-3 ps-4 pe-4" style={{ backgroundColor: "whitesmoke" }}>
                            <InputGroup>
                                <FormControl
                                    ref={searchCustomerRef}
                                    value={searchCustomer}
                                    onChange={(e) => setSearchedCustomer(e.target.value)}
                                    variant="secondary"
                                    style={{ borderRadius: "20px", border: "0px" }}
                                    placeholder="Cerca cliente"
                                />
                            </InputGroup>
                        </div>
                        <ListGroup>
                            <div>
                                {customers.map((customerT) => {
                                    if (String(customerT.full_name).toLowerCase().includes(searchCustomer.toLowerCase())) {
                                        return (
                                            <ListGroup.Item
                                                action
                                                key={customerT.id}
                                                variant="white"
                                                style={{ fontSize: "18px", border: "0em" }}
                                                onClick={() => {
                                                    addCustomer(customerT)
                                                }}
                                            >
                                                <div className="flex flex-row  items-center gap-2 p-1">
                                                    {/* {checkIfActive(customerT.id) ? <BsCircleFill color={colorTheme.primaryColor} /> : <BsCircle color={colorTheme.primaryColor} />} */}

                                                    <div className="d-flex flex-fill">{customerT.full_name}</div>
                                                </div>
                                            </ListGroup.Item>
                                        )
                                    }
                                })}
                            </div>
                        </ListGroup>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="flex justify-center w-full">
                    <Button className="w-full" variant={colorTheme.button} onClick={() => addCustomer(anonymousCustomer[0])}>
                        Cliente anonimo
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
