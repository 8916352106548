import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { colors, days, capitalizeString, handleDictionaryChanges, getSelectedAvailableTimeslotsOfADay, getIndexofSelectedTimeslot } from "../utils/helpers"
import { DateTime } from "luxon"

import { useDatabase } from "../contexts/DatabaseProvider"

function MonthBarModalCalendarAvailability({ calendarDate, setCalendarDate }) {
    const monthName = capitalizeString(calendarDate.toLocaleString({ month: "long", year: "numeric" }))

    function moveMonth(direction) {
        if (direction === "next") {
            setCalendarDate(calendarDate.plus({ month: 1 }))
        } else if (direction === "back") {
            setCalendarDate(calendarDate.minus({ month: 1 }))
        }
    }

    return (
        <div className="!mb-3 flex flex-row justify-between !px-2 ">
            <div className="flex items-center text-lg "> {monthName}</div>
            <div className="flex flex-row items-center gap-4">
                <button className="flex h-[20px] w-[20px] rounded-full bg-black/5 hover:bg-black/10 items-center justify-center" onClick={() => moveMonth("back")}>
                    <FontAwesomeIcon icon={faChevronLeft} style={{ color: colors.primary }} className="w-[12px] h-[12px]" />
                </button>

                <button className="flex h-[20px] w-[20px] rounded-full bg-black/5 hover:bg-black/10 items-center justify-center" onClick={() => moveMonth("next")}>
                    <FontAwesomeIcon icon={faChevronRight} style={{ color: colors.primary }} className="w-[12px] h-[12px]"  />
                </button>
            </div>
        </div>
    )
}

function DayBarModalCalendarAvailability() {
    return (
        <div className="!mb-2 grid grid-cols-7 ">
            {days.map((day, index) => {
                return (
                    <div key={index} className="text-center text-xs text-black/80  ">
                        {day}
                    </div>
                )
            })}
        </div>
    )
}

function CalendarAvailability({ availableTimeslots, children }) {
    if (availableTimeslots.length === 0) return null
    const startOfTheMonthday = availableTimeslots[0].dateday.weekday

    return (
        <div className="grid grid-cols-7 grid-rows-6 place-items-center gap-1 text-[13px]">
            {startOfTheMonthday > 1 && <div style={{ gridColumnStart: startOfTheMonthday - 1 }} />}
            {availableTimeslots.map((dateDay) => {
                return children(dateDay)
            })}
        </div>
    )
}

function SelectionNumber({ value, isAvailable, isSelected, onClick }) {
    function handleStyleDay(isAvailable, isSelected) {
        if (isSelected) return { color: "white", backgroundColor: colors.primary }
        if (isAvailable) return { color: colors.primary }
        return { color: colors.secondary }
    }

    return (
        <button className="flex h-[28px] w-[28px] items-center justify-center rounded-xl leading-4" style={handleStyleDay(isAvailable, isSelected)} onClick={onClick} disabled={!isAvailable}>
            <div>{value}</div>
        </button>
    )
}

function NavigationArrows({ movementNext, movementBack, isDisabled = false }) {
    function movement(direction) {
        if (direction === "next") {
            movementNext()
        } else if (direction === "back") {
            movementBack()
        }
    }

    // console.log(isDisabled)
    return (
        <div className="flex flex-row items-center gap-4">
            <button
                className="flex h-[25px] w-[25px] rounded-full bg-black/5 hover:bg-black/10 justify-center items-center"
                onClick={() => movement("back")}
                disabled={isDisabled === "back" || isDisabled === "both"}
            >
                <FontAwesomeIcon icon={faChevronLeft} className={`${isDisabled === "back" || isDisabled === "both" ? "text-slate-300" : "text-primary"}`} />
            </button>

            <button
                className="flex h-[25px] w-[25px] rounded-full bg-black/5 hover:bg-black/10 justify-center items-center"
                onClick={() => movement("next")}
                disabled={isDisabled === "next" || isDisabled === "both"}
            >
                <FontAwesomeIcon icon={faChevronRight} className={`${isDisabled === "next" || isDisabled === "both" ? "text-slate-300" : "text-primary"}`} />
            </button>
        </div>
    )
}

export function TimeSlotsAvailability({ availableTimeslots, infoData, setInfoData }) {
    let numElements = 8
    const [showSection, setShowSection] = useState(0)



    function computeSection(selectedTimeSlotIdx, numElements, adjustmentIdx = 0) {
        let numSections = Number.isInteger(selectedTimeSlotIdx / numElements) ? selectedTimeSlotIdx / numElements - adjustmentIdx : Math.floor(selectedTimeSlotIdx / numElements)
        if (numSections < 0) numSections = 0
        return numSections
    }

    useEffect(() => {
        const {selectedTimeSlotIdx} = getIndexofSelectedTimeslot(availableTimeslots, infoData) 
        const initialSection = computeSection(selectedTimeSlotIdx, numElements)

        if (!isNaN(initialSection)) setShowSection(initialSection)
    }, [infoData.selectedDayTimeInfo.dayTimestamp, availableTimeslots])

    if (infoData.selectedDayTimeInfo.dayTimestamp === "not-selected") {
        return <div className="text-black/40 ">Nessun orario selezionato</div>
    }
    const selectedAvailableTimeslots = getSelectedAvailableTimeslotsOfADay(availableTimeslots, infoData)

    //This is triggered when a day was selected and then a month was changed. The selectedDayTimeInfo is still saved on the previous month.
    if (selectedAvailableTimeslots === undefined) return <div className="text-black/40 ">Nessun orario selezionato</div>
    let numSections = computeSection(selectedAvailableTimeslots.free_timeslots.length , numElements, 1)

    function isDisabledNavigation() {
        if (numSections === 0) return "both"
        if (showSection === 0) return "back"
        if (showSection === numSections) return "next"
    }
    return (
        <>
            <div className="!mb-3 flex flex-row justify-between !px-2 ">
                <div className="flex items-center text-sm">Seleziona orario</div>
                <NavigationArrows
                    movementNext={() => {
                        setShowSection(showSection + 1)
                    }}
                    movementBack={() => {
                        setShowSection(showSection - 1)
                    }}
                    isDisabled={isDisabledNavigation()}
                />
            </div>
            <div className="grid grid-cols-4 grid-rows-2 place-items-center gap-2 ">
                {selectedAvailableTimeslots.free_timeslots.slice(showSection * numElements, numElements * (showSection + 1)).map((timeSlot) => {
                    return <TimeSlotButton key={timeSlot.toMillis()} timeSlot={timeSlot} infoData={infoData} setInfoData={setInfoData} />
                })}
            </div>
        </>
    )
}

function TimeSlotButton({ timeSlot, infoData, setInfoData }) {


    function handleTimeSlotStyle(selected) {
        if (selected) {
            return "text-white bg-primary"
        } else {
            return "text-primary bg-primary/10"
        }
    }

    return (
        <button
            className={`flex h-[35px] w-[65px] grow items-center justify-center rounded-xl text-[16px] text-sm ${handleTimeSlotStyle(timeSlot.toMillis() === infoData.selectedDayTimeInfo.timeSlot)}`}
            onClick={() => {
                setInfoData({
                    ...infoData,
                    selectedDayTimeInfo: {
                        ...infoData.selectedDayTimeInfo,
                        timeSlot: timeSlot.toMillis(),
                    },
                })
            }}
        >
            {timeSlot.toLocaleString(DateTime.TIME_24_SIMPLE)}
        </button>
    )
}

function CalendarMode({ onClickMode1, onClickMode2, defaultMode = 1 }) {
    const [mode, setMode] = useState(defaultMode)

    return (
        <div className="flex flex-row justify-center items-center !mb-5 px-2">
            <button
                className={`flex w-full justify-center !py-[7px] rounded-s-lg ${mode === 1 ? "bg-primary/90 text-white" : "bg-primary/20 text-primary font-medium"}`}
                onClick={() => {
                    setMode(1)
                    onClickMode1()
                }}
            >
                Auto
            </button>
            <button
                className={`flex w-full justify-center !py-[7px] rounded-e-lg ${mode === 2 ? "bg-primary/90 text-white" : "bg-primary/20 text-primary font-medium"}`}
                onClick={() => {
                    setMode(2)
                    onClickMode2()
                }}
            >
                Manuale
            </button>
        </div>
    )
}

export default function DateTimePicker({
    className,
    calendarDate,
    setCalendarDate,
    infoData,
    setInfoData,
    availableTimeslots,
    onClickSelectionNumber,
    isAvailableSelectionNumber,
    isSelectedSelectionNumber,
    timeslotMode = true,
    multiModes = false,
    defaultMode,
    onClickMode1 = () => {},
    onClickMode2 = () => {},
}) {
    return (
        <div className={className}>
            {multiModes && <CalendarMode onClickMode1={onClickMode1} onClickMode2={onClickMode2} defaultMode={defaultMode} />}
            <MonthBarModalCalendarAvailability calendarDate={calendarDate} setCalendarDate={setCalendarDate} />
            <DayBarModalCalendarAvailability />
            <CalendarAvailability calendarDate={calendarDate} availableTimeslots={availableTimeslots}>
                {(dateDay) => {
                    return (
                        <SelectionNumber
                            key={dateDay.dateday.toMillis()}
                            value={dateDay.dateday.day}
                            // isAvailable={dateDay.free_timeslots.length > 0}
                            isAvailable={isAvailableSelectionNumber(dateDay)}
                            // isSelected={infoData.selectedDayTimeInfo.dayTimestamp === timeStamp}
                            isSelected={isSelectedSelectionNumber(dateDay, infoData)}
                            // onClick={() => }
                            onClick={() => {
                                onClickSelectionNumber(dateDay, setInfoData)
                            }}
                        />
                    )
                }}
            </CalendarAvailability>
            {timeslotMode && (
                <div className="!mt-2 flex w-full flex-col">
                    <TimeSlotsAvailability availableTimeslots={availableTimeslots} infoData={infoData} setInfoData={setInfoData} />
                </div>
            )}
        </div>
    )
}
