import React, { useState } from 'react';

import CustomerHandler from './CustomerHandler';
import TemplateEditor from './TemplateEditor';

export default function TemplatePanel({customers, unselectedCustomers}) {
    const [selectedCustomerTemplate, setSelectedCustomerTemplate] = useState({id:''})


    return (
        <div className='d-flex flex-row'>
            <CustomerHandler selectedCustomerTemplate={selectedCustomerTemplate} unselectedCustomers={unselectedCustomers} setSelectedCustomerTemplate={setSelectedCustomerTemplate} customers={customers}  />
            <TemplateEditor selectedCustomerTemplate={selectedCustomerTemplate} />
        </div>
    )
}
