import React, { useEffect, useState } from "react"
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom"
import { DatabaseProvider } from "../contexts/DatabaseProvider"
import { DatabaseProvider as BookingDataBaseProvider } from "./dashboard/main-panel/easy-calendar/contexts/DatabaseProvider"

import { AuthenticationProvider } from "../contexts/AuthenticationProvider"
import { DashboardProvider } from "../contexts/DashboardProvider"
import { MessagesProvider } from "../contexts/MessagesProvider"
import Dashboard from "./Dashboard"
import PrivateRoute from "./PrivateRoute"
import Login from "./authentication/Login"
import NewPassword from "./authentication/NewPassword"
import ResetPassword from "./authentication/ResetPassword"
import { ErrorBoundary } from "react-error-boundary"
import { Button } from "react-bootstrap"
import { SalonProvider } from "../contexts/SalonProvider"
import * as Sentry from "@sentry/react"

function FallBackComponent() {
    return (
        <div className="d-flex flex-column ">
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "100vh" }}>
                <div className="pt-2 text-secondary" style={{ fontSize: "17px" }}>
                    Ops, qualcosa è andato storto
                </div>
                <div className="d-flex mt-4">
                    <Button onClick={() => window.location.reload()}>Ricarica</Button>
                </div>
            </div>
        </div>
    )
}

function WrappedDashboard() {
    return (
        <SalonProvider>
            <DatabaseProvider>
                <BookingDataBaseProvider>
                    <MessagesProvider>
                       
                        <Sentry.ErrorBoundary fallback={<FallBackComponent />}>
                            <DashboardProvider>
                                <Dashboard />
                            </DashboardProvider>
                        </Sentry.ErrorBoundary>
                    </MessagesProvider>
                </BookingDataBaseProvider>
            </DatabaseProvider>
        </SalonProvider>
    )
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window
    return {
        width,
        height,
    }
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    return windowDimensions
}

export default function App() {
    const { height, width } = useWindowDimensions()

    useEffect(() => {
        document.title = "LDN Hair Salon App"
    }, [])

    return (
        <div style={{ width: width < 1080 && "1080px" }}>
            {/* {console.log(width)} */}
            <Router>
                <AuthenticationProvider>
                    <Routes>
                        <Route exact path="" element={<Navigate to="/login" />} />

                        <Route path="/dashboard" element={<PrivateRoute />}>
                            <Route path="/dashboard" element={<WrappedDashboard />} />
                        </Route>

                        <Route path="/login" element={<Login />} />

                        <Route path="/reset-password" element={<ResetPassword />} />

                        <Route path="/new-password/:id/:token" element={<NewPassword />} />

                        <Route
                            path="*"
                            element={
                                <main style={{ padding: "1rem" }}>
                                    <p>There's nothing here!</p>
                                </main>
                            }
                        />
                    </Routes>
                </AuthenticationProvider>
            </Router>
        </div>
    )
}
