import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark, faSearch, faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { handleDictionaryChanges } from "../utils/helpers"
import useComponentVisible from "../components/useComponentVisible"

export default function SearchBox({ value, label, name, placeholder, type, onChange, data = null, searchData = () => {}, onClick = () => {}, customFilter = null }) {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)

    return (
        <div className="relative">
            <div className="flex h-10 flex-row items-center rounded-2xl bg-slate-100 text-sm outline-none">
                <div className="flex items-center px-4">
                    <FontAwesomeIcon icon={faSearch} size="lg" className="text-black/50" />
                </div>

                <input
                    className="flex w-full rounded-e-2xl bg-transparent outline-none"
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => {
                        onChange(e)
                        if (!isComponentVisible) setIsComponentVisible(true)
                    }}
                    // onBlur={() => { setIsComponentVisible(false) }}
                    // Add button to close the list dialog
                ></input>

                
            </div>
            <div ref={ref}>
                {data !== null && isComponentVisible && (
                    <div className="absolute left-2 right-2 mt-2 flex max-h-48 flex-col overflow-auto rounded-md bg-white shadow-md">
                        {data.map((entry) => {
                            const formattedEntry = searchData(entry)
                            const standardFilter = formattedEntry.toLowerCase().includes(value.toLowerCase())
                            const filter = customFilter(entry, value) 
                        
                            if (filter || standardFilter) {
                                return (
                                    <button
                                        key={entry.id}
                                        className="flex p-2 ps-4 hover:bg-slate-50"
                                        onClick={() => {
                                            onClick(entry)
                                            setIsComponentVisible(false)
                                        }}
                                    >
                                        {formattedEntry}
                                    </button>
                                )
                            }
                        })}
                    </div>
                )}
            </div>
        </div>
    )
}
