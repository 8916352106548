import React from 'react'
import { useAuthentication } from '../../contexts/AuthenticationProvider'
import { useNavigate } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import { Button } from 'react-bootstrap'

export default function LoadingScreen({children, text, loading}) {
    const {logOut} = useAuthentication()
    const navigate = useNavigate()
    const handleLogout = () => {
        logOut()
        navigate("/login")
    }

  return (
    <>
    {!loading ? (
        children
    ) : (
        <div className="d-flex flex-column ">
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "100vh" }}>
                <CircularProgress />
                <div className="pt-2 text-secondary" style={{ fontSize: "17px" }}>
                    {text}
                </div>
                <div className="d-flex mt-4">
                    <Button onClick={() => handleLogout()}>Logout</Button>
                </div>
            </div>
        </div>
    )}
    </>
  )
}
