import { useDashboard } from "../../../../contexts/DashboardProvider"
import Calendar from "./components/Calendar"
import "react-international-phone/style.css"

function EasyCalendar() {
    const { calendarOperator } = useDashboard()

    if (calendarOperator === undefined) {
        return <div className="flex text-[48px] justify-center text-[#d3d3d3] h-full items-center">Nessun operatore in questo salone</div>
    }

    return <Calendar />
}

export default EasyCalendar
