import React, { useEffect, useState } from "react"
import useComponentVisible from "../components/useComponentVisible"
import { DateTime } from "luxon"
import { generateMonthlyDates } from "../utils/helpers"
import DateTimePicker from "../components/DateTimePicker"
import ExpandingSection from "./ExpandingSection"



export default function CalendarButton({ value, setValue, children, startingMonth = undefined, autoclose = false }) {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
    const [calendarDate, setCalendarDate] = useState(startingMonth ? startingMonth : DateTime.now().startOf("month"))

    useEffect(() => {
        setCalendarDate(startingMonth)
    }, [startingMonth])

    function Calendar() {
        return (
            <DateTimePicker
                calendarDate={calendarDate}
                setCalendarDate={setCalendarDate}
                availableTimeslots={generateMonthlyDates(calendarDate)} //Memoize this function if needed
                isAvailableSelectionNumber={(dateDay) => true}
                isSelectedSelectionNumber={(dateDay) => dateDay.dateday.toMillis() === value?.toMillis()}
                onClickSelectionNumber={(dateDay) => {
                    setValue(dateDay)
                    if (autoclose) setIsComponentVisible(false)
                }}
                timeslotMode={false}
            />
        )
    }

    return (
        <ExpandingSection refSection={ref} isComponentVisible={isComponentVisible}  extraComponent={<Calendar />}>
            <div onClick={() => setIsComponentVisible(true)}>{children}</div>
        </ExpandingSection>
    )
}
