import React, { useEffect, useState } from "react"
import { Toast, ToastBody, ToastHeader } from "react-bootstrap"

function ConnectionStatus({ children }) {
    // State to track online/offline status
    const [isOnline, setIsOnline] = useState(navigator.onLine)

    useEffect(() => {
        // Event handler for when the browser goes online
        const handleOnline = () => setIsOnline(true)

        // Event handler for when the browser goes offline
        const handleOffline = () => setIsOnline(false)

        // Add event listeners
        window.addEventListener("online", handleOnline)
        window.addEventListener("offline", handleOffline)

        // Cleanup event listeners on component unmount
        return () => {
            window.removeEventListener("online", handleOnline)
            window.removeEventListener("offline", handleOffline)
        }
    }, [])

    return (
        <>
            {!isOnline && <div className="fixed inset-0 z-10 bg-black bg-opacity-50"></div>}
            <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-20">
                <Toast show={!isOnline} bg={"dark"} className="rounded-xl">
                    <ToastBody>
                        <div className="flex flex-col">
                            <div className="flex text-white text-lg mb-2 justify-center">Problemi di rete</div>
                            <div className="flex text-white text-md justify-center">Connessione assente, controlla il WiFi</div>
                        </div>
                    </ToastBody>
                </Toast>
            </div>
        </>
    )
}

export default ConnectionStatus
