import React, { useEffect, useState } from "react"
import { CountrySelector } from "react-international-phone"

import InputBox from "./InputBox"
import { formatPhoneNumber } from "../utils/helpers"
import { isValidPhoneNumber } from "libphonenumber-js"

export default function PhoneInputBox({ value, label, name, onChange, countryCode }) {
    

    const countryCodeSelector = (
        <CountrySelector
            buttonStyle={{ border: "0px", paddingLeft: "15px", paddingRight: "10px", borderTopLeftRadius: "1rem", borderBottomLeftRadius: "1rem", height: "100%" }}
            selectedCountry={countryCode.iso2}
            onSelect={(data) => {
                onChange({ target: { name: "countryCode", value: data } })
                // console.log(data)
            }}
        />
    )

    return (
        <InputBox
            label={label}
            name={name}
            onChange={(e) => {
                if (/^[0-9\s()-]*$/.test(e.target.value) === false) return 
                onChange(e)
            }}
            placeholder={"XXX XXX XX XX"}
            value={isValidPhoneNumber(countryCode.dialCode + value, countryCode.iso2.toUpperCase()) ? formatPhoneNumber(value, countryCode.iso2.toUpperCase()) : value}

            prefix={countryCodeSelector}
        />
    )
}
