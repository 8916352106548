import React, { useState, useEffect } from "react"
import { useDashboard } from "../../../../contexts/DashboardProvider"
import { ListGroup } from "react-bootstrap"
import { useMessages } from "../../../../contexts/MessagesProvider"
import InputBox from "../../main-panel/easy-calendar/elements/InputBox"
import { useAuthentication } from "../../../../contexts/AuthenticationProvider"
import { ROLES, hasPermission } from "../../../../helper"
import { BsCircleFill } from "react-icons/bs"
import { IoIosRefresh } from "react-icons/io"
import { IconButton } from "@mui/material"

function ExtraFunctions({ conversation }) {
    const { deleteConversationBySID } = useMessages()
    const [sid, setSid] = useState("")

    function handleSid(e) {
        const { name, value } = e.target
        setSid(value)
    }

    return (
        <div className="flex flex-col p-2 gap-2 bg-secondary/50">
            <InputBox name="email" value={sid} onChange={handleSid} />
            <button
                className="flex p-2 bg-red-700 rounded-md text-white text-lg justify-center"
                onClick={() => {
                    deleteConversationBySID(sid)
                }}
            >
                Delete conversation
            </button>
            <button
                className="flex p-2 bg-primary rounded-md text-white text-lg justify-center"
                onClick={async () => {
                    await conversation.setAllMessagesRead()
                }}
            >
                Read chat
            </button>
            <button
                className="flex p-2 bg-secondary rounded-md text-white text-lg justify-center"
                onClick={async () => {
                    await conversation.setAllMessagesUnread()
                    // await conversation.updateLastReadMessageIndex(20)
                }}
            >
                Unread chat
            </button>
        </div>
    )
}

export default function MessagesList() {
    const { colorTheme } = useDashboard()
    const { user } = useAuthentication()
    const { conversations, selectedConversation, safeSetSelectedConversation, forceRefreshClient, lastUpdate } = useMessages()
    const [sortedConversations, setSortedConversations] = useState([])
    const [isRefreshing, setIsRefreshing] = useState(false)

    const sortByMostRecent = (a, b) => {
        if (a._internalState.lastMessage && b._internalState.lastMessage) {
            const aTime = a._internalState.lastMessage.dateCreated.getTime()
            const bTime = b._internalState.lastMessage.dateCreated.getTime()
            return aTime - bTime
        }
    }

    function unreadMessages(conversation) {
        return conversation._internalState.lastMessage?.index === conversation._internalState.lastReadMessageIndex
    }

    useEffect(() => {
        const sortedConversationsT = [...conversations].sort((a, b) => sortByMostRecent(a, b)).reverse()
        setSortedConversations(sortedConversationsT)
    }, [conversations])

    return (
        <>
            <div className="overflow-auto">
                {/* If issues on messages still occur of not syncing messages enable this  */}
                {/* <ExtraFunctions conversation={selectedConversation} /> */}
                {/* {console.log(isRefreshing)} */}
                <div className="flex px-[35px] !py-3 items-center gap-2" style={{ backgroundColor: colorTheme.thirdColor }}>
                    <button
                        className={`relative !flex-none !w-6 !h-6   text-white rounded-full ${isRefreshing ? "bg-gray-500" : "bg-primary"}`}
                        disabled={isRefreshing}
                        onClick={async () => {
                            // console.log("pressed")
                            setIsRefreshing(true)
                            await forceRefreshClient()
                            setIsRefreshing(false)
                        }}
                    >
                        <IoIosRefresh className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" color={"white"} />
                    </button>

                        
                    
                    
                    <div className="flex text-md  text-primary ">Ultimo update: {lastUpdate.toFormat("dd-MM || HH:mm")}</div>
                </div>

                <ListGroup className="d-flex flex-fill" variant="flush" style={{ fontSize: "18px" }}>
                    {sortedConversations.map((conversation) => {
                        let showConversation
                        // console.log(conversation._internalState)
                        if (hasPermission(user, [ROLES.owner])) showConversation = true
                        else showConversation = user.salon.some((salon) => conversation._internalState.attributes.salon.includes(salon.id))
                        if (!showConversation) return

                        const selected = selectedConversation === conversation
                        return (
                            <ListGroup.Item
                                action
                                key={conversation.sid}
                                onClick={() => {
                                    safeSetSelectedConversation(conversation)
                                }}
                                active={selected}
                                style={{ paddingLeft: "35px", paddingRight: "35px", backgroundColor: selected && colorTheme.primaryColor }}
                            >
                                <div className="flex flex-row justify-between items-center">
                                    <div className="flex-fill text-base">{conversation._internalState.attributes.name}</div>

                                    {!unreadMessages(conversation) && <BsCircleFill color={"limegreen"} size={10} />}
                                </div>
                            </ListGroup.Item>
                        )
                    })}
                </ListGroup>
            </div>
        </>
    )
}
