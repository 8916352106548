import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import TimeStatsPanel from './TimeStatsPanel';
import { useDatabase } from '../../../../contexts/DatabaseProvider'
import { IconButton } from '@mui/material';
import { MdOutlineArrowForwardIos, MdOutlineArrowBackIos } from 'react-icons/md'
import { BsCalendar3 } from 'react-icons/bs'
import { IoIosRefresh } from 'react-icons/io'
import { monthsName, ROLES } from '../../../../helper'
import { useDashboard } from '../../../../contexts/DashboardProvider';
import PermissionGate from '../../../permissions/PermissionGate';
import { CgFileDocument } from "react-icons/cg";
import IncomeSummary from './IncomeSummary';
import { DateTime } from 'luxon';



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className='mt-4'>
          {children}
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


export default function TimeStats({ statsList, date, setDate }) {
  const { updateStats } = useDatabase()
  const { colorTheme } = useDashboard()
  const [value, setValue] = useState(0);
  const [initialMonth, initialYear] = [new Date().getMonth(), new Date().getFullYear()]
  const [isCurrentMonth, setIsCurrentMonth] = useState(true)
  const [modalShow, setModalShow] = useState(false)
  const [isLoadingStats, setIsLoadingStats] = useState(false)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  

  const moveMonth = async (direction) => {
    setIsLoadingStats(true)
    const newDate = new Date (date)
    newDate.setMonth(newDate.getMonth() + direction)
    setIsCurrentMonth(newDate.getMonth() === initialMonth)
    setDate(newDate)
    await updateStats(newDate)   
    setIsLoadingStats(false)
  }

  function getYearMonth(date){
    let year = date.getFullYear();

    // Get the month (0-11, where 0 is January and 11 is December)
    let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add 1 to get a human-readable month (1-12) and pad with leading zero if necessary
    
    // Format the string as YYYY-mm
    return `${year}-${month}`;  }
 
  return (
    <Box sx={{ width: '100%' }}>
      <Box className='d-flex flex-row align-items-center justify-content-between' sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <PermissionGate permissions={[ROLES.manager, ROLES.owner]}>
          <Tabs value={value} TabIndicatorProps={{ style: { backgroundColor: colorTheme.primaryColor } }} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Entrate" style={{ textTransform: 'none', fontFamily: 'Poppins', padding: '0px', margin: '0px', alignItems: 'start', color: value === 0 && colorTheme.tabcolor }} />
            <Tab label="Uscite" style={{ textTransform: 'none', fontFamily: 'Poppins', padding: '0px', margin: '0px', alignItems: 'start', color: value === 1 && colorTheme.tabcolor }} />
            {/* <Tab label="Profitto" style={{ textTransform: 'none', fontFamily: 'Poppins', padding: '0px', margin: '0px', alignItems: 'start', color: value === 2 && colorTheme.tabcolor }} /> */}
          </Tabs>
        </PermissionGate>

        <PermissionGate permissions={[ROLES.hairdresser]}>
          <Tabs value={value} TabIndicatorProps={{ style: { backgroundColor: colorTheme.primaryColor } }} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Entrate" style={{ textTransform: 'none', fontFamily: 'Poppins', padding: '0px', margin: '0px', alignItems: 'start', color: value === 0 && colorTheme.tabcolor }} />
          </Tabs>
        </PermissionGate>



        <div className='d-flex flex-row text-secondary align-items-center'>

          <PermissionGate permissions={[ROLES.owner]}>
            <div className='me-4'>
              <IconButton onClick={() => setModalShow(true)} >
                <CgFileDocument size={17} color={colorTheme.primaryColor}/>
              </IconButton>
            </div>
            <IncomeSummary modalShow={modalShow} setModalShow={setModalShow} date={date} />
          </PermissionGate>


          <IconButton className='p-2' disabled={isLoadingStats} onClick={() => { moveMonth(-1) }}>
            <MdOutlineArrowBackIos size={15} />
          </IconButton>

          <div className='d-flex flex-row  align-items-center' style={{ width: '90px' }}>
            <BsCalendar3 className='me-2' />
            {/* {console.log(date)} */}
            {monthsName[date.getMonth()] + ' ' + date.getFullYear()}
          </div>
          {/* {console.log(date.getMonth())} */}

          <IconButton className='p-2 me-2' disabled={isLoadingStats || getYearMonth(date) === DateTime.now().toFormat('yyyy-MM') } onClick={() => { moveMonth(1) }}>
            <MdOutlineArrowForwardIos size={15} />
          </IconButton>

          <IconButton onClick={() => {
            setDate(dateT => { // Weird glitch, if not following this way then there is unwanted behaviour after reset month that does not move the month for one click on the arrow
              // console.log('month', initialMonth)
              dateT.setMonth(initialMonth)
              dateT.setYear(initialYear)
              updateStats(dateT)
              return new Date(dateT)
            })
            // console.log('been')
          }}>
            <IoIosRefresh size={15} color={colorTheme.primaryColor} />
          </IconButton>
        </div>



      </Box>
      {/* {console.log(statsList)} */}
      {statsList.timePanel.map((statsTime, index) => {
        return (
          <TabPanel key={index} value={value} index={index} >
            <TimeStatsPanel statsType={statsTime} date={date}/>
          </TabPanel>
        )
      })}

    </Box>
  );
}