import React from 'react'
import { useState, useRef, useEffect } from "react"
import { Modal, Button, Container, InputGroup, FormControl, Spinner } from "react-bootstrap"
import { AiOutlinePlusCircle } from "react-icons/ai"
import { useDatabase } from '../../../../contexts/DatabaseProvider'
import { useDashboard } from '../../../../contexts/DashboardProvider'
import { BsCheckCircle, BsCircle } from 'react-icons/bs'
import { VscCheck, VscCheckAll } from "react-icons/vsc"
import { CgClose } from "react-icons/cg"
import { urls, getData } from '../../../../helper'
import moment from "moment/moment"




export default function NewGiftCard({ giftCardsSelected, setGiftCardsSelected }) {

    const defaultGiftCard = { id: '', code: '', operator: '---', smartsale: '', sell_price: 0, quantity: 1, type: 'giftCard', discount: 0 }

    const { colorTheme } = useDashboard()
    const [modalShow, setModalShow] = useState(false)
    const [selectedGiftCardModal, setSelectedGiftCardModal] = useState(defaultGiftCard)
    const [giftCardExpiryDate, setGiftCardExpiryDate] = useState('')
    const [status, setStatus] = useState('toVerify')
    const searchCardRef = useRef(null)

    const handleGiftCardUpdate = (key, value) => { //Use this syntax to update dictionaries

        if (key === 'code') {
            if (value.length > 5) {
                return
            }
        }


        setSelectedGiftCardModal({ ...selectedGiftCardModal, [key]: value })
    }


    useEffect(() => {
        if (modalShow) {
            // console.log(searchCardRef.current)
            searchCardRef.current.focus()
        }
    }, [modalShow])

    useEffect(() => {
        if (status !== 'valid' && searchCardRef.current !== null) {
            searchCardRef.current.focus()
        }
    }, [status])

    const verifyGiftCard = async () => {

        const isAlreadyListed = giftCardsSelected.some((giftCardT) => { return giftCardT.code === selectedGiftCardModal.code })

        if (isAlreadyListed) {

            setStatus('invalid')
            return
        }

        if (status === "toVerify" || status === "invalid") {
            setStatus('checking')
            const response = await getData(urls.retrieveNotIssuedGiftCards + selectedGiftCardModal.code + '/')
            // console.log(response)
            if (response.id !== undefined) {
                handleGiftCardUpdate('id', response.id)
                setGiftCardExpiryDate(response.expiry_date)
                setStatus('valid')
            }
            else {
                setStatus('invalid')

            }

        }
    }


    useEffect(() => {
        if (selectedGiftCardModal.code.length === 5) { // Improve by using a useRef that check if the prev value is equal to the current one, in order to fix endless loop that it happens now if it is not cleaned the code
            verifyGiftCard()
        }
    }, [selectedGiftCardModal.code])


    const addGiftCard = () => {


        setGiftCardsSelected([...giftCardsSelected, {...selectedGiftCardModal, name: `Carta Regalo - ${selectedGiftCardModal.code}`, date: new Date().toISOString()} ])
        setSelectedGiftCardModal(defaultGiftCard)
        setStatus('toVerify')
        setModalShow(false)
    }

    // console.log(selectedGiftCardModal.code)
    // console.log(prevCode.current)


    return (
        <>
            <Button variant="white rounded-pill flex items-center justify-center" onClick={() => setModalShow(true)}>
                <AiOutlinePlusCircle color={colorTheme.primaryColor}/>
                <span className='ms-2' style={{ color: colorTheme.primaryColor }}>Aggiungi carta regalo</span>
            </Button >

            <Modal
                show={modalShow}
                onHide={() => {
                    setSelectedGiftCardModal(defaultGiftCard)
                    setGiftCardExpiryDate('')
                    setStatus('toVerify')
                    setModalShow(false)
                }}
                size="sg"
                aria-labelledby="contained-modal-title-vcenter" >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Carta Regalo
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: "0px" }}>
                    <Container style={{ padding: "1px" }}>


                    </Container>
                    <div className=' text-center p-3 pb-0 mb-3' style={{ fontSize: '17px' }}>
                        Scanerizza il codice della carta regalo
                    </div>
                    <div className='d-flex flex text-center mb-3 justify-content-center ' style={{ fontSize: '17px' }}>
                        <div className='d-flex flex-row justify-content-center align-items-center pt-3' >
                            <InputGroup className='ms-4 me-2' style={{ width: '40%', caretColor: 'transparent' }} >
                                <FormControl
                                    ref={searchCardRef}
                                    className='text-center'
                                    variant='secondary'
                                    style={{ borderRadius: "20px", height: '50px', fontSize: '20px' }}
                                    placeholder={'_ _ _ _ _'}
                                    value={selectedGiftCardModal.code}
                                    disabled={status === 'checking' || status === 'valid'}
                                    onChange={e => { handleGiftCardUpdate('code', e.target.value.toUpperCase()) }}
                                />
                            </InputGroup>
                            <div>
                                {status === "toVerify" && <VscCheck size={20} style={{ width: '25px' }} color='transparent' />}
                                {status === "checking" && <Spinner animation='border' size="sm" variant='primary' />}
                                {status === "valid" && <VscCheckAll size={20} color={"dodgerblue"} style={{ width: '25px' }} />}
                                {status === "invalid" && <CgClose color="red" style={{ width: '25px' }} />}
                            </div>

                           

                        </div>
                       
                    </div>

                    <div className='text-center mb-4' style={{color: colorTheme.primaryColor, height: '20px'}}>
                    {status === "valid" && <div>Valida fino il {moment(giftCardExpiryDate).format('DD-MM-YYYY')}</div>}
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant={colorTheme.button} onClick={() => addGiftCard()} disabled={status !== 'valid'}> Avanti</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}