import React, { useState } from 'react'
import { FiKey } from 'react-icons/fi'
import { MdEmail } from 'react-icons/md'
import { Button, InputGroup, FormControl, Alert } from "react-bootstrap"
import { useNavigate } from 'react-router-dom';
import { sendData, TextButton, urls } from '../../helper';

export default function ResetPassword() {
    const defaultAlertBox = { mode: "", message: "", enabled: false }
    const [isLinkSent, setIsLinkSent] = useState(false)
    const [email, setEmail] = useState('')


    const [alertBox, setAlertBox] = useState(defaultAlertBox)
    const navigate = useNavigate()

    // DO NOT DELETE: if there are issue with reset password check if the tokens are undefined, if so uncomment this.
    // useEffect(() => {
    //     localStorage.removeItem(ACCESS_TOKEN)
    //     localStorage.removeItem(REFRESH_TOKEN)
    // }, [])

    const handleResetPassword = async () => {
        setAlertBox(defaultAlertBox)
        const regex = new RegExp('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$')
        if (regex.test(email)) {
            const content = {
                email: email
            }
            const response = await sendData(urls.resetPassword, content)
            // console.log(response.detail)
            if (response.detail === 'Not found.') { //To do improve with status
                setAlertBox({ mode: "danger", message: 'Utente non esistente', enabled: true })
                return
            }
            setIsLinkSent(true)
        }
        else {
            setAlertBox({ mode: "danger", message: 'Formato email non valido', enabled: true })
            // console.log('not-accepted')
        }
    }


    return (
        // <div className='d-flex flex-fill justify-content-center' style={{ heigth: '100vh' }}>
        <div className='d-flex flex-column ' style={{ paddingTop: '12vh' }}>
            <div className='d-flex justify-content-center' >
                {isLinkSent ?
                    <MdEmail className='d-flex' color='dodgerblue' size={65} style={{ backgroundColor: 'aliceblue', borderRadius: '100px', padding: '15px' }} />
                    :
                    <FiKey className='d-flex' color='dodgerblue' size={65} style={{ backgroundColor: 'aliceblue', borderRadius: '100px', padding: '15px' }} />
                }
            </div>
            <div className='d-flex justify-content-center pt-4' style={{ fontSize: 32, fontWeight: '600' }}>
                {isLinkSent ?
                    'Controlla la tua email'
                    :
                    'Password dimenticata?'
                }
            </div>
            <div className='d-flex justify-content-center pt-3 mb-3' style={{ fontSize: 18, textAlign: 'center', color: 'dimgray' }}>
                {isLinkSent ?
                    <div>
                        Abbiamo inviato un link alla seguente email <br></br>
                        <span style={{ fontWeight: '600' }} >{email}</span>
                    </div>
                    :
                    <div>
                        Non ti preoccupare, ti invieremo le istruzioni <br></br> per recuperarla
                    </div>
                }
            </div>

            {isLinkSent || <div className='d-flex justify-content-center pt-3 mt-2'>
                <InputGroup style={{ width: '350px' }}>
                    <FormControl
                        variant='secondary'
                        style={{ borderRadius: "20px", width: '350px', height: '50px' }}
                        type='email'
                        placeholder={'Email'}
                        value={email}
                        onChange={e => { setEmail(e.target.value) }}
                    />
                </InputGroup>
            </div>}




            {isLinkSent || <div className='d-flex justify-content-center pt-3 mt-3'>
                <Button
                    variant={'primary'}
                    disabled={email === ''}
                    style={{ borderRadius: "15px", width: '350px', fontSize: '16px', height: '45px' }}
                    size="lg"
                    onClick={() => { handleResetPassword() }}
                >
                    Reimposta password
                </Button>
            </div>}

            <div className='d-flex justify-content-center mt-2'>
                {alertBox.enabled && <Alert
                    className='text-center'
                    variant={alertBox.mode}
                    style={{ width: '350px' }}
                >
                    {alertBox.message}</Alert>}
            </div>


            <div className='d-flex justify-content-center pt-2' style={{ fontSize: '14px', color: 'gray' }}>
                {isLinkSent ?
                    <Button
                        variant="outline-primary"
                        style={{ borderRadius: "15px", width: '350px', fontSize: '16px', height: '45px' }}
                        size="lg"
                        onClick={() => navigate('/login')}
                    >
                        Torna al login
                    </Button>
                    :
                    <TextButton onClick={() => navigate('/login')}>
                        Torna al login
                    </TextButton>
                }
            </div>
        </div >
    )
}