import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import { CgFileDocument } from "react-icons/cg"
import { useDashboard } from "../../../../contexts/DashboardProvider"
import useComponentVisible from "../easy-calendar/components/useComponentVisible"
import { DateTime } from "luxon"
import { getData, loyaltyCategories, range, urls } from "../../../../helper"
import { NavigationBar } from "../easy-operators/EasyOperators"
import { useSalon } from "../../../../contexts/SalonProvider"

function CustomerRecords({ filteredRecords, onClick, isSelected }) {
    return (
        <div className="relative flex px-3 text-md w-[50%] overflow-auto">
            <div className="absolute left-0 right-0 flex flex-col divide-y-2  ">
                {filteredRecords.map((record) => (
                    <button key={record.id} className={`flex py-2 gap-4 px-4  justify-between  ${isSelected(record) && "text-primary bg-primary/10 rounded-md"} `} onClick={() => onClick(record)}>
                        <div className="flex">{record.customer.first_name + " " + record.customer.last_name}</div>
                        {[loyaltyCategories[3][1], loyaltyCategories[4][1]].includes(record.type) && <div>{record.date}</div>}
                    </button>
                ))}
            </div>
        </div>
    )
}

function CustomerHistoryRecords({ selectedCustomer, loyaltyRecordsSelectedCustomer }) {
    if (selectedCustomer === undefined) {
        return <div className="flex flex-col w-[50%] !text-gray-400 text-lg font-normal bg-whitesmoke p-3 rounded-md h-fit">Nessun cliente selezionato</div>
    }

    return (
        <div className="relative flex px-3 text-md w-[50%] overflow-auto">

        <div className="absolute left-0 right-0 flex flex-col bg-whitesmoke p-3 rounded-md ">

            <div className="!text-black text-lg font-medium mb-3">{selectedCustomer.first_name + " " + selectedCustomer.last_name}</div>
            {loyaltyRecordsSelectedCustomer.map((record) => (
                <div key={record.id} className="flex flex-row bg-white rounded-md p-2 justify-between mb-2 text-md px-4">
                    <div>{record.date}</div>
                    <div> {record.type}</div>
                </div>
            ))}
        </div>
        </div>
    )
}



export default function CustomerLoyaltySummary({ modalShow, setModalShow }) {
    const { colorTheme } = useDashboard()
    const {selectedSalon} = useSalon()
    const [selectedCategoryIdx, setSelectedCategoryIdx] = useState(0)
    const [loyaltyRecords, setLoyaltyRecords] = useState([])
    const [selectedCustomer, setSelectedCustomer] = useState(undefined)
    const [loyaltyRecordsSelectedCustomer, setLoyaltyRecordsSelectedCustomer] = useState([])

    async function retrieveLoyaltyStatus() {
        const data = await getData(urls.retrieveLoyaltyStatus + selectedSalon.id + "/")
        const formattedRecords = data.map((x) => ({
            ...x,
            date: DateTime.fromISO(x.date).toFormat("dd-MM-yyyy"),
        }))
        // console.log(formattedRecords)
        setLoyaltyRecords(formattedRecords)
    }

    async function retrieveLoyaltyHistoryRecords(customerId) {
        const data = await getData(urls.retrieveLoyaltyRecordsCustomer + customerId + "/")
        const formattedRecords = data.map((x) => ({
            ...x,
            type: loyaltyCategories.filter((y) => x.type === y[1])[0][0],
            date: DateTime.fromISO(x.date).toFormat("dd-MM-yyyy"),
        }))
        // console.log(formattedRecords)



        setLoyaltyRecordsSelectedCustomer(formattedRecords)
    }

    useEffect(() => {
        retrieveLoyaltyStatus()
    }, [selectedSalon.id])

    
    const filteredRecords = loyaltyRecords.filter((x) => x.type === loyaltyCategories[selectedCategoryIdx][1])
    return (
        <Modal
            show={modalShow}
            onHide={() => {
                setModalShow(false)
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <Modal.Title className="flex text-center contained-modal-title-vcenter align-items-center ">
                    <CgFileDocument className="me-2" size={28} color={colorTheme.primaryColor} />
                    <div className="me-4">Report fedeltà</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <div className="flex flex-row text-base items-center justify-start mb-3 ">
                    <div className="flex flex-row grow">
                        <NavigationBar titles={loyaltyCategories.map((x) => x[0])} isSelected={(idx) => idx === selectedCategoryIdx} onClick={(idx) => setSelectedCategoryIdx(idx)} />
                    </div>
                </div>
                <div className="flex flex-row gap-2 h-[500px]">
                    <CustomerRecords
                        filteredRecords={filteredRecords}
                        isSelected={(record) => record.customer.id === selectedCustomer?.id}
                        onClick={(record) => {
                            // console.log(record)
                            retrieveLoyaltyHistoryRecords(record.customer.id)
                            setSelectedCustomer(record.customer)
                        }}
                    />

                    <CustomerHistoryRecords selectedCustomer={selectedCustomer} loyaltyRecordsSelectedCustomer={loyaltyRecordsSelectedCustomer} />
                </div>
            </Modal.Body>
            <Modal.Footer />
        </Modal>
    )
}
