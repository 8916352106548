import React from "react"

export default function AlertBox({ msg, className, showStatus }) {
    return (
        <>
            {showStatus && (
                <div className={"flex h-10 items-center justify-center rounded-md bg-red-50 p-4 text-center" + " " + className}>
                    <div className="text-red-700 ">{msg}</div>
                </div>
            )}
        </>
    )
}
