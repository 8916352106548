import React, { useEffect } from 'react'
import { useState } from "react"
import { FormControl, InputGroup, Dropdown, Col, Row } from "react-bootstrap"
import { FiTrash } from "react-icons/fi"
import { AiOutlineDelete } from 'react-icons/ai'
import { IconButton } from '@mui/material'

export default function Product({ operators, productSelected, setProductsSelected }) {
    const [operatorSelected, setOperatorSelected] = useState({ id: '', name: '---' })
    const [product, setProduct] = useState(productSelected)
    const [quantity, setQuantity] = useState('')

    useEffect(() => {
        setProduct({ ...productSelected })
    }, [productSelected])


    const removeProduct = () => {
        setProductsSelected(productsT => { return [...productsT.filter(productTT => { return productTT !== productSelected })] })
    }

    const updateProducts = (prevVar, newVar) => {
        setProductsSelected(productsT => {
            productsT.map(productTT => {
                if (productTT === productSelected) {
                    productTT[prevVar] = newVar
                }
                return productTT
            })
            const updateProducts = [...productsT]
            return updateProducts
        })
    }


    const handleOperatorSelection = (operator) => {
        setOperatorSelected(operator)
        updateProducts('operator', operator.id)
    }

    const updatePrice = (newPrice) => {
        // console.log(newPrice)
        const intPrice = Number(newPrice)
        if (intPrice >= 0) {
            updateProducts('sell_price', intPrice)
        }
        else {
            updateProducts('sell_price', product.sell_price)
        }
    }

    const updateQuantity = (newQuantity) => {
        const intQuantity = Number(newQuantity)
        if (intQuantity > 0) {
            setQuantity(intQuantity)
            updateProducts('quantity', intQuantity)
        }
        else {
            setQuantity('')
            updateProducts('quantity', 1)
        }
    }

    const manageOperatorShown = () => { //If the services are not initialized from the appointments set it to '---'
        if (productSelected.operator !== '---') {
            return operators.filter((operator) => productSelected.operator === operator.id)[0].first_name
        }
        else {
            return '---'
        }
    }

    

    return (
        <>
            <Row className="p-2 ps-3 pe-3 align-items-center" style={{ fontSize: "15px", margin: "0px" }}>
                <Col lg="9"> {productSelected.name}
                </Col>
                <Col className="d-flex justify-content-end align-items-center">
                    <IconButton size='medium' onClick={() => { removeProduct() }}>
                        <AiOutlineDelete color="tomato" />
                    </IconButton>
                </Col>
            </Row>
            <Row className="d-flex flex-row p-3" style={{ backgroundColor: "whitesmoke", margin: "0px", borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px" }} >
                <div className="d-flex flex-row align-items-center mb-3 " >
                    <div className='text-secondary'>QUANTITA'</div>
                    <div className="ps-3 pe-3 flex-fill" style={{ width: "35%" }} >
                        <InputGroup >
                            <InputGroup.Text variant="light bg-white" style={{ borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px", border: "0px" }}>N.</InputGroup.Text>
                            <FormControl value={quantity} onChange={e => updateQuantity(e.target.value)} variant='light bg-white' style={{ borderTopRightRadius: "20px", borderBottomRightRadius: "20px", border: "0px", color: "black", textAlign: "end" }} placeholder={productSelected.quantity} />
                        </InputGroup>
                    </div>
                    <div className='text-secondary ' style={{ width: "35%" }}>
                        <InputGroup >
                            <InputGroup.Text variant="light bg-white" style={{ borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px", border: "0px" }}>€</InputGroup.Text>
                            <FormControl type="number"
                                value={String(productSelected.sell_price)} onChange={e => updatePrice(e.target.value)}
                                variant='light bg-white'
                                style={{ borderTopRightRadius: "20px", borderBottomRightRadius: "20px", border: "0px", color: "black" }}
                                placeholder={product.sell_price} />
                        </InputGroup>

                    </div>
                </div>
                <div className="d-flex flex-row align-items-center" >
                    <div className='text-secondary me-4'>VENDUTO DA</div>
                    <div className='flex-fill' style={{ width: "0px" }}>
                        <Dropdown  >
                            <Dropdown.Toggle variant='light bg-white' className="rounded-pill" style={{ width: "100%", }} id="dropwn-basic" >
                                <span style={{ color: "black" }} >{manageOperatorShown()} </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {operators.map((operator) => (
                                    <Dropdown.Item
                                        key={operator.id}
                                        onClick={() => { handleOperatorSelection(operator) }}>
                                        {operator.first_name}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </Row>

        </>

        // style={{width: "25px"}}


    );

}