import React, { useEffect, useState } from "react"
import SpecialDays from "./SpecialDays"
import { useDashboard } from "../../../../contexts/DashboardProvider"
import { useAuthentication } from "../../../../contexts/AuthenticationProvider"
import { hasPermission, ROLES } from "../../../../helper"
import WorkingDays from "./WorkingDays"
import PermissionGate from "../../../permissions/PermissionGate"

export function NavigationBar({ titles, className, isSelected, showArtifact = true, onClick = () => {} }) {
    return (
        <div className={`relative flex flex-row gap-2 bg-white rounded-xl py-2 items-center px-3 ${className}`}>
            {titles.map((title, idx) => {
                return (
                    <button
                        key={"tab_" + title}
                        onClick={() => {
                            onClick(idx)
                        }}
                        className={`flex py-2 px-3 rounded-xl font-medium tracking-tight z-10 ${isSelected(idx) ? "bg-primary/20 text-primary" : "bg-secondary/20 text-secondary"}`}
                    >
                        {title}
                    </button>
                )
            })}
            {showArtifact && <div className="absolute rounded-bl-xl w-6 h-6  bg-transparent right-[-24px] bottom-[-0px]" style={{ boxShadow: "-10px 0 0 0 #ffffff" }} />}
        </div>
    )
}

export default function EasyOperators() {
    const { user } = useAuthentication()
    const { selectedOperator, setSelectedOperator } = useDashboard()
    const [selectedTabIdx, setSelectedTabIdx] = useState(0)

    const tabsData = [
        { title: "Lavorativi", component: <WorkingDays /> },
        { title: "Speciali", component: <SpecialDays /> },
    ]

    useEffect(() => {
        if (hasPermission(user, [ROLES.hairdresser])) {
            setSelectedOperator(user)
        }
    }, [])

    if (selectedOperator === "not-selected") {
        return (
            <div className="d-flex justify-content-center " style={{ fontSize: "48px", paddingTop: "300px", color: "lightgray" }}>
                Seleziona operatore
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-4 overflow-visible">
            <PermissionGate permissions={[ROLES.owner, ROLES.manager]}>
                <NavigationBar
                    titles={tabsData.map((x) => x.title)}
                    isSelected={(idx) => idx === selectedTabIdx}
                    onClick={(idx) => {
                        setSelectedTabIdx(idx)
                    }}
                    className="shadow-md"
                />
                {tabsData[selectedTabIdx].component}
            </PermissionGate>
            <PermissionGate permissions={[ROLES.hairdresser]}>
                <SpecialDays />
            </PermissionGate>
        </div>
    )
}
