// import React from 'react';
// import ReactDOM from 'react-dom';
// import App from './components/App';

// import 'bootstrap/dist/css/bootstrap.min.css'
// import './index.css'
// // import reportWebVitals from './reportWebVitals';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />\
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// // reportWebVitals();

import "./instruments.js"
import { createRoot } from "react-dom/client"
import App from "./components/App"
import "bootstrap/dist/css/bootstrap.min.css"
import "./index.css"

const container = document.getElementById("root")
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(<App tab="home" />)
