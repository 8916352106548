import React from "react"
import { useEffect, useState, useRef } from "react"
import { FormControl, InputGroup, Col, Row, Spinner, Dropdown } from "react-bootstrap"
import { BsCircle, BsCheckCircle } from "react-icons/bs"
import { TiMinus } from "react-icons/ti"
import { VscCheck, VscCheckAll } from "react-icons/vsc"
import { CgClose } from "react-icons/cg"
import { getData, urls, roundToTwo, retrieveIssuedValidGiftCards } from "../../../../helper"
import { useDashboard } from "../../../../contexts/DashboardProvider"
import { MdSchool } from "react-icons/md"
import { IconButton } from "@mui/material"

export default function Total({
    productsSelected,
    servicesSelected,
    giftCardsSelected,
    total,
    setTotal,
    paymentOptions,
    setPaymentOptions,
    defaultPaymentOptions,
    giftCardRedeem,
    setGiftCardRedeem,
    defaultGiftCardRedeem,
    globalDiscount,
    setGlobalDiscount,
    operators,
}) {
    const { colorTheme } = useDashboard()
    const [giftCardLeftOver, setGiftCardLeftOver] = useState(0)
    const [operatorSelected, setOperatorSelected] = useState({ id: "", name: "---" })

    const computeTotal = () => {
        let totalServices = 0
        let individualDiscountServices = 0
        let totalProducts = 0
        let totalGiftCards = 0
        //console.log(servicesSelected)
        if (servicesSelected.length > 0) {
            totalServices = servicesSelected.reduce((total, serviceT) => {
                // return total + (serviceT.sell_price * (100 - serviceT.discount) / 100) //Fix this line in such way that sospeso is variable
                return total + serviceT.sell_price
            }, 0)
            individualDiscountServices = servicesSelected.reduce((total, serviceT) => {
                return total + (serviceT.sell_price * serviceT.discount) / 100
            }, 0)
        }
        if (productsSelected.length > 0) {
            totalProducts = productsSelected.reduce((total, productT) => {
                return total + productT.sell_price * productT.quantity
            }, 0)
        }

        if (giftCardsSelected.length > 0) {
            totalGiftCards = giftCardsSelected.reduce((total, giftCardT) => {
                return total + giftCardT.sell_price
            }, 0)
        }

        // console.log(giftCardRedeem)
        const totalBruto = totalServices + totalProducts + totalGiftCards
        let totalNet = (Number(totalBruto - individualDiscountServices) * (100 - Number(globalDiscount))) / 100 - giftCardRedeem.residual_amount

        setGiftCardLeftOver(totalNet)
        if (totalNet < 0) totalNet = 0

        setPaymentOptions(defaultPaymentOptions) //Enable to prevent weird states
        return [
            { name: "Bruto", amount: roundToTwo(totalBruto) },
            { name: "Net", amount: roundToTwo(totalNet) },
        ]
    }

    useEffect(() => {
        setTotal(computeTotal())
    }, [servicesSelected, productsSelected, globalDiscount, giftCardsSelected, giftCardRedeem])

    const updateDiscount = (newDiscount) => {
        const intDiscount = Number(newDiscount)
        if (intDiscount >= 0 && intDiscount <= 100) {
            setGlobalDiscount(intDiscount)
        } else if (intDiscount > 100) {
            setGlobalDiscount("100")
        } else if (intDiscount < 0) {
            setGlobalDiscount("")
        }
    }

    const updatePaymentOptions = (prevVar, newVar, paymentSelected) => {
        setPaymentOptions((paymentsT) => {
            paymentsT.map((paymentTT) => {
                if (paymentTT === paymentSelected) {
                    paymentTT[prevVar] = newVar
                }
                return paymentTT
            })
            const updatePayments = [...paymentsT]
            return updatePayments
        })
    }

    const handleGiftCardUpdate = (key, value) => {
        //Use this syntax to update dictionaries
        if (key === "code") {
            if (value.length > 5) {
                return
            }
        }
        setGiftCardRedeem((prev) => {
            return { ...prev, [key]: value }
        })
    }

    const verifyGiftCard = async () => {
        if (giftCardRedeem.status === "toVerify" || giftCardRedeem.status === "invalid") {
            handleGiftCardUpdate("status", "checking")
            const response = await getData(urls.retrieveIssuedValidGiftCards + giftCardRedeem.code + "/")
            // console.log(response)
            if (response.id !== undefined) {
                handleGiftCardUpdate("id", response.id)
                handleGiftCardUpdate("residual_amount", response.residual_amount)
                handleGiftCardUpdate("status", "valid")
            } else {
                handleGiftCardUpdate("status", "invalid")
            }
        }
    }

    useEffect(() => {
        if (giftCardRedeem.code.length === 5) {
            // Improve by using a useRef that check if the prev value is equal to the current one, in order to fix endless loop that it happens now if it is not cleaned the code
            verifyGiftCard()
        }
    }, [giftCardRedeem.code])

    const handleOperatorSelection = (operator) => {
        setOperatorSelected(operator)
        handleGiftCardUpdate("operator", operator.id)
    }

    const manageOperatorShown = () => {
        //If the services are not initialized from the appointments set it to '---'
        if (giftCardRedeem.operator !== "---") {
            return operators.filter((operator) => giftCardRedeem.operator === operator.id)[0].first_name
        } else {
            return "---"
        }
    }

    //It refreshes twice per change due to discount and copuon, impacting the Net and Bruto
    return (
        <>
            {/* {console.log(selectedAppointment)} */}
            <Row className="p-4" style={{ fontSize: "15px", margin: "0px" }}>
                <Col xs={7}> Totale Parziale</Col>
                <Col className="d-flex justify-content-end">€ {roundToTwo(total[0].amount).toFixed(2)}</Col>
            </Row>
            <Row className="d-flex flex-row p-4" style={{ backgroundColor: "whitesmoke", margin: "0px" }}>
                <div className="text-secondary mb-3 ms-2">SCONTO SUL TOTALE</div>
                <div className="d-flex flex-row">
                    <InputGroup className="me-2 ">
                        <InputGroup.Text style={{ borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px", border: "0px" }}>%</InputGroup.Text>
                        <FormControl
                            value={globalDiscount}
                            onChange={(e) => updateDiscount(e.target.value)}
                            variant="secondary"
                            style={{ borderTopRightRadius: "20px", borderBottomRightRadius: "20px", border: "0px" }}
                            placeholder="0"
                        />
                    </InputGroup>
                    <IconButton
                        onClick={() => {
                            globalDiscount === 10 ? updateDiscount(0) : updateDiscount(10)
                        }}
                    >
                        <MdSchool color={globalDiscount !== 10 ? "gray" : colorTheme.primaryColor} />
                    </IconButton>
                </div>

                <div className="mt-1">
                    <hr className="mt-4 mb-4 bg-dark border-2 border-top border-secondary"></hr>
                </div>
                <div className="d-flex flex-row mb-2">
                    <div className="d-flex flex-fill text-secondary ps-2">CODICE SCONTO</div>
                    <div
                        className="d-flex flex-fill justify-content-end"
                        style={{ fontSize: "15px", color: giftCardRedeem.selected ? "gray" : colorTheme.primaryColor }}
                        onClick={() => {
                            giftCardRedeem.selected || handleGiftCardUpdate("selected", true)
                        }}
                    >
                        {" "}
                        Aggiungi
                    </div>
                </div>

                {giftCardRedeem.selected && (
                    <>
                        <div className="d-flex flex-row mb-2 align-items-center">
                            <div>
                                <InputGroup>
                                    <FormControl
                                        value={giftCardRedeem.code}
                                        disabled={giftCardRedeem.status === "checking" || giftCardRedeem.status === "valid"}
                                        onChange={(e) => {
                                            handleGiftCardUpdate("code", e.target.value.toUpperCase())
                                        }}
                                        variant="secondary"
                                        style={{ borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px", border: "0px", color: "black", width: "50%", fontSize: "13px" }}
                                        placeholder="Inserisci codice"
                                    />
                                    <InputGroup.Text style={{ borderTopRightRadius: "20px", borderBottomRightRadius: "20px", border: "2px", width: "75px" }}>
                                        € {giftCardRedeem.residual_amount}
                                    </InputGroup.Text>
                                </InputGroup>
                            </div>
                            <div className="p-2 flex-fill" onClick={() => verifyGiftCard(giftCardRedeem)}>
                                {giftCardRedeem.status === "toVerify" && <VscCheck size={20} style={{ width: "25px" }} color={"transparent"} />}
                                {giftCardRedeem.status === "checking" && <Spinner animation="border" size="sm" />}
                                {giftCardRedeem.status === "valid" && <VscCheckAll size={20} color={"dodgerblue"} style={{ width: "25px" }} />}
                                {giftCardRedeem.status === "invalid" && <CgClose color="red" style={{ width: "25px" }} />}
                            </div>
                            <div>
                                {" "}
                                <TiMinus
                                    color="gray"
                                    size={22}
                                    onClick={() => {
                                        handleGiftCardUpdate("status", "toVerify")
                                        setGiftCardRedeem(defaultGiftCardRedeem)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-row align-items-center">
                            <div className="flex-fill" style={{ width: "0px" }}>
                                <Dropdown>
                                    <Dropdown.Toggle variant="light bg-white" className="rounded-pill" style={{ width: "100%", fontSize: "1" }} id="dropwn-basic">
                                        <span style={{ color: "black" }}>{manageOperatorShown()} </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {operators.map((operator) => (
                                            <Dropdown.Item
                                                key={operator.id}
                                                onClick={() => {
                                                    handleOperatorSelection(operator)
                                                }}
                                            >
                                                {operator.first_name}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>

                        {giftCardLeftOver < 0 && (
                            <div className="d-flex flex-row mt-2 " style={{ fontSize: "12px", color: colorTheme.primaryColor}}>
                                <div className="d-flex flex-fill ps-2" >SCONTO RIMANENTE </div>
                                <div className="d-flex flex-fill justify-content-end" style={{ fontSize: "14px" }}>
                                    {" "}
                                    € {roundToTwo(-1 * giftCardLeftOver).toFixed(2)}
                                </div>
                            </div>
                        )}
                    </>
                )}

                <div className="mt-1">
                    <hr className="mt-4 mb-4 bg-dark border-2 border-top border-secondary"></hr>
                </div>
                <div className="d-flex flex-row">
                    <div className="d-flex flex-fill text-secondary ps-2">SOSPESO</div>
                    <div className="d-flex flex-fill text-secondary justify-content-end" style={{ fontSize: "16px" }}>
                        {" "}
                        € {roundToTwo(total[0].amount - total[1].amount).toFixed(2)}
                    </div>
                </div>
            </Row>

            <Row className="d-flex p-4 justify-content-between" style={{ margin: "0px" }}>
                <div className="d-flex flex-row justify-content-between  align-items-center">
                    <div style={{ fontSize: "15px" }}>Totale netto</div>
                    <div style={{ fontSize: "22px" }}>€ {roundToTwo(total[1].amount).toFixed(2)}</div>
                </div>
                <div className="mt-1">
                    <hr className="mt-4 mb-4 bg-dark border-2 border-top border-secondary"></hr>
                </div>

                {paymentOptions.map((paymentT, index) => {
                    return (
                        <div key={index} className="d-flex flex-row justify-content-between align-items-center mb-3 ">
                            <div className=" text-secondary" style={{ margin: "0px" }}>
                                {paymentT.name}
                            </div>
                            <div className="me-3 ms-auto" style={{ width: "45%" }}>
                                <InputGroup>
                                    <FormControl
                                        value={paymentT.amount}
                                        type="number"
                                        disabled={!paymentT.selected}
                                        onChange={
                                            (e) => {
                                                //selecting the opposite
                                                updatePaymentOptions("amount", roundToTwo(e.target.value), paymentT)
                                                updatePaymentOptions("amount", roundToTwo(total[1].amount - e.target.value), paymentOptions[1 - index])
                                                Number(total[1].amount - e.target.value) === 0
                                                    ? updatePaymentOptions("selected", false, paymentOptions[1 - index])
                                                    : updatePaymentOptions("selected", true, paymentOptions[1 - index])
                                            } //Flipping 1 and 0 in order to have the game always filling values
                                        }
                                        variant="secondary"
                                        style={{ borderRadius: "20px", textAlign: "end" }}
                                        placeholder="0"
                                    />
                                </InputGroup>
                            </div>
                            <div>
                                {paymentT.selected ? (
                                    <BsCheckCircle
                                        onClick={() => {
                                            updatePaymentOptions("selected", !paymentT.selected, paymentT)
                                            updatePaymentOptions("amount", 0, paymentT)
                                            paymentOptions[1 - index].selected && updatePaymentOptions("amount", total[1].amount - 0, paymentOptions[1 - index]) //mechanics for oscillating amounts
                                        }}
                                        size={25}
                                        color={colorTheme.primaryColor}
                                    />
                                ) : (
                                    <BsCircle
                                        onClick={() => {
                                            updatePaymentOptions("selected", !paymentT.selected, paymentT)
                                            updatePaymentOptions("amount", roundToTwo(total[1].amount - Number(paymentOptions[1 - index].amount)), paymentT)
                                        }}
                                        size={25}
                                        color={colorTheme.primaryColor}
                                    />
                                )}
                            </div>
                        </div>
                    )
                })}
            </Row>
        </>
    )
}
