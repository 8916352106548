import { borderColor } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react'
import { useDashboard } from '../../../../contexts/DashboardProvider';
import { FiShoppingCart } from 'react-icons/fi'
import { BsCashStack, BsFillPersonFill, BsUpcScan } from 'react-icons/bs'
import { MdCardGiftcard } from 'react-icons/md';


export default function InventoryStats({ statsList }) {
    const { colorTheme } = useDashboard()

    return (
        <div>
            {/* {console.log(statsList)} */}
            <div className='d-flex card shadow-sm mb-3 p-4' style={{ borderRadius: "15px" }}>

                <div className='d-flex flex-row align-items-center'>
                    <div>
                        <FiShoppingCart size={22} color={colorTheme.primaryColor} />
                    </div>
                    <div className='ms-3'>
                        <div className='mt-2 ms-3' style={{ fontSize: "20px", fontWeight: '600', letterSpacing: '0.5px', color: colorTheme.primaryColor }}>
                            €{statsList.inventory.sellableProductsValue.toFixed(2)}

                        </div>
                        <div className='d-flex text-secondary ms-3'
                            style={{ fontSize: '15px' }}
                        >
                            Prodotti vendibili
                        </div>
                    </div>
                </div>

            </div>
            <div className='d-flex card shadow-sm mb-3 p-4' style={{ borderRadius: "15px" }}>
                <div className='d-flex flex-row align-items-center'>
                    <div>
                        <FiShoppingCart size={22} color={'grey'} />
                    </div>
                    <div className='ms-3'>
                        <div className='mt-2 ms-3' style={{ fontSize: "20px", fontWeight: '600', letterSpacing: '0.5px', color: colorTheme.primaryColor }}>
                            €{statsList.inventory.nonSellableProductsValue.toFixed(2)}
                        </div>
                        <div className='d-flex text-secondary ms-3'
                            style={{ fontSize: '15px' }}
                        >
                            Prodotti non vendibili
                        </div>
                    </div>
                </div>
            </div>

            <div className='d-flex card shadow-sm mb-3 p-4' style={{ borderRadius: "15px" }}>


                <div className='d-flex flex-row align-items-center'>
                    <div>
                        <BsUpcScan size={22} color={colorTheme.primaryColor} />
                    </div>
                    <div className='ms-3'>
                        <div className='mt-2 ms-3' style={{ fontSize: "20px", fontWeight: '600', letterSpacing: '0.5px', color: colorTheme.primaryColor }}>
                            €{statsList.inventory.allProductsValue.toFixed(2)}
                        </div>
                        <div className='d-flex text-secondary ms-3'
                            style={{ fontSize: '15px' }}
                        >
                            Tutti i prodotti <br></br> (prezzo acquisto)
                        </div>
                    </div>
                </div>



            </div>


            <div className='d-flex card shadow-sm mb-3 p-4' style={{ borderRadius: "15px" }}>
                <div className='d-flex flex-row align-items-center'>
                    <div>
                        <MdCardGiftcard size={22} color={colorTheme.primaryColor} />
                    </div>
                    <div className='ms-3'>
                        <div className='mt-2 ms-3' style={{ fontSize: "20px", fontWeight: '600', letterSpacing: '0.5px', color: colorTheme.primaryColor }}>
                            {statsList.inventory.giftCardIssuedNumber}
                        </div>
                        <div className='d-flex text-secondary ms-3'
                            style={{ fontSize: '15px' }}
                        >
                            Carte regalo in circolazione
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )

}