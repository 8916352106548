import React, { useState } from 'react'
import { FiKey } from 'react-icons/fi'
import { MdCheck } from 'react-icons/md'
import { Button, InputGroup, FormControl, Alert } from "react-bootstrap"
import { useParams, useNavigate } from 'react-router-dom';
import { sendData, urls } from '../../helper';
import { useAuthentication } from '../../contexts/AuthenticationProvider';


export default function NewPassword() {
    const { id, token } = useParams()
    const { logIn } = useAuthentication()
    const defaultAlertBox = { mode: "", message: "", enabled: false }
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isPasswordSet, setIsPasswordSet] = useState(false)
    const [alertBox, setAlertBox] = useState(defaultAlertBox)
    const navigate = useNavigate()



    const handleSendNewPassword = async () => {
        setAlertBox(defaultAlertBox)
        // const regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})')
        // if (regex.test(password)) {

        const content = {
            id: id,
            token: token,
            password: password
        }

        const response = await sendData(urls.setNewPassword, content)
        // console.log(response)
        if (response.type === 'error') {
            setAlertBox({ mode: "danger", message: response.msg, enabled: true })

        }
        else if (response.type === 'success') {
            setEmail(response.msg.email)
            setIsPasswordSet(true)
        }
    }
    // else {
    //     setAlertBox({ mode: "danger", message: 'La password non è abbastanza sicura', enabled: true })
    // console.log('not-accepted')
    // }
    // }

    const handleLogin = async () => {
        const authResponse = await logIn(email, password)
        if (authResponse.type === 'error') {
            setAlertBox({ mode: 'danger', message: authResponse.msg, enabled: true })
        }
        else {
            navigate('/dashboard')
        }
    }
    // console.log(uid, token)

    return (

        // <div className='d-flex flex-fill justify-content-center' style={{ heigth: '100vh' }}>
        <div className='d-flex flex-column ' style={{ paddingTop: '12vh' }}>
            <div className='d-flex justify-content-center' >
                {isPasswordSet ?
                    <MdCheck className='d-flex' color='dodgerblue' size={65} style={{ backgroundColor: 'aliceblue', borderRadius: '100px', padding: '15px' }} />
                    :
                    <FiKey className='d-flex' color='dodgerblue' size={65} style={{ backgroundColor: 'aliceblue', borderRadius: '100px', padding: '15px' }} />
                }
            </div>
            <div className='d-flex justify-content-center pt-4' style={{ fontSize: 32, fontWeight: '600' }}>
                {isPasswordSet ?
                    'Password aggiornata'
                    :
                    'Imposta nuova password'
                }
            </div>
            <div className='d-flex justify-content-center pt-3 mb-3' style={{ fontSize: 18, textAlign: 'center', color: 'dimgray' }}>
                {isPasswordSet ?
                    <div>
                        La tua password è stata impostata correttamenta.
                        <br></br>
                        Clicca sotto per accedere al tuo profilo
                    </div>
                    :
                    <div>
                        Scegli la tua nuova password con cui utilizzare
                        <br></br>
                        il tuo account LDN Hair Salon
                    </div>
                }
            </div>

            {isPasswordSet || <div className='d-flex justify-content-center pt-3 mt-2 align-items-center'>
                <InputGroup style={{ width: '350px' }}>
                    <FormControl
                        variant='secondary'
                        style={{ borderRadius: "20px", width: '350px', height: '50px' }}
                        type='password'
                        placeholder={'Password'}
                        value={password}
                        onChange={e => { setPassword(e.target.value) }}
                    />
                </InputGroup>
            </div>}

            <div className='d-flex justify-content-center pt-3 mt-3'>
                {isPasswordSet ?
                    <Button
                        variant="primary"
                        style={{ borderRadius: "15px", width: '350px', fontSize: '16px', height: '45px' }}
                        size="lg"
                        onClick={() => { handleLogin() }}
                    >
                        Continua
                    </Button>
                    :
                    <Button
                        variant={'primary'}
                        disabled={password === ''}
                        style={{ borderRadius: "15px", width: '350px', fontSize: '16px', height: '45px' }}
                        size="lg"
                        onClick={() => { handleSendNewPassword() }}
                    >
                        Imposta password
                    </Button>
                }

            </div>


            <div className='d-flex justify-content-center mt-2'>
                {alertBox.enabled && <Alert
                    className='text-center'
                    variant={alertBox.mode}
                    style={{ width: '350px' }}

                >
                    {alertBox.message}
                </Alert>}

            </div>



        </div >
    )
}