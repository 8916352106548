import { IconButton } from "@mui/material"
import React, { useEffect, useState } from "react"
import { FormControl, InputGroup, ListGroup } from "react-bootstrap"
import { BsFilterLeft } from "react-icons/bs"
import { useDashboard } from "../../../../contexts/DashboardProvider"
import { useDatabase } from "../../../../contexts/DatabaseProvider"
import { getDataTime } from "../../../../helper"

import { BiEditAlt, BiSpreadsheet } from "react-icons/bi"
import NewCustomer from "../../main-panel/easy-register/NewCustomer"

export default function CustomersList() {
    const { appointments, draftAppointments, billings } = useDatabase()
    const { selectedAppointment, setSelectedAppointment, colorTheme } = useDashboard()
    const [sortedAppointments, setSortedAppointments] = useState([])
    const [filterState, setFilterState] = useState(0) //States ['all','sellable','non-sellable']
    const [searchCustomer, setSearchedCustomer] = useState("")

    // console.log(appointments)

    const sortByTime = (a, b) => {
        return new Date(a.appointments[0].date) - new Date(b.appointments[0].date)
    }

    useEffect(() => {
        const appointmentsT = [...appointments]
        switch (filterState) {
            case 0:
                setSortedAppointments(appointmentsT.sort((a, b) => sortByTime(a, b)))
                break

            case 1:
                setSortedAppointments(appointmentsT.sort((a, b) => sortByTime(a, b)).filter((appointment) => isBilled(appointment)))
                break
            case 2:
                setSortedAppointments(appointmentsT.sort((a, b) => sortByTime(a, b)).filter((appointment) => !isBilled(appointment)))
                break
            default:
        }
    }, [filterState, appointments])

    const filterCustomers = () => {
        if (filterState === 2) {
            setFilterState(0)
        } else {
            setFilterState(filterState + 1)
        }
    }
    // console.log(selectedAppointment)

    const isBilled = (appointment) => {
        return billings.some((billing) => billing.smartsale.customer === appointment.customer.id)
    }

    const IsNotInAppointments = () => {
        // const isInAppointments = appointments.some((x) => x.customer.id === selectedAppointment.customer.id)
        // if (isInAppointments || selectedAppointment.customer.phone === "+390000000000") return
        // else
        if (selectedAppointment.manual === true) {
            return (
                <ListGroup.Item key={selectedAppointment.customer.id} active={true} style={{ paddingLeft: "35px", paddingRight: "35px", backgroundColor: colorTheme.primaryColor, fontSize: "18px" }}>
                    <div className="d-flex flex-row justify-content-between align-items-end">
                        <div className="flex-fill">{selectedAppointment.customer.full_name}</div>
                    </div>
                </ListGroup.Item>
            )
        }
    }

    const iconManager = (appointment, selected) => {
        // console.log(appointment)
        if (isBilled(appointment)) {
            return <BiSpreadsheet color={selected ? "white" : colorTheme.primaryColor} />
        } else {
            if (appointment.customer.edited) return <BiEditAlt color={selected ? "white" : colorTheme.primaryColor} />
            else return <BiSpreadsheet color={"transparent"} />
        }
        // return isBilled(appointment) ? <BiSpreadsheet color={selected ? "white" : colorTheme.primaryColor} /> : <BiSpreadsheet color={selected ? colorTheme.primaryColor : "white"} />
    }

    return (
        <div className="d-flex flex-column" style={{ height: "88vh" }}>
            <div className="d-flex flex-row align-items-center p-3 ps-4 pe-4" style={{ backgroundColor: colorTheme.thirdColor }}>
                <div>
                    <NewCustomer setCustomersSelected={setSelectedAppointment} />
                </div>
                <InputGroup className="ms-2">
                    <FormControl
                        value={searchCustomer}
                        onChange={(e) => setSearchedCustomer(e.target.value)}
                        variant="secondary"
                        style={{ borderRadius: "20px", border: "0px" }}
                        placeholder="Nome cliente"
                    />
                </InputGroup>

                <div className="ms-2">
                    <IconButton onClick={() => filterCustomers()}>
                        <BsFilterLeft />
                    </IconButton>
                </div>
            </div>

            <div className="overflow-auto">
                <ListGroup variant="flush" style={{ fontSize: "18px" }}>
                    {/* appointment are used in order to not have errors on the date, selectedAppointment is obtained from the draftAppointments */}
                    <IsNotInAppointments />
                    {sortedAppointments.map((appointment) => {
                        // console.log(appointment)
                        if (String(appointment.customer["full_name"]).toLowerCase().includes(searchCustomer.toLowerCase())) {
                            const selected = selectedAppointment.customer.id === appointment.customer.id && selectedAppointment.manual !== true
                            const draftAppointment = draftAppointments.find((dAppT) => {
                                return dAppT.customer.id === appointment.customer.id
                            })
                            {
                                /* This undefined check is added so not to have the app crashing if an appointment has been edited and then deleted from the calendar */
                            }
                            if (draftAppointment === undefined) return
                            return (
                                <ListGroup.Item
                                    action
                                    key={appointment.customer.id}
                                    onClick={() => {
                                        setSelectedAppointment(draftAppointments.find((appT) => appT.customer.id === appointment.customer.id))
                                    }}
                                    active={selected}
                                    style={{ paddingLeft: "35px", paddingRight: "35px", backgroundColor: selected && colorTheme.primaryColor }}
                                >
                                    <div className="d-flex flex-row justify-content-between align-items-end">
                                        <div className="flex-fill text-base">{appointment.customer.full_name}</div>
                                        <div className="me-3" style={{ fontSize: "15px" }}>
                                            {getDataTime(appointment.appointments[0].date).split("-")[1].slice(0, 5)}
                                        </div>
                                        <div>{iconManager(draftAppointment, selected)}</div>
                                    </div>
                                </ListGroup.Item>
                            )
                        }
                        return null
                    })}
                </ListGroup>
            </div>
        </div>
    )
}
