import React, { useState, useEffect } from "react"
import { ListGroup, FormControl, InputGroup } from "react-bootstrap"
import { useDatabase } from "../../../../contexts/DatabaseProvider"
import { useDashboard } from "../../../../contexts/DashboardProvider"
import { getDataTime } from "../../../../helper"
import { MdSend } from "react-icons/md"
import { BsFilterLeft } from "react-icons/bs"
import { IconButton } from "@mui/material"
import { CgFileDocument } from "react-icons/cg"
import { IoMdWarning } from "react-icons/io"

export default function BillingsList() {
    const { billings } = useDatabase()
    const { selectedBilling, setSelectedBilling, colorTheme } = useDashboard()
    const [filterState, setFilterState] = useState(0)
    const [searchBilling, setSearchBilling] = useState("")
    const [sortedBillings, setSortedBillings] = useState([])
    const [filteredBillings, setFilteredBillings] = useState([])

    useEffect(() => {
        const billingsT = [...billings]
        switch (filterState) {
            case 0: //all
                setSortedBillings(billingsT)
                break
            case 1: //sent
                setSortedBillings(billingsT.filter((billingT) => billingT.smartsale.receipt === true))
                break
            case 2: //not-sent
                setSortedBillings(billingsT.filter((billingT) => billingT.smartsale.receipt === false))
                break
            case 3: //to be dispatched
                setSortedBillings(billingsT.filter((billingT) => billingT.smartsale.dispatched === false))
                break
            default:
        }
    }, [filterState, billings])

    useEffect(() => {
        const sortedBillingsT = [...sortedBillings]
        // console.log(sortedBillingsT)
        const filteredBillings = sortedBillingsT.filter((sortedBilling) => {
            return String(sortedBilling.customer).toLowerCase().includes(searchBilling.toLowerCase())
        })

        setFilteredBillings(filteredBillings)

        if (filteredBillings.length === 1) {
            setSelectedBilling(filteredBillings[0])
        }
    }, [searchBilling, sortedBillings])

    const filterBilling = () => {
        if (filterState === 3) {
            setFilterState(0)
        } else {
            setFilterState(filterState + 1)
        }
    }

    const manageIconShow = (smartsale, selected) => {
        if (!smartsale.dispatched)
            return (
                <div>
                    <MdSend color={selected ? "white"  : "gray"} />
                </div>
            )

        return <div>{smartsale.receipt ? <MdSend color={selected ? "white" : colorTheme.primaryColor} /> : <MdSend color={selected ? colorTheme.primaryColor : "transparent"} />}</div>
    }

    return (
        <div className="d-flex flex-column" style={{ height: "88vh" }}>
            <div className="d-flex flex-row align-items-center p-3 ps-4 pe-4" style={{ backgroundColor: colorTheme.thirdColor }}>
                <div>
                    <IconButton
                        onClick={() => {
                            setSelectedBilling("daily-summary")
                        }}
                    >
                        <CgFileDocument color={colorTheme.primaryColor} />
                    </IconButton>
                </div>
                <InputGroup className="ms-2">
                    <FormControl
                        value={searchBilling}
                        onChange={(e) => {
                            setSearchBilling(e.target.value)
                        }}
                        variant="secondary"
                        style={{ borderRadius: "20px", border: "0px" }}
                        placeholder="Nome cliente"
                    />
                </InputGroup>

                <div className="ms-2">
                    <IconButton
                        onClick={() => {
                            filterBilling()
                        }}
                    >
                        <BsFilterLeft />
                    </IconButton>
                </div>
            </div>

            <div className="overflow-auto">
                <ListGroup className="d-flex flex-fill" variant="flush" style={{ fontSize: "18px" }}>
                    {filteredBillings.map((billing) => {
                        const selected = selectedBilling === billing
                        return (
                            <ListGroup.Item
                                action
                                key={billing.smartsale.id}
                                onClick={() => {
                                    setSelectedBilling(billing)
                                }}
                                active={selected}
                                style={{ paddingLeft: "35px", paddingRight: "35px", backgroundColor: selected && colorTheme.primaryColor }}
                            >
                                <div className="d-flex flex-row justify-content-between align-items-center">
                                    <div className="flex flex-fill text-base">{billing.customer}</div>
                                    <div className="flex me-3 text-base" >
                                        {getDataTime(billing.smartsale.date).split("-")[1].slice(0, 5)}
                                    </div>
                                    {manageIconShow(billing.smartsale, selected)}
                                </div>
                            </ListGroup.Item>
                        )
                    })}
                </ListGroup>
            </div>
        </div>
    )
}
