import React, { useEffect, useMemo, useRef, useState } from "react"

import useComponentVisible from "../easy-calendar/components/useComponentVisible"
import DateTimePicker from "../easy-calendar/components/DateTimePicker"
import { DateTime } from "luxon"
import { formatRedableDate, generateMonthlyDates, generateTimeSlots, handleStartEndSelection } from "../easy-calendar/utils/helpers"
import { AiFillCheckCircle, AiFillCloseCircle, AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai"
import { MaterialUISwitch, ROLES, defaultAlertBox, getData, hasPermission, patchData, range, sendData, urls } from "../../../../helper"
import { Alert, Button, ButtonGroup, Dropdown, DropdownButton, Spinner } from "react-bootstrap"
import { useDashboard } from "../../../../contexts/DashboardProvider"
import PermissionGate from "../../../permissions/PermissionGate"
import { useAuthentication } from "../../../../contexts/AuthenticationProvider"
import { useSalon } from "../../../../contexts/SalonProvider"
import { ExpandingSection as ExpandingSectionTime } from "../easy-calendar/elements/TimeSelector"
import DropdownMenu from "react-bootstrap/esm/DropdownMenu"
import { IoMdClock } from "react-icons/io"
import { MdOutlineLockClock } from "react-icons/md"
import { useDatabase } from "../../../../contexts/DatabaseProvider"

export function Card({ children, title, extraComponent }) {
    return (
        <div className="flex flex-col bg-white rounded-xl p-3 pb-4 shadow-md">
            <div className="flex flex-row justify-between items-center w-full mb-3">
                <div className="ms-1 text-lg font-medium tracking-tighter text-black/80">{title}</div>
                <div className="flex">{extraComponent}</div>
            </div>
            <div>{children}</div>
        </div>
    )
}

function HolidaysSickSwitch({ value, setValue }) {
    // console.log(value)
    return (
        <div className="flex flex-row gap-2 ">
            <button
                className={`flex items-center justify-center rounded-full bg-primary/80 text-white w-[20px] h-[20px]  ${value === "holidays" || "bg-secondary/80"}`}
                onClick={() => setValue("holidays")}
            >
                F
            </button>
            <button
                className={`flex items-center justify-center rounded-full bg-lime-600/80 text-white w-[20px] h-[20px] ${value === "sick_days" || "bg-secondary/80"}`}
                onClick={() => setValue("sick_days")}
            >
                M
            </button>
        </div>
    )
}

function LabelledHolder({ children, className, classNameLabel, label }) {
    return (
        <div className={"flex flex-col  " + className}>
            <div className={`!ms-3 text-xs mb-1 text-black/70 ${classNameLabel}`}>{label}</div>
            {children}
        </div>
    )
}

function LabelledButton({ text, classNameInput, label, className, onClick }) {
    return (
        <LabelledHolder label={label} className={className}>
            <div className="flex flex-row rounded-2xl text-sm outline outline-1 outline-slate-300">
                <button className={"flex h-10  items-center text-primary px-3 outline-0 " + classNameInput} onClick={onClick}>
                    <p className="capitalize">{text}</p>
                </button>
            </div>
        </LabelledHolder>
    )
}

export function LabelledSwitch({ label, onChange, checked }) {
    return (
        <LabelledHolder label={label} className="flex " classNameLabel="!ms-0">
            <div className="flex flex-row w-full h-10 items-center">
                <MaterialUISwitch checked={checked} onChange={onChange} />
            </div>
        </LabelledHolder>
    )
}

function ExpandingSection({ children, isComponentVisible, refSection, extraComponent, className }) {
    const expandingSectionRef = useRef(null)

    return (
        <div className="relative w-full z-10">
            {children}
            <div ref={refSection} className="relative">
                {isComponentVisible && (
                    <div ref={expandingSectionRef} className={`absolute left-2 mt-2 flex flex-col !p-4 rounded-md bg-white shadow-md ${className}`} style={{ width: "275px" }}>
                        {extraComponent}
                    </div>
                )}
            </div>
        </div>
    )
}

export function CalendarButton({ label, text, value, setValue, className }) {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
    const [calendarDate, setCalendarDate] = useState(value.startOf("month"))

    useEffect(() => {
        setCalendarDate(value.startOf("month"))
    }, [value])

    function Calendar() {
        return (
            <DateTimePicker
                calendarDate={calendarDate}
                setCalendarDate={setCalendarDate}
                availableTimeslots={generateMonthlyDates(calendarDate)} //Memoize this function if needed
                // isAvailableSelectionNumber={(dateDay) => true}
                // isSelectedSelectionNumber={(dateDay) => dateDay.dateday.toMillis() === value?.toMillis()}
                isAvailableSelectionNumber={(dateDay) => dateDay.dateday.startOf("day") >= DateTime.now().startOf("day")}
                isSelectedSelectionNumber={(dateDay) => dateDay.dateday.toMillis() === value?.toMillis() && dateDay.dateday.startOf("day") >= DateTime.now().startOf("day")}
                onClickSelectionNumber={setValue}
                timeslotMode={false}
            />
        )
    }

    return (
        <ExpandingSection refSection={ref} isComponentVisible={isComponentVisible} extraComponent={<Calendar />} className={className}>
            <LabelledButton
                label={label}
                text={text}
                onClick={() => {
                    setIsComponentVisible(true)
                }}
            />
        </ExpandingSection>
    )
}

export function TimePicker({ timeslots, isSelectedTimeslot, onClickTimeslot }) {
    const timeslotRefs = useRef([])

    useEffect(() => {
        // Find the index of the selected timeslot
        const selectedTimeslotIndex = timeslots.findIndex(isSelectedTimeslot)
        if (selectedTimeslotIndex !== -1) {
            // Scroll to the selected timeslot
            timeslotRefs.current[selectedTimeslotIndex]?.scrollIntoView({
                behavior: "instant",
                block: "center",
            })
        }
    }, [timeslots, isSelectedTimeslot])

    return (
        <div className="flex flex-col gap-2">
            {timeslots.map((timeslot, index) => (
                <button
                    key={timeslot.toISO()} // Ensure unique key for each timeslot
                    ref={(el) => (timeslotRefs.current[index] = el)} // Set the ref for each button
                    className={`hover:bg-primary/10 tracking-tighter p-2 rounded-xl text-primary/80 text-center ${isSelectedTimeslot(timeslot) ? "bg-primary text-white" : "bg-slate-100"}`}
                    onClick={(e) => {
                        onClickTimeslot(e, timeslot)
                    }}
                >
                    {timeslot.toLocaleString(DateTime.TIME_24_SIMPLE)}
                </button>
            ))}
        </div>
    )
}

export function TimeButton({ label, text, value, setValue }) {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)

    const timeslots = useMemo(() => generateTimeSlots(), [])

    function TimeSelector() {
        return (
            <TimePicker
                selectedTimeslot={value}
                setSelectedTimeslot={setValue}
                timeslots={timeslots}
                isSelectedTimeslot={(timeslot) => timeslot.toLocaleString(DateTime.TIME_24_SIMPLE) === value.toLocaleString(DateTime.TIME_24_SIMPLE)}
                onClickTimeslot={(e, timeslot) => {
                    //The propagation allows the closure of the component
                    e.stopPropagation()
                    setValue(timeslot)
                    setIsComponentVisible(false)
                }}
            />
        )
    }

    return (
        <ExpandingSectionTime refSection={ref} isComponentVisible={isComponentVisible} setIsComponentVisible={setIsComponentVisible} extraComponent={<TimeSelector />}>
            <LabelledButton label={label} text={text} />
        </ExpandingSectionTime>
    )
}

export function SaveButton({ children, onClick }) {
    const [isProcessing, setIsProcessing] = useState(false)
    // console.log(isProcessing)
    return (
        <button
            disabled={isProcessing}
            className={`flex h-10 w-full items-center justify-center rounded-full text-white text-lg bg-primary`}
            onClick={async () => {
                setIsProcessing(true)
                await onClick()
                setIsProcessing(false)
            }}
        >
            {isProcessing && <Spinner className="me-2" as="span" animation="border" size="sm" role="status" />}
            {children}
        </button>
    )
}

function AddDatesCard({ setAlertBox, operator, onClick }) {
    const [specialDayInfo, setSpecialDayInfo] = useState({ start_date: DateTime.now(), end_date: DateTime.now(), type: "holidays" })

    function setSpecialDayInfoNormal(type, value) {
        return setSpecialDayInfo((prev) => ({ ...prev, [type]: value }))
    }

    function setSpecialDayInfoConstraint(firstBoundary, lastBoundary, value) {
        return setSpecialDayInfo((prev) => ({ ...prev, [firstBoundary]: value, [lastBoundary]: value }))
    }

    return (
        <Card title="Ferie e malattia" extraComponent={<HolidaysSickSwitch value={specialDayInfo.type} setValue={(dayType) => setSpecialDayInfo((prev) => ({ ...prev, type: dayType }))} />}>
            <div className="flex flex-col gap-3">
                <div className="flex flex-row gap-3 px-1">
                    {/* <LabelledButton label="Data fine" /> */}
                    <CalendarButton
                        label="Data inizio"
                        text={formatRedableDate(specialDayInfo.start_date)}
                        value={specialDayInfo.start_date}
                        setValue={(dateDay) => handleStartEndSelection("start_date", ["start_date", "end_date"], dateDay.dateday, specialDayInfo, setSpecialDayInfoNormal, setSpecialDayInfoConstraint)}
                    />
                    <CalendarButton
                        label="Data fine"
                        text={formatRedableDate(specialDayInfo.end_date)}
                        value={specialDayInfo.end_date}
                        setValue={(dateDay) => handleStartEndSelection("end_date", ["start_date", "end_date"], dateDay.dateday, specialDayInfo, setSpecialDayInfoNormal, setSpecialDayInfoConstraint)}
                        className="!-left-24"
                    />
                </div>
                <SaveButton onClick={() => onClick({ specialDayInfo, operator, setAlertBox })}>Salva date</SaveButton>
            </div>
        </Card>
    )
}

function AddVariationCard({ setAlertBox, operator, onClick }) {
    const { selectedSalon } = useSalon()

    const [specialDayInfo, setSpecialDayInfo] = useState({
        date: DateTime.now(),
        start_time: DateTime.now().set({ hour: 9, minute: 0, second: 0, millisecond: 0 }),
        end_time: DateTime.now().set({ hour: 18, minute: 0, second: 0, millisecond: 0 }),
        type: "variation",
    })

    function setSpecialDayInfoNormal(type, value) {
        return setSpecialDayInfo((prev) => ({ ...prev, [type]: value }))
    }

    function setSpecialDayInfoConstraint(firstBoundary, lastBoundary, value) {
        return setSpecialDayInfo((prev) => ({ ...prev, [firstBoundary]: value, [lastBoundary]: value }))
    }

    const [isOpen, setIsOpen] = useState(true)

    function formatVariationSpecialDay(specialDayInfo) {
        const midnight = DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toFormat("HH:mm:ss")

        return {
            ...specialDayInfo,
            day: specialDayInfo.date.toFormat("yyyy-MM-dd"),
            start_time: isOpen ? specialDayInfo.start_time.toFormat("HH:mm:ss") : midnight,
            end_time: isOpen ? specialDayInfo.end_time.toFormat("HH:mm:ss") : midnight,
            start_date: specialDayInfo.date,
            end_date: specialDayInfo.date,
        }
    }

    return (
        <Card title="Variazione orario">
            <div className="flex flex-col gap-3">
                <div className="flex flex-row gap-6">
                    <CalendarButton
                        label="Data"
                        text={formatRedableDate(specialDayInfo.date)}
                        value={specialDayInfo.date}
                        setValue={(dateDay) => setSpecialDayInfo((prev) => ({ ...prev, date: dateDay.dateday }))}
                    />
                    <LabelledSwitch label="Apertura" checked={isOpen} onChange={() => setIsOpen(!isOpen)} />
                </div>
                {isOpen && (
                    <div className="flex flex-row gap-2">
                        <TimeButton
                            label="Inizio"
                            text={specialDayInfo.start_time.toLocaleString(DateTime.TIME_24_SIMPLE)}
                            value={specialDayInfo.start_time}
                            setValue={(timeslot) => handleStartEndSelection("start_time", ["start_time", "end_time"], timeslot, specialDayInfo, setSpecialDayInfoNormal, setSpecialDayInfoConstraint)}
                        />
                        <TimeButton
                            label="Fine"
                            text={specialDayInfo.end_time.toLocaleString(DateTime.TIME_24_SIMPLE)}
                            value={specialDayInfo.end_time}
                            setValue={(timeslot) => handleStartEndSelection("end_time", ["start_time", "end_time"], timeslot, specialDayInfo, setSpecialDayInfoNormal, setSpecialDayInfoConstraint)}
                        />
                    </div>
                )}

                <SaveButton onClick={() => onClick({ specialDayInfo: formatVariationSpecialDay({ ...specialDayInfo, salon: [selectedSalon.id] }), operator, setAlertBox })}>Salva orario</SaveButton>
            </div>
        </Card>
    )
}

function GeneralBadge({ text, className }) {
    return <div className={`flex items-center justify-center rounded-full text-white w-[40px] h-[40px] text-2xl leading-none ${className}`}>{text}</div>
}

function DropdownYears({ yearList, onClick }) {
    const [year, setYear] = useState(Number(new Date().getFullYear()))

    return (
        <Dropdown className="!bg-white" as={ButtonGroup}>
            <Dropdown.Toggle variant="secondary" className="flex items-center !px-5 rounded-2xl">
                {" "}
                {year}
            </Dropdown.Toggle>
            <DropdownMenu>
                {yearList.map((yearT) => {
                    if (yearT !== year)
                        return (
                            <Dropdown.Item
                                // className="!w-40"

                                key={"dropdown-years_" + String(yearT)}
                                style={{ fontSize: "14px" }}
                                onClick={() => {
                                    setYear(yearT)
                                    onClick(yearT)
                                }}
                            >
                                {yearT}
                            </Dropdown.Item>
                        )
                })}
            </DropdownMenu>
            {/* <DropdownButton id="dropdown-years" title={year} size="sm" variant="secondary" className="!rounded-xl">
            
            
        </DropdownButton> */}
        </Dropdown>
    )
}

function SummaryDatesCard({ specialDays, iconComponent }) {
    return (
        <Card title="Riepilogo ferie e malattia">
            <div className="flex flex-col !gap-5">
                {specialDays.map((specialDay) => {
                    if (specialDay.type === "variation") return
                    return (
                        <div key={specialDay.id} className="flex flex-row items-center ">
                            <GeneralBadge className={specialDay.type === "sick_days" ? "bg-lime-500/80" : "bg-primary/60"} text={specialDay.type === "sick_days" ? "M" : "F"} />
                            <div className="flex flex-row grow gap-3 !px-4">
                                <div className="flex flex-col">
                                    <div className="text-secondary text-xs">Data inizio</div>
                                    <div className="text-[14px] font-medium tracking-tighter text-black/80">
                                        <p className="capitalize">{formatRedableDate(specialDay.start_date)}</p>
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="text-secondary text-xs">Data fine</div>
                                    <div className="text-[14px] font-medium tracking-tighter text-black/80">
                                        <p className="capitalize">{formatRedableDate(specialDay.end_date)}</p>
                                    </div>
                                </div>
                            </div>
                            {iconComponent(specialDay)}
                        </div>
                    )
                })}
            </div>
        </Card>
    )
}

function SummaryVariationsCard({ specialDays, iconComponent }) {
    function formatRedableHours(start_time, end_time) {
        return start_time.slice(0, -3) + " - " + end_time.slice(0, -3)
    }

    return (
        <Card title="Riepilogo variazioni">
            <div className="flex flex-col !gap-5">
                {specialDays.map((specialDay) => {
                    if (specialDay.type !== "variation") return
                    const specialDayDetails = specialDay.special_days_operator_transactions[0]
                    const isClosed = specialDayDetails.start_time === "00:00:00" && specialDayDetails.end_time === "00:00:00"
                    return (
                        <div key={specialDay.id} className="flex flex-row items-center ">
                            <GeneralBadge className={isClosed ? "bg-gray-400/80" : "bg-violet-400/80"} text={isClosed ? "C" : "V"} />
                            <div className="flex flex-row grow gap-3 !px-4">
                                <div className="flex flex-col">
                                    <div className="text-secondary text-xs">Data</div>
                                    <div className="text-[14px] font-medium tracking-tighter text-black/80">
                                        <p className="capitalize">{formatRedableDate(specialDay.start_date)}</p>
                                    </div>
                                </div>
                                {isClosed || (
                                    <div className="flex flex-col">
                                        <div className="text-secondary text-xs">Orario</div>
                                        <div className="text-[14px] font-medium tracking-tighter text-black/80">
                                            <p className="capitalize">{formatRedableHours(specialDayDetails.start_time, specialDayDetails.end_time)}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {iconComponent(specialDay)}
                        </div>
                    )
                })}
            </div>
        </Card>
    )
}

function HourDaysSwitch({ value, setValue }) {
    // console.log(value)
    return (
        <div className="flex flex-row gap-2 ">
            <button className={`flex items-center justify-center rounded-full bg-primary/80 text-white w-[20px] h-[20px]  ${value === "hours" || "bg-secondary/80"}`} onClick={() => setValue("hours")}>
                O
            </button>
            <button className={`flex items-center justify-center rounded-full bg-primary/80 text-white w-[20px] h-[20px] ${value === "days" || "bg-secondary/80"}`} onClick={() => setValue("days")}>
                G
            </button>
        </div>
    )
}

function SummarySpecialDaysCard({ specialDaysStats }) {
    const [mode, setMode] = useState("days")

    function formatToMode(value) {
        if (mode === "days") return String(Number(value / 8).toFixed(0))
        else return value
    }

    return (
        <Card title="Riepilogo ore" extraComponent={<HourDaysSwitch value={mode} setValue={setMode} />}>
            <div className="flex flex-row px-2 gap-8">
                <div className="flex flex-col">
                    <div className="text-primary text-lg font-medium tracking-tighter leading-5">{formatToMode(specialDaysStats.holidays_available - specialDaysStats.holidays_used)}</div>

                    <div className="text-secondary tracking-tighter">Ferie rimaste</div>
                </div>
                <div className="flex flex-col">
                    <div className="text-primary text-lg font-medium tracking-tighter leading-5">{formatToMode(specialDaysStats.sick_days)}</div>

                    <div className="text-secondary tracking-tighter">Malattia</div>
                </div>
            </div>
        </Card>
    )
}

export function AlertBoxWrapper({ children, dependecies }) {
    const [alertBox, setAlertBox] = useState(defaultAlertBox)
    useEffect(() => {
        if (alertBox.enabled) {
            setAlertBox(false)
        }
    }, [...dependecies])

    return (
        <div className="flex flex-col">
            {children(setAlertBox)}
            {alertBox.enabled && (
                <Alert className="text-center mt-3" variant={alertBox.mode}>
                    {alertBox.message}
                </Alert>
            )}
        </div>
    )
}

export function IconComponentHairdresser({ specialDay, onClick }) {
    const [isProcessing, setIsProcessing] = useState(false)

    async function safeOnClick(onClick = async () => {}) {
        setIsProcessing(true)
        await onClick()
        setIsProcessing(false)
    }

    if (isProcessing) {
        return (
            <div>
                <Spinner className="me-1 text-primary" as="span" animation="border" size="sm" role="status" />
            </div>
        )
    }

    return (
        <>
            {specialDay.status === "accepted" ? (
                <AiOutlineCheckCircle className="text-primary/70" size={23} />
            ) : (
                <button onClick={() => safeOnClick(() => onClick(specialDay))}>
                    <AiOutlineCloseCircle className="text-secondary/90" size={23} />
                </button>
            )}
        </>
    )
}

export function IconComponentManager({ specialDay, onClickDelete, onClickAccept, onClickReject }) {
    const [isProcessing, setIsProcessing] = useState(false)

    async function safeOnClick(onClick = async () => {}) {
        setIsProcessing(true)
        await onClick()
        setIsProcessing(false)
    }

    if (isProcessing) {
        return (
            <div>
                <Spinner className="me-1 text-primary" as="span" animation="border" size="sm" role="status" />
            </div>
        )
    }
    // console.log(specialDay)

    return (
        <div className="flex flex-row gap-1">
            {specialDay.status === "accepted" ? (
                <>
                    <AiOutlineCheckCircle className="text-primary/70" size={23} />
                    <button
                        disabled={isProcessing}
                        onClick={() => {
                            safeOnClick(() => onClickDelete(specialDay))
                        }}
                    >
                        {/* {specialDay.start_date >= DateTime.local() && <AiOutlineCloseCircle className="text-secondary/90" size={23} />} */}
                        <AiOutlineCloseCircle className="text-secondary/90" size={23} />
                    </button>
                </>
            ) : (
                <>
                    <button
                        disabled={isProcessing}
                        onClick={() => {
                            safeOnClick(() => onClickAccept(specialDay))
                        }}
                    >
                        <AiFillCheckCircle className="text-primary/70" size={23} />
                    </button>
                    <button
                        disabled={isProcessing}
                        onClick={() => {
                            safeOnClick(() => onClickReject(specialDay))
                        }}
                    >
                        <AiFillCloseCircle className="text-red-700" size={23} />
                    </button>
                </>
            )}
        </div>
    )
}

function SplitContainer({ leftSideComponent, rightSideComponent }) {
    return (
        <div className="flex flex-row gap-4">
            <div className="flex flex-col w-1/2 gap-4">{leftSideComponent}</div>

            <div className="flex flex-col w-1/2 gap-4">{rightSideComponent}</div>
        </div>
    )
}

function ValueDropdown({ values, value, setValue, isSelected }) {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
    console.log(values)
    function ValueSelector() {
        return (
            <>
                {values.map((valueT) => {
                    return (
                        <div
                            key={"my_value" + valueT}
                            className={` hover:bg-primary/10 tracking-tighter p-2 rounded-xl text-primary/80 text-center ${isSelected(valueT) ? "bg-primary text-white" : "bg-slate-100"}`}
                            onClick={(e) => {
                                console.log("click")
                                //The propagation allows the closure of the component
                                e.stopPropagation()
                                setValue(valueT)
                                setIsComponentVisible(false)
                            }}
                        >
                            {valueT}
                        </div>
                    )
                })}
            </>
        )
    }

    return (
        <ExpandingSectionTime refSection={ref} isComponentVisible={isComponentVisible} setIsComponentVisible={setIsComponentVisible} extraComponent={<ValueSelector />} className="!grow-0">
            <div className={`flex !py-2 !px-3 rounded-xl font-medium tracking-tight z-10   bg-primary text-white !w-20 justify-center`}>{value}</div>
        </ExpandingSectionTime>
    )
}

export default function SpecialDays() {
    const { updateOperators } = useDatabase()
    const { selectedOperator } = useDashboard()
    const { selectedSalon } = useSalon()

    const [specialDaysRetrieved, setSpecialDaysRetrieved] = useState([])
    const [specialDaysStats, setSpecialDaysStats] = useState({ holidays_available: 0, holidays_used: 0, sick_days: 0 })
    const [year, setYear] = useState(Number(new Date().getFullYear()))

    function formatDatesSpecialDaysInfo(specialDayInfo) {
        return { ...specialDayInfo, start_date: specialDayInfo.start_date.toFormat("yyyy-MM-dd"), end_date: specialDayInfo.end_date.toFormat("yyyy-MM-dd") }
    }

    async function retrieveSpecialDaysOperator() {
        if (selectedOperator !== "not-selected") {
            //Retrieve the days
            const specialDaysRetrieved = await getData(urls.retrieveSpecialDaysOperator + "year/" + selectedOperator.id + "/" + String(year) + "/" + selectedSalon.id + "/")
            // console.log(specialDaysRetrieved)
            const specialDaysFormatted = specialDaysRetrieved.map((period) => ({
                ...period,
                start_date: DateTime.fromISO(period.start_date),
                end_date: DateTime.fromISO(period.end_date),
            }))
            // console.log(specialDaysFormatted)
            setSpecialDaysRetrieved(specialDaysFormatted)
            //Retrieve the stats
            const specialDaysStats = await getData(urls.retrieveStatsSpecialDaysOperators + selectedOperator.id + "/")
            setSpecialDaysStats(specialDaysStats)
            await updateOperators()
        }
    }

    async function acceptSpecialDays(specialDay) {
        try {
            await patchData(urls.acceptSpecialDaysOperators + specialDay.id + "/", formatDatesSpecialDaysInfo(specialDay))
        } catch (error) {
            console.log(error)
            return
        }
        await retrieveSpecialDaysOperator()
    }

    async function rejectSpecialDays(specialDay) {
        try {
            await patchData(urls.rejectSpecialDaysOperators + specialDay.id + "/", formatDatesSpecialDaysInfo(specialDay))
        } catch (error) {
            console.log(error)
            return
        }
        await retrieveSpecialDaysOperator()
    }

    async function deleteSpecialDays(specialDay) {
        try {
            await patchData(urls.deleteSpecialDaysOperators + specialDay.id + "/", formatDatesSpecialDaysInfo({ ...specialDay, deleted: true }))
        } catch (error) {
            console.log(error)
            return
        }
        await retrieveSpecialDaysOperator()
    }

    async function saveSpecialDays({ specialDayInfo, operator, setAlertBox, action }) {
        // console.log(specialDayInfo)
        // Check dates condition
        if (specialDayInfo.start_date > specialDayInfo.end_date) return setAlertBox({ mode: "danger", message: "La data di inizio deve essere antecedente alla data di fine", enabled: true })
        const urlRequest = action === "accepted" ? urls.createAcceptSpecialDaysOperators : urls.createPendingSpecialDaysOperators
        //Send data
        try {
            await sendData(urlRequest, formatDatesSpecialDaysInfo({ ...specialDayInfo, operator }))
        } catch (error) {
            // console.log(error.message)
            setAlertBox({ mode: "danger", message: error.message, enabled: true })
            return
        }
        setAlertBox({ mode: "success", message: "Date inviate con successo", enabled: true })
        await retrieveSpecialDaysOperator()
    }

    useEffect(() => {
        retrieveSpecialDaysOperator()
    }, [selectedOperator, year])

    function computeYearsList() {
        const startYearOpeartor = new Date(selectedOperator.joining_date).getFullYear()
        const yearList = range(Number(startYearOpeartor), Number(new Date().getFullYear() + 1)).reverse()
        return yearList
    }
    return (
        <>
            <PermissionGate permissions={[ROLES.manager, ROLES.owner]}>
                <SplitContainer
                    leftSideComponent={
                        <AlertBoxWrapper dependecies={[selectedOperator]}>
                            {(setAlertBox) => {
                                return (
                                    <div className="flex flex-col gap-4">
                                        <AddVariationCard operator={selectedOperator.id} setAlertBox={setAlertBox} onClick={(props) => saveSpecialDays({ ...props, action: "accepted" })} />
                                    </div>
                                )
                            }}
                        </AlertBoxWrapper>
                    }
                    rightSideComponent={
                        <AlertBoxWrapper dependecies={[selectedOperator]}>
                            {(setAlertBox) => {
                                return (
                                    <div className="flex flex-col gap-4">
                                        <SummarySpecialDaysCard specialDaysStats={specialDaysStats} />

                                        <AddDatesCard operator={selectedOperator.id} setAlertBox={setAlertBox} onClick={(props) => saveSpecialDays({ ...props, action: "accepted" })} />
                                    </div>
                                )
                            }}
                        </AlertBoxWrapper>
                    }
                />
                <div className="flex flex-row bg-transparent rounded-lg p-2 mt-10 mb-3 gap-4">
                    <div className="font-semibold text-[22px]">Riepilogo</div>
                    {/* <DropdownYears yearList={computeYearsList()} onClick={(year) => setYear(year)} /> */}
                    <ValueDropdown values={computeYearsList()} value={year} setValue={(value) => setYear(value)} isSelected={(value) => value === year} />
                </div>
                <SplitContainer
                    leftSideComponent={
                        <SummaryVariationsCard
                            specialDays={specialDaysRetrieved}
                            iconComponent={(specialDay) => (
                                <IconComponentManager specialDay={specialDay} onClickDelete={deleteSpecialDays} onClickReject={rejectSpecialDays} onClickAccept={acceptSpecialDays} />
                            )}
                        />
                    }
                    rightSideComponent={
                        <SummaryDatesCard
                            specialDays={specialDaysRetrieved}
                            iconComponent={(specialDay) => (
                                <IconComponentManager specialDay={specialDay} onClickDelete={deleteSpecialDays} onClickReject={rejectSpecialDays} onClickAccept={acceptSpecialDays} />
                            )}
                        />
                    }
                />
            </PermissionGate>

            <PermissionGate permissions={[ROLES.hairdresser]}>
                <SplitContainer
                    leftSideComponent={
                        <AlertBoxWrapper dependecies={[]}>
                            {(setAlertBox) => {
                                return (
                                    <div className="flex flex-col gap-4">
                                        <AddVariationCard operator={selectedOperator.id} setAlertBox={setAlertBox} onClick={(props) => saveSpecialDays({ ...props, action: "pending" })} />
                                    </div>
                                )
                            }}
                        </AlertBoxWrapper>
                    }
                    rightSideComponent={
                        <AlertBoxWrapper dependecies={[selectedOperator]}>
                            {(setAlertBox) => {
                                return (
                                    <div className="flex flex-col gap-4">
                                        <SummarySpecialDaysCard specialDaysStats={specialDaysStats} />
                                        <AddDatesCard operator={selectedOperator.id} setAlertBox={setAlertBox} onClick={(props) => saveSpecialDays({ ...props, action: "pending" })} />
                                    </div>
                                )
                            }}
                        </AlertBoxWrapper>
                    }
                />
                <div className="flex flex-row bg-transparent rounded-lg p-2 mt-10 mb-3 gap-4">
                    <div className="font-semibold text-[22px]">Riepilogo</div>
                    <ValueDropdown values={computeYearsList()} value={year} setValue={(value) => setYear(value)} isSelected={(value) => value === year} />
                </div>
                <SplitContainer
                    leftSideComponent={
                        <SummaryVariationsCard specialDays={specialDaysRetrieved} iconComponent={(specialDay) => <IconComponentHairdresser specialDay={specialDay} onClick={deleteSpecialDays} />} />
                    }
                    rightSideComponent={
                        <SummaryDatesCard specialDays={specialDaysRetrieved} iconComponent={(specialDay) => <IconComponentHairdresser specialDay={specialDay} onClick={deleteSpecialDays} />} />
                    }
                />
            </PermissionGate>
        </>
    )
}
