import React from "react"
import { useState, useEffect } from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import PropTypes from "prop-types"
import { styled } from "@mui/material/styles"
import { useDashboard } from "../../../../contexts/DashboardProvider"
import TemplatesList from "./TemplatesList"
import MessagesList from "./MessagesList"
import PermissionGate from "../../../permissions/PermissionGate"
import { ROLES } from "../../../../helper"

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <div>{children}</div>}
        </div>
    )
}

const StyledTabs = styled((props) => (
    <Tabs {...props} style={{ borderTopRightRadius: "15px", borderTopLeftRadius: "15px" }} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
    "& .MuiTabs-indicator": {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
        width: "60%",
        backgroundColor: "#0d6efd",
    },
})

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

export default function EasyMessagesSidebar() {
    const { colorTheme, setMessagesMode } = useDashboard()
    const [value, setValue] = useState(0)

    useEffect(() => {
        setMessagesMode("conversations")
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    return (
        <div>
            <PermissionGate permissions={[ROLES.manager, ROLES.salon, ROLES.owner]}>
                <StyledTabs
                    variant="fullWidth"
                    value={value}
                    //TabIndicatorProps={{ style: { backgroundColor: colorTheme.primaryColor } }}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    <Tab
                        label="Messaggi"
                        onClick={() => {
                            setMessagesMode("conversations")
                        }}
                        className="pt-4 pb-3"
                        style={{ fontSize: "20px", fontWeight: "500", textTransform: "none", fontFamily: "Poppins", padding: "0px", margin: "0px", color: value === 0 && colorTheme.primaryColor }}
                    />
                    <Tab
                        label="Modelli"
                        onClick={() => {
                            setMessagesMode("templates")
                        }}
                        className="pt-4 pb-3"
                        style={{ fontSize: "20px", fontWeight: "500", textTransform: "none", fontFamily: "Poppins", padding: "0px", margin: "0px", color: value === 1 && colorTheme.primaryColor }}
                    />
                </StyledTabs>

                <TabPanel value={value} index={0}>
                    <MessagesList />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <TemplatesList />
                </TabPanel>
            </PermissionGate>

            <PermissionGate permissions={[ROLES.hairdresser]}>
                <div
                    className="d-flex flex-row pt-4 ps-3 pe-3 text-center align-items-center justify-content-center"
                    style={{ fontSize: "20px", fontWeight: "500", color: colorTheme.primaryColor, paddingBottom: "9px" }}
                >
                    Messaggi
                </div>
                <MessagesList />
            </PermissionGate>
        </div>
    )
}
