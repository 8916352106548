import React, { Children, useEffect, useState } from "react"
import { Toast, ToastBody } from "react-bootstrap"
import useNewVersionNotification from "./hooks/useNewVersionNotification"

export default function NewVersionNotification({children}) {
    const {newVersionAvailable, forceUpdate} = useNewVersionNotification()
    const [showModal, setShowModal] = useState(false)
    
    useEffect(()=>{
    
        // console.log(newVersionAvailable)
        if (newVersionAvailable) setShowModal(true)
    },[newVersionAvailable])

    return (
        <>
            {showModal && <div className="fixed inset-0 z-10 bg-black bg-opacity-50"></div>}
            <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-20">
                <Toast show={showModal} bg={"primary"} className="rounded-xl w-[425px] p-2">
                    <ToastBody>
                        <div className="flex flex-col">
                            <div className="flex text-white text-lg mb-2 justify-center font-medium">Nuovo update!</div>
                            <div className="flex text-white text-sm justify-center text-center">Un nuovo update è disponibile per l'app. Aggiorna ora o più tardi per usare le ultime funzionalità.</div>
                            <div className="flex flex-row justify-center mt-4 gap-2">
                                <button className="flex p-2 bg-white/20 rounded-md text-white" onClick={() => {setShowModal(false)}}>Aggiorna più tardi</button>
                                <button className="flex p-2 bg-white rounded-md text-primary font-medium"  onClick={forceUpdate}>Aggiorna ora </button>
                            </div>
                        </div>
                    </ToastBody>
                </Toast>
            </div>
            {children(newVersionAvailable, forceUpdate)}
        </>
    )
}
