import React from 'react'
import { useState, useRef, useEffect } from "react"
import { Modal, Button, Container, InputGroup, FormControl, ListGroup } from "react-bootstrap"
import { AiOutlinePlusCircle } from "react-icons/ai"
import { useDatabase } from '../../../../contexts/DatabaseProvider'
import { useDashboard } from '../../../../contexts/DashboardProvider'
import { BsCheckCircle, BsCircle } from 'react-icons/bs'

export default function NewProduct({ setProductsSelected, productsSelected }) {
    const [modalShow, setModalShow] = useState(false)
    const { products } = useDatabase()
    const { colorTheme } = useDashboard()
    const [selectedProductsModal, setSelectedProductsModal] = useState(products.reduce((productsAll, productT) => { return { ...productsAll, [productT.id]: false } }, {}))
    const [searchProduct, setSearchedProduct] = useState('')
    const searchProductRef = useRef(null)


    const handleProductsSelection = (id) => {
        setSelectedProductsModal(idT => {
            //  console.log(idT)
            const idUpdate = { ...idT }
            idUpdate[id] = !idUpdate[id]
            return idUpdate
        })
        setSearchedProduct('')
        searchProductRef.current.focus()
    }

    useEffect(() => {
        if (modalShow){
            searchProductRef.current.focus()
        }
    },[modalShow])

    const checkIfActive = (id) => {
        //console.log(selectedProductsModal[id]) 
        return selectedProductsModal[id]
    }

    const addProduct = () => {
        const newProductsSelected = products.filter(productT => { return selectedProductsModal[productT.id] })
            .reduce((productsAll, productT) => { return [...productsAll, { ...productT, operator: "---", quantity: 1, discount: 0, date: new Date().toISOString(), type: 'product' }] }, [])
        // console.log('selection', newProductsSelected)
        setProductsSelected([...productsSelected, ...newProductsSelected])
        setModalShow(false)
        setSelectedProductsModal({})
        setSearchedProduct('')
    }


    return (
        <>
            <Button variant="white rounded-pill flex items-center justify-center" onClick={() => setModalShow(true)} >
                <AiOutlinePlusCircle color={colorTheme.primaryColor}  />
                <span className='ms-2' style={{ color: colorTheme.primaryColor }}>Aggiungi prodotto</span>
            </Button>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="sg"
                aria-labelledby="contained-modal-title-vcenter" 
                // style={{height:'80%'}}
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Lista Prodotti
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: "0px" }}>
                    <Container style={{ padding: "1px" }}>
                        {/* {console.log('internal', selectedproductsModal)} */}
                        <div className='p-3 ps-4 pe-4' style={{ backgroundColor: "whitesmoke" }}>
                            <InputGroup >
                                <FormControl                                    
                                    ref={searchProductRef}
                                    value={searchProduct}
                                    
                                    onChange={e => setSearchedProduct(e.target.value)}
                                    variant='secondary'
                                    style={{ borderRadius: "20px", border: "0px" }}
                                    placeholder="Cerca prodotto" />
                            </InputGroup>
                        </div>
                        <ListGroup>
                            <div >
                                {products.map((productT) => {
                                    if ((String(productT["code"]).includes(searchProduct.toLowerCase()) || String(productT["name"]).toLowerCase().includes(searchProduct.toLowerCase())) && productT.active && productT.sellable && (productT.quantity > 0)) {
                                        return (
                                            <ListGroup.Item
                                                action
                                                key={productT.id}
                                                variant="white"
                                                style={{ fontSize: "18px", border: "0em" }}
                                                onClick={() => { handleProductsSelection(productT.id) }} >

                                                <div className='flex flex-row  items-center gap-2 p-1'>
                                                    
                                                        {checkIfActive(productT.id) ? <BsCheckCircle color={colorTheme.primaryColor}  /> : <BsCircle color="gray" />}
                                                
                                                    <div className='d-flex flex-fill'>
                                                        {productT.name}
                                                    </div>
                                                    <div>
                                                        € {Number(productT.sell_price).toFixed(2)}
                                                    </div>
                                                </div>


                                            </ListGroup.Item>
                                        )

                                    }

                                })}
                            </div>
                        </ListGroup>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={colorTheme.button} onClick={() => addProduct()} >Avanti</Button>
                </Modal.Footer>
            </Modal>

        </>

    );

}