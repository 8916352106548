import React from 'react'
import { stringToHslColor } from '../../../../helper';
import { useDashboard } from '../../../../contexts/DashboardProvider';


export default function IndividualStats({ selectedOperator }) {
    const { colorTheme } = useDashboard()

    return (
        <>
            <div className='d-flex card shadow-sm mb-3 p-4' style={{ borderRadius: "15px" }}>
                <div className='d-flex justify-content-center mt-3'>
                    <div
                        className='d-flex justify-content-center rounded-circle align-items-center '
                        style={{
                            height: '50px',
                            width: '50px',
                            fontSize: '26px',
                            backgroundColor: stringToHslColor(selectedOperator.name, 30, 80),
                            color: 'white'
                        }}>
                        {selectedOperator.name[0]}
                    </div>

                </div>
                <div className='text-center mt-3 mb-4' >
                    <span style={{ fontSize: '18px', fontWeight: '600' }}>
                        {selectedOperator.name}
                    </span><br />
                    <span className='text-secondary'>
                        {selectedOperator.jobTitle}
                    </span>
                </div>
                <div className='d-flex flex-row mt-1 ps-3 pe-3 justify-content-around'>
                    <div className='d-flex flex-column'>
                        <div className='text-center' style={{ fontSize: '20px', fontWeight: '600', color: colorTheme.primaryColor }}>
                            {selectedOperator.numberServicesSold}

                        </div>
                        <div className='text-secondary'>
                            N. Servizi
                        </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='text-center' style={{ fontSize: '20px', fontWeight: '600', color: colorTheme.primaryColor }}>
                            {selectedOperator.numberProductsSold}

                        </div>
                        <div className='text-secondary'>
                            N. Prodotti
                        </div>
                    </div>
                </div>
                <div >
                    <hr className="mt-4 mb-4 bg-dark border-1 border-top border-secondary"></hr>
                </div>
                <div className='d-flex flex-column ps-3 pe-3'>
                    <div className='mb-2' style={{ fontSize: '17px', fontWeight: '600' }}>
                        Top Servizi
                    </div>
                    {selectedOperator.countPerServiceType.map((serviceT) => {
                        return (
                            <div key={serviceT.service_id} className='d-flex flex-row justify-content-between text-secondary mb-1'>
                                <div style={{ fontWeight: '500' }}>
                                    {serviceT.name}
                                </div>
                                <div>
                                    x{serviceT.count}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className='d-flex flex-column ps-3 pe-3 mt-3'>
                    <div className='mb-2' style={{ fontSize: '17px', fontWeight: '600' }}>
                        Top Prodotti
                    </div>
                    {selectedOperator.countPerProductType.map((productT) => {
                        return (
                            <div key={productT.product_id} className='d-flex flex-row justify-content-between text-secondary mb-1'>
                                <div style={{ fontWeight: '500' }}>
                                    {productT.name}
                                </div>
                                <div>
                                    x{productT.count}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>


    );
}