import { IconButton } from "@mui/material"
import React, { useEffect, useState } from "react"
import { Alert, Button, Col, FormControl, InputGroup, Row } from "react-bootstrap"
import { AiOutlineDelete, AiOutlinePlusCircle } from "react-icons/ai"
import { BsCheckCircle, BsCircle, BsCircleFill } from "react-icons/bs"
import { FiMinus, FiPlus } from "react-icons/fi"
import { TiDelete } from "react-icons/ti"
import { useDashboard } from "../../../../contexts/DashboardProvider"
import { useDatabase } from "../../../../contexts/DatabaseProvider"
import { MaterialUISwitchInventory, defaultAlertBox, defaultProduct, putData, sendData, urls } from "../../../../helper"
import { useSalon } from "../../../../contexts/SalonProvider"

export default function EasyInventory() {
    const { updateInventory, updateStats, providers } = useDatabase()
    const { selectedProduct, setSelectedProduct, setSearchedProduct, colorTheme } = useDashboard()
    const { selectedSalon } = useSalon()
    const [product, setProduct] = useState(defaultProduct) //this variable holds the updates of the selectedProduct, so that selectedProduct does not change until backend is updated
    const [alertBox, setAlertBox] = useState(defaultAlertBox)
    const [inventoryMode, setInventoryMode] = useState("operational") //Can be operational or editing
    // Use effect are useful to update a state when another state changes

    useEffect(() => {
        setSelectedProduct({ ...defaultProduct })
    }, [selectedSalon])

    useEffect(() => {
        // console.log(selectedProduct)
        if (selectedProduct.id !== "new") {
            setProduct({ ...selectedProduct, salon: selectedSalon.id })
            if (selectedProduct.id !== product.id) {
                // Disable alertbox if a product is changed but not if a product is refreshed
                setAlertBox(defaultAlertBox)
                setInventoryMode("operational")
            }
        } else setProduct({ ...defaultProduct, salon: selectedSalon.id })
    }, [selectedProduct])

    const updateProduct = (key, newValue, codeIdx = null) => {
        setProduct((productT) => {
            if (codeIdx !== null) {
                productT[key][codeIdx] = newValue
            } else {
                productT[key] = newValue
            }
            const updateProduct = { ...productT }
            return updateProduct
        })
    }

    const updateQuantity = (quantity) => {
        if (quantity >= 0) {
            updateProduct("quantity", quantity)
        } else {
            updateProduct("quantity", 0)
        }
    }

    const handleSaveProduct = async () => {
        setAlertBox(defaultAlertBox)

        var keys = Object.keys(product)
        const noFieldCompleted = keys.some((key) => product[key] === "")
        const productFull = {
            product: product,
            product_transaction: {
                date: new Date().toISOString(),
                quantity: product.quantity - selectedProduct.quantity,
                buy_price: product.buy_price,
                sellable: product.sellable,
            },
        }

        // console.log(productFull)
        if (noFieldCompleted) {
            setAlertBox({ mode: "danger", message: "Non tutti i campi sono stati compilati", enabled: true })
            return
        }

        if (inventoryMode === "operational") {
            if (selectedProduct.id === "new") {
                const response = await sendData(urls.inventoryTransactionNew, productFull)

                if (response.type === "success") {
                    setAlertBox({ mode: "success", message: response.msg, enabled: true })
                    setSelectedProduct({ ...defaultProduct })
                } else setAlertBox({ mode: "danger", message: response.msg, enabled: true })
            } else {
                const response = await putData(urls.inventoryTransactionNew + product.id + "/", productFull) //inventoryTransactionNew registering transaction
                // console.log(response)
                if (response.type === "success") {
                    setAlertBox({ mode: "success", message: response.msg, enabled: true })
                    setSelectedProduct(product)
                    setSearchedProduct("")
                } else setAlertBox({ mode: "danger", message: response.msg, enabled: true })
            }
        } else {
            const response = await putData(urls.inventoryTransactionEdit + product.id + "/", productFull) //inventoryTransactionNew registering transaction
            // console.log(response)
            if (response.type === "success") {
                setAlertBox({ mode: "success", message: response.msg, enabled: true })
                setSelectedProduct(product)
                setSearchedProduct("")
            } else setAlertBox({ mode: "danger", message: response.msg, enabled: true })
        }

        updateInventory()
        updateStats(new Date())
    }

    const handleDeleteProduct = async () => {
        selectedProduct.active = false
        // console.log(selectedProduct)
        let productFull = {
            product: selectedProduct,
            product_transaction: {
                date: new Date().toISOString(),
                quantity: -selectedProduct.quantity,
                buy_price: selectedProduct.buy_price,
                sellable: selectedProduct.sellable,
            },
        }
        productFull.product.quantity = 0
        const response = await putData(urls.inventoryTransactionNew + product.id + "/", productFull)
        // console.log(response)
        setSelectedProduct(defaultProduct)
        updateInventory()
        updateStats(new Date())
    }

    // console.log(defaultProduct)

    const addCodeProduct = () => {
        product.code.push("")
        setProduct({ ...product })
        // console.log(product)
    }

    const removeCodeProduct = (idx) => {
        product.code.splice(idx, 1)
        setProduct({ ...product })
    }

    return (
        <>
            <div className="d-flex flex-row mb-2">
                <div className="flex-grow-1 ps-2 text-secondary">Prodotto</div>
                <div className="flex-grow-1 ps-4 text-secondary">Quantità</div>
            </div>

            <Row>
                <Col className="me-1">
                    <div className="card shadow-sm " style={{ borderRadius: "15px", backgroundColor: "whitesmoke" }}>
                        <div
                            className="d-flex flex-row p-4 justify-content-between align-items-center"
                            style={{ fontSize: "16px", margin: "0px", backgroundColor: "white", borderTopRightRadius: "15px", borderTopLeftRadius: "15px" }}
                        >
                            <div className="pe-2 ps-2">{selectedProduct.code === "" ? "Nuovo prodotto" : "Info prodotto"}</div>
                            {selectedProduct.code === "" ? (
                                <IconButton disabled>
                                    <AiOutlineDelete color="white" />
                                </IconButton>
                            ) : (
                                <IconButton
                                    onClick={() => {
                                        handleDeleteProduct()
                                    }}
                                >
                                    <AiOutlineDelete color="tomato" />
                                </IconButton>
                            )}
                        </div>
                        <div className="ps-2 pe-2 ">
                            <div className="ps-4 pe-4  mb-4 mt-4">
                                <InputGroup>
                                    <FormControl
                                        variant="secondary"
                                        style={{ borderRadius: "20px" }}
                                        value={product.name}
                                        onChange={(e) => updateProduct("name", e.target.value)}
                                        placeholder={"Nome del prodotto"}
                                    />
                                </InputGroup>
                            </div>
                            <div className="d-flex ps-4 pe-4 mb-3 align-items-center justify-content-between">
                                <div className="text-secondary" style={{ margin: "0px" }}>
                                    PREZZO ACQUISTO
                                </div>
                                <div style={{ width: "50%" }}>
                                    <InputGroup>
                                        <FormControl
                                            variant="secondary"
                                            type="number"
                                            value={product.buy_price}
                                            onChange={(e) => updateProduct("buy_price", e.target.value)}
                                            style={{ borderRadius: "20px" }}
                                            placeholder={"0"}
                                        />
                                    </InputGroup>
                                </div>
                            </div>
                            <div className="d-flex ps-4 pe-4 align-items-center justify-content-between mb-4 ">
                                <div className="text-secondary" style={{ margin: "0px" }}>
                                    PREZZO VENDITA
                                </div>
                                <div style={{ width: "50%" }}>
                                    <InputGroup>
                                        <FormControl
                                            variant="secondary"
                                            type="number"
                                            value={product.sell_price}
                                            onChange={(e) => updateProduct("sell_price", e.target.value)}
                                            style={{ borderRadius: "20px", width: "11rem" }}
                                            placeholder={"0"}
                                        />
                                    </InputGroup>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col px-2">
                            {product.code.map((e, idx) => {
                                return (
                                    <div key={idx} className="d-flex flex-row ps-4 mb-4 align-items-center justify-content-around">
                                        {/* Adding this undefined control due to an issue of not having a dynamic array in the defaultProduct, since it is not dynamic it triggers undefined when more then one element and it triggers a console warning. The issue is that value goes from undefined to defined */}

                                        <div className="text-secondary me-2" style={{ margin: "0px" }}>
                                            C. PROD #{idx + 1}
                                        </div>

                                        <div style={{ width: "60%" }}>
                                            <InputGroup>
                                                <FormControl
                                                    variant="secondary"
                                                    value={product.code[idx]}
                                                    onChange={(e) => updateProduct("code", e.target.value, idx)}
                                                    style={{ borderRadius: "20px", fontSize: "14px" }}
                                                    placeholder={"0000-0000-0000"}
                                                />
                                            </InputGroup>
                                        </div>

                                        <IconButton
                                            onClick={() => {
                                                removeCodeProduct(idx)
                                            }}
                                        >
                                            <TiDelete color={"grey"} className="align-items-center" size={15} />
                                        </IconButton>
                                    </div>
                                )
                            })}

                            <button className="flex  items-center bg-white rounded-2xl p-2 mb-3 mx-4 justify-center" onClick={() => addCodeProduct()}>
                                <AiOutlinePlusCircle color={colorTheme.primaryColor} className="align-items-center" size={13} />
                                <span className="ms-2 text-[14px]" style={{ color: colorTheme.primaryColor }}>
                                    Aggiungi codice
                                </span>
                            </button>
                        </div>

                        <div className="d-flex ps-4 pe-4 pt-3 align-items-center justify-content-between bg-white">
                            <div className=" ps-2" style={{ fontSize: "16px", margin: "0px" }}>
                                Fornitore{" "}
                            </div>
                            <div>
                                {providers.map((providerT) => {
                                    return (
                                        <IconButton
                                            key={providerT.id}
                                            className="ms-2"
                                            onClick={() => {
                                                updateProduct("provider", providerT.id)
                                            }}
                                        >
                                            {product.provider !== providerT.id ? <BsCircle color={providerT.color} size={25} /> : <BsCircleFill color={providerT.color} size={25} />}
                                        </IconButton>
                                    )
                                })}
                            </div>
                        </div>
                        <div
                            className=" d-flex ps-4 pe-4 p-4 pt-2 align-items-center "
                            style={{ fontSize: "18px", margin: "0px", backgroundColor: "white", borderBottomRightRadius: "15px", borderBottomLeftRadius: "15px" }}
                        >
                            <div className="flex !me-[19px] align-items-center pt-1 ps-2" style={{ fontSize: "16px", margin: "0px" }}>
                                Vendibile
                            </div>
                            <div className="bg-white rounded-circle">
                                {product.sellable ? (
                                    <BsCheckCircle
                                        onClick={() => {
                                            updateProduct("sellable", !product.sellable)
                                        }}
                                        color={colorTheme.primaryColor}
                                        size={25}
                                    />
                                ) : (
                                    <BsCircle
                                        onClick={() => {
                                            updateProduct("sellable", !product.sellable)
                                        }}
                                        color={colorTheme.primaryColor}
                                        size={25}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </Col>

                <Col>
                    <div className="d-flex card shadow-sm" style={{ fontSize: "16px", borderRadius: "15px", backgroundColor: "whitesmoke" }}>
                        {selectedProduct.id !== "new" && (
                            <div className="d-flex flex-row justify-content-center align-items-center p-3 ">
                                <div className="me-3" style={{ fontSize: "13px", color: inventoryMode === "operational" ? "black" : "gray", fontWeight: inventoryMode === "operational" && "600" }}>
                                    Registra
                                </div>
                                <MaterialUISwitchInventory
                                    checked={inventoryMode === "editing" ? true : false}
                                    onChange={() => {
                                        if (inventoryMode === "operational") setInventoryMode("editing")
                                        else setInventoryMode("operational")
                                    }}
                                />
                                <div className="ms-3" style={{ fontSize: "13px", color: inventoryMode === "editing" ? "black" : "gray", fontWeight: inventoryMode === "editing" && "600" }}>
                                    Modifica
                                </div>
                            </div>
                        )}

                        <div
                            className="d-flex flex-column bg-white p-4"
                            style={{ borderTopLeftRadius: selectedProduct.id === "new" && "15px", borderTopRightRadius: selectedProduct.id === "new" && "15px" }}
                        >
                            <div className="d-flex justify-content-center mb-2">
                                <div>Quantità</div>
                            </div>
                            <div className="d-flex ps-3 pe-3 flex-row justify-content-around align-items-center">
                                <IconButton style={{ backgroundColor: "whitesmoke" }} onClick={() => updateQuantity(product.quantity - 1)}>
                                    <FiMinus size={25} color={colorTheme.primaryColor} />
                                </IconButton>

                                <InputGroup className="border-bottom ps-2 pe-2" style={{ width: "115px" }}>
                                    <FormControl
                                        value={product.quantity}
                                        onChange={(e) => {
                                            updateQuantity(Number(e.target.value))
                                        }}
                                        variant="secondary"
                                        // className='bg-light'
                                        style={{ borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px", border: "0px", fontSize: "30px", color: colorTheme.primaryColor, textAlign: "right" }}
                                        placeholder={"0"}
                                    />
                                    <InputGroup.Text
                                        style={{
                                            borderTopRightRadius: "20px",
                                            borderBottomRightRadius: "20px",
                                            border: "0px",
                                            fontSize: "22px",
                                            backgroundColor: "white",
                                            textAlign: "left",
                                            padding: "0px",
                                            paddingTop: "5px",
                                        }}
                                    >
                                        {" "}
                                        pz
                                    </InputGroup.Text>
                                </InputGroup>

                                <IconButton style={{ backgroundColor: "whitesmoke" }} onClick={() => updateQuantity(product.quantity + 1)}>
                                    <FiPlus size={25} color={colorTheme.primaryColor} />
                                </IconButton>
                            </div>
                        </div>
                        <div className="p-2 pt-4">
                            <div className="d-flex ps-4 pe-4 mb-3 align-items-center justify-content-between">
                                <div className="text-secondary" style={{ fontSize: "14px", margin: "0px" }}>
                                    QUANTITÀ MINIMA
                                </div>
                                <div>
                                    <InputGroup>
                                        <FormControl
                                            variant="secondary"
                                            value={product.restock_limit}
                                            type="number"
                                            onChange={(e) => updateProduct("restock_limit", e.target.value)}
                                            style={{ borderRadius: "20px" }}
                                            placeholder={"0"}
                                        />
                                    </InputGroup>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex card shadow-sm mt-3 mb-3" style={{ borderRadius: "25px" }}>
                        <Button
                            variant={inventoryMode === "operational" ? colorTheme.button : "secondary"}
                            style={{ borderRadius: "25px" }}
                            size="lg"
                            onClick={() => {
                                handleSaveProduct()
                            }}
                        >
                            {inventoryMode === "operational" ? "Salva" : "Modifica"}
                        </Button>
                    </div>
                    {alertBox.enabled && (
                        <Alert className="text-center" variant={alertBox.mode}>
                            {alertBox.message}
                        </Alert>
                    )}
                </Col>
            </Row>
        </>
    )
}
