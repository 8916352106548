import React, { useEffect, useRef, useState, useTransition } from "react"
import { ListGroup, FormControl, InputGroup } from "react-bootstrap"
import { useDatabase } from "../../../../contexts/DatabaseProvider"
import { useDashboard } from "../../../../contexts/DashboardProvider"
import CircularProgress from "@mui/material/CircularProgress"
import { BsCircleFill } from "react-icons/bs"

export default function EasyOperatorsSidebar() {
    const { operators } = useDatabase()
    const { colorTheme, setSelectedOperator, selectedOperator } = useDashboard()
    const [searchOperator, setSearchedOperator] = useState("")
    const [filteredOperators, setFilteredOperators] = useState([])
    const searchOperatorRef = useRef(null)
    const [isPending, startTransition] = useTransition()

    const filterOperators = () => {
        const operatorsT = [...operators]
        const filteredOperators = operatorsT.filter((operator) => {
            return String(operator["name"]).toLowerCase().includes(searchOperator.toLowerCase())
        })
        setFilteredOperators(filteredOperators)
        if (filteredOperators.length === 1) {
            setSelectedOperator(filteredOperators[0])
        }
    }

    useEffect(() => {
        startTransition(() => {
            filterOperators()
        })
    }, [searchOperator])

    useEffect(() => {
        filterOperators()
    }, [operators])

    // console.log(filteredOperators)

    return (
        <>
            <div
                className="d-flex flex-row pt-4 ps-3 pe-3 text-center align-items-center justify-content-center"
                style={{ fontSize: "20px", fontWeight: "500", color: colorTheme.primaryColor, paddingBottom: "9px" }}
            >
                Operatori
            </div>

            <div className="d-flex flex-row align-items-center p-3 ps-4 pe-4" style={{ backgroundColor: colorTheme.thirdColor }}>
                <InputGroup>
                    <FormControl
                        autoFocus
                        ref={searchOperatorRef}
                        value={searchOperator}
                        onChange={(e) => setSearchedOperator(e.target.value)}
                        variant="secondary"
                        style={{ borderRadius: "20px", border: "0px" }}
                        placeholder="Cerca operatori"
                    />
                </InputGroup>
            </div>

            <div className="overflow-auto">
                {isPending ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <ListGroup className="d-flex flex-fill" variant="flush" style={{ fontSize: "18px" }}>
                        {filteredOperators.map((filteredOperator) => {
                            const selected = selectedOperator.id === filteredOperator.id
                            return (
                                <ListGroup.Item
                                    action
                                    key={filteredOperator.id}
                                    onClick={() => {
                                        setSelectedOperator(filteredOperator)
                                    }}
                                    active={selected}
                                    style={{ paddingLeft: "35px", paddingRight: "35px", backgroundColor: selected && colorTheme.primaryColor }}
                                >
                                    <div className="flex flex-row justify-between items-center">
                                        <div className="flex-fill text-base">{filteredOperator.first_name + " " + filteredOperator.last_name}</div>
                                        {filteredOperator.has_specialday_request && <BsCircleFill color={"limegreen"} size={10} /> }
                                    </div>
                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                )}
            </div>
        </>
    )
}
