import { faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { NavigationBar } from "../../easy-operators/EasyOperators"

export default function MultiTabModal({ showModal, modalPage, setModalPage, tabsData, customDimensions = undefined }) {
    const [selectedTabIdx, setSelectedTabIdx] = useState(0)
    const currentTab = tabsData[selectedTabIdx]

    const handleSizeModal = (modalPage) => {
        if (customDimensions) return currentTab.dimensions[modalPage - 1]
    }

    useEffect(() => {
        setSelectedTabIdx(0)
    }, [showModal])

    return (
        <>
            {showModal && (
                <div className="fixed bottom-0 left-0 right-0 top-0 z-30 flex items-center justify-center bg-black/30">
                    <div className="flex flex-col transition-size  duration-100 ease-in-out bg-gray-200 rounded-xl overflow-clip" style={handleSizeModal(modalPage)}>
                        <div className="flex flex-row justify-between">
                            <NavigationBar
                                titles={tabsData.map((x) => x.title)}
                                isSelected={(idx) => idx === selectedTabIdx}
                                onClick={(idx) => {
                                    setSelectedTabIdx(idx)
                                    setModalPage(1)
                                }}
                            />

                            <button
                                className="px-4"
                                onClick={() => {
                                    currentTab.cleanUpModal()
                                    setSelectedTabIdx(0)
                                }}
                            >
                                <FontAwesomeIcon icon={faXmark} size="xl" className="text-black/30 hover:text-black/50" />
                            </button>
                        </div>

                        <div className="flex flex-col   bg-white !px-6 !py-5 shadow-md  h-full">{currentTab.component}</div>
                    </div>
                </div>
            )}
        </>
    )
}
