import React, { useState } from "react"
import { Col } from "react-bootstrap"
import EasyRegister from "./main-panel/easy-register/EasyRegister"
import BillingSummary from "./main-panel/billings/BillingSummary"
import { useDashboard } from "../../contexts/DashboardProvider"
import EasyInventory from "./main-panel/easy-inventory/EasyInventory"
import EasyInsights from "./main-panel/easy-insights/EasyInsights"
import EasyCustomers from "./main-panel/easy-customers/EasyCustomers"
import EasyMessages from "./main-panel/easy-messages/EasyMessages"
import EasyOperators from "./main-panel/easy-operators/EasyOperators"
import EasyCalendar from "./main-panel/easy-calendar/EasyCalendar"

export default function MainPanel() {
    const {  dashboard, cashRegisterMode } = useDashboard()

    const getCashRegisterComponent = () => {
        if (cashRegisterMode === "new-billing") {
            return <EasyRegister />
        } else if (cashRegisterMode === "billings-list") {
            return <BillingSummary />
        }

        return
    }


    return (
        <div className={`relative flex flex-col w-full overflow-auto ${dashboard == "calendar" || "p-4"}`} style={{ backgroundColor: "whitesmoke" }}>
                {dashboard === "insights" && <EasyInsights />}
                {dashboard === "calendar" && <EasyCalendar />}
                {dashboard === "inventory" && <EasyInventory />}
                {dashboard === "customers" && <EasyCustomers />}
                {dashboard === "messages" && <EasyMessages />}
                {dashboard === "cash-register" && getCashRegisterComponent()}
                {dashboard === "operators" && <EasyOperators />}
        </div>
    )
}
