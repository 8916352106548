import { faXmark } from "@fortawesome/free-solid-svg-icons"
import { DateTime } from "luxon"
import React, { useEffect, useState } from "react"
import { Spinner } from "react-bootstrap"
import { useSalon } from "../../../../../contexts/SalonProvider"
import { formatTimeToMinHourString } from "../../../../../helper"
import { useDatabase } from "../contexts/DatabaseProvider"
import AlertBox from "../elements/AlertBox"
import InputBox from "../elements/InputBox"
import TimeSelector from "../elements/TimeSelector"
import {
    colors,
    defaultAlertInfo,
    defaultSelectedDayTimeInfo,
    getIndexofSelectedTimeslot,
    handleDictionaryChanges,
    isAvailableSelectionNumber,
    isSelectedSelectionNumber,
    onClickSelectionNumber,
} from "../utils/helpers"
import DateTimePicker from "./DateTimePicker"
import ShowEventModal from "./ShowEventModal"
import TopBarModal from "./TopBarModal"

function InfoEventSection({ className, infoCustomEvent, setInfoCustomEvent, alertInfo }) {
    const { bookingParameters } = useDatabase()

    function handleInfoCustomEventChange(
        key,
        value,
        isValueValid = () => {
            return true
        }
    ) {
        if (!isValueValid(value)) return

        handleDictionaryChanges("selectedInfo", { ...infoCustomEvent.selectedInfo, [key]: value }, setInfoCustomEvent)
    }

    return (
        <div className={className}>
            <div className="flex flex-col space-y-5">
                {/* <div className="-mb-3 !ms-2 text-lg ">Informazioni Evento</div> */}
                <div className="flex flex-row space-x-5 ">
                    <InputBox
                        className="grow"
                        label={"Nome"}
                        placeholder={"Pausa pranzo"}
                        value={infoCustomEvent.selectedInfo.name}
                        onChange={(e) => {
                            handleInfoCustomEventChange("name", e.target.value)
                        }}
                    />

                    <TimeSelector value={infoCustomEvent.selectedInfo.duration} setValue={(duration) => handleInfoCustomEventChange("duration", duration)} slotSize={bookingParameters.SLOT_SIZE}>
                        <div className={"flex flex-col "}>
                            <div className="!ms-3 text-xs text-black/70 ">Durata</div>
                            <div className={`flex flex-row rounded-2xl text-sm outline outline-1 outline-slate-300 h-[40px] items-center justify-center px-2 w-[100px]`}>
                                {formatTimeToMinHourString(infoCustomEvent.selectedInfo.duration)}
                            </div>
                        </div>
                    </TimeSelector>
                    {/* <InputBox
                        className="w-[33%]"
                        classNameInput="text-end "
                        label={"Durata"}
                        placeholder={"45"}
                        value={infoCustomEvent.selectedInfo.duration}
                        suffix={"min"}
                        onChange={(e) => {
                            handleInfoCustomEventChange("duration", e.target.value, onlyNumericValue)
                        }}
                    /> */}
                </div>
                <InputBox
                    label={"Descrizione"}
                    placeholder={"Inserisci una descrizione evento"}
                    value={infoCustomEvent.selectedInfo.description}
                    onChange={(e) => {
                        handleInfoCustomEventChange("description", e.target.value)
                    }}
                    className="h-40"
                    isTextArea={true}
                />
                <AlertBox className="!mt-2" msg={alertInfo.msg} showStatus={alertInfo.status} />
            </div>
        </div>
    )
}

function DateTimeSection({ className, infoCustomEvent, setInfoCustomEvent, selectedCustomEvent, calendarOperator }) {
    const { bookingParameters } = useDatabase()
    const SLOT_SIZE = bookingParameters.SLOT_SIZE

    function initialCalendarDate(timeslot) {
        // console.log(timeslot)
        return timeslot === "not-selected" ? DateTime.now().startOf("month") : DateTime.fromMillis(timeslot).startOf("month")
    }

    const { retrieveMonthlyFreeTimeslots, monthlyFreeTimeslots } = useDatabase()
    const [calendarDate, setCalendarDate] = useState(initialCalendarDate(infoCustomEvent.selectedDayTimeInfo.timeSlot))

    useEffect(() => {
        if (infoCustomEvent.selectedInfo.duration !== "") {
            retrieveMonthlyFreeTimeslots(calendarDate, SLOT_SIZE, calendarOperator.id, undefined, selectedCustomEvent.id)
        }
    }, [calendarDate])

    if (infoCustomEvent.selectedInfo.duration === "") {
        return <div className="flex grow items-center justify-center text-2xl tracking-normal text-black/20"> Seleziona durata evento</div>
    }

    return (
        <DateTimePicker
            className={className}
            calendarDate={calendarDate}
            setCalendarDate={setCalendarDate}
            infoData={infoCustomEvent}
            setInfoData={setInfoCustomEvent}
            availableTimeslots={monthlyFreeTimeslots}
            isAvailableSelectionNumber={isAvailableSelectionNumber}
            isSelectedSelectionNumber={isSelectedSelectionNumber}
            onClickSelectionNumber={onClickSelectionNumber}
        />
    )
}

function CreateEditCustomEvent({ props }) {
    const { closeModal, selectedCustomEvent, infoCustomEvent, setInfoCustomEvent, calendarOperator, refreshDataCalendar } = props
    const { postCustomEvent, updateCustomEvent, deleteCustomEvent, monthlyFreeTimeslots } = useDatabase()
    const { selectedSalon } = useSalon()
    const [alertInfo, setAlertInfo] = useState(defaultAlertInfo())
    const [isProcessingSave, setIsProcessingSave] = useState(false)
    const [isProcessingDelete, setIsProcessingDelete] = useState(false)
    const [showModal, setShowModal] = useState(false)

    async function saveCustomEventDatabae(infoCustomEvent) {
        if (!validCustomEventInfo(infoCustomEvent.selectedInfo)) return

        const customEvent = {
            ...infoCustomEvent.selectedInfo,
            start_time: DateTime.fromMillis(infoCustomEvent.selectedDayTimeInfo.timeSlot).toISO(),
            operator: calendarOperator.id,
            salon: selectedSalon.id,
        }
        setIsProcessingSave(true)

        let response
        if (selectedCustomEvent === "not-selected") {
            response = await postCustomEvent(customEvent)
        } else {
            response = await updateCustomEvent(customEvent, selectedCustomEvent.id)
        }
        setIsProcessingSave(false)

        if ("id" in response) {
            await refreshDataCalendar()
            closeModal()
        } else {
            setAlertInfo({ status: true, msg: JSON.stringify(response) })
        }
    }

    async function deleteCustomEventDatabase() {
        setIsProcessingDelete(true)
        await deleteCustomEvent({ deleted: true }, selectedCustomEvent.id)
        await refreshDataCalendar()
        setIsProcessingDelete(false)
        closeModal()
    }

    function validCustomEventInfo(customEvent) {
        if (Number(customEvent.duration) <= 0) {
            setAlertInfo({ status: true, msg: "La durata dell'evento deve essere maggiore di 0 minuti" })
            return false
        }

        return true
    }

    function isInfoComplete(infoCustomEvent) {
        const checkKeys = ["name", "duration"]

        for (const key of checkKeys) {
            if (infoCustomEvent.selectedInfo[key] === "") return false
        }

        const {availableTimeslot} = getIndexofSelectedTimeslot(monthlyFreeTimeslots, infoCustomEvent)
        return availableTimeslot
    }

    return (
        <>
            <TopBarModal title={selectedCustomEvent === "not-selected" ? "Nuovo Evento" : "Modifica Evento"} />
            <div className="!mt-4 flex w-full h-full flex-row divide-x divide-black/5">
                <InfoEventSection infoCustomEvent={infoCustomEvent} setInfoCustomEvent={setInfoCustomEvent} alertInfo={alertInfo} className="flex w-[50%] gap-2 !pe-10" />

                <DateTimeSection
                    className="flex w-[50%] flex-col pl-8"
                    infoCustomEvent={infoCustomEvent}
                    setInfoCustomEvent={setInfoCustomEvent}
                    selectedCustomEvent={selectedCustomEvent}
                    calendarOperator={calendarOperator}
                />
            </div>
            <div className="!mt-5 flex w-full  items-center justify-center space-x-4">
                {selectedCustomEvent !== "not-selected" && (
                    <>
                        <button onClick={() => setShowModal(true)} className="flex h-10 w-64 items-center justify-center rounded-xl bg-black/10 ">
                            Elimina
                        </button>
                        <ShowEventModal showModal={showModal}>
                            <TopBarModal closingIcon={faXmark} closeModal={() => setShowModal(false)} title={"Cancella evento"} classNameTitle="text-lg tracking-tight" />
                            <div className="mt-2 leading-5 w-64">Sei sicuro di voler cancellare questo evento?</div>
                            <div className="!mt-5 flex w-full  items-center justify-center space-x-4">
                                <button
                                    disabled={isProcessingDelete}
                                    className="flex h-10 w-full items-center justify-center rounded-xl bg-red-600 text-white"
                                    onClick={() => deleteCustomEventDatabase()}
                                >
                                    {isProcessingDelete && <Spinner className="me-2" as="span" animation="border" size="sm" role="status" />}
                                    Elimina
                                </button>
                            </div>
                        </ShowEventModal>
                    </>
                )}
                <button
                    className="flex h-10 w-64 items-center justify-center rounded-xl text-white"
                    style={{ backgroundColor: isInfoComplete(infoCustomEvent) ? colors.primary : "lightgray" }}
                    disabled={!isInfoComplete(infoCustomEvent) || isProcessingSave}
                    onClick={() => saveCustomEventDatabae(infoCustomEvent)}
                >
                    {isProcessingSave && <Spinner className="me-2" as="span" animation="border" size="sm" role="status" />}
                    Salva
                </button>
            </div>
        </>
    )
}

export default function CustomEventModal({ closeModal, selectedCustomEvent, calendarOperator, refreshDataCalendar, calendarAccessedMode }) {
    const defaultCustomEvent = {
        selectedInfo: { name: "", duration: 45, description: "" },
        selectedDayTimeInfo: defaultSelectedDayTimeInfo,
    }
    const [infoCustomEvent, setInfoCustomEvent] = useState()

    useEffect(() => {
        if (selectedCustomEvent !== "not-selected") {
            const customEventDate = DateTime.fromISO(selectedCustomEvent.start_time)
            const selectedDayTimeInfo = { dayTimestamp: customEventDate.startOf("day").toMillis(), hour: customEventDate.hour, timeSlot: customEventDate.toMillis() }

            setInfoCustomEvent({
                selectedInfo: { name: selectedCustomEvent.name, duration: selectedCustomEvent.duration, description: selectedCustomEvent.description },
                selectedDayTimeInfo: selectedDayTimeInfo,
            })
        } else if (calendarAccessedMode.mode === "click") {
            setInfoCustomEvent({ ...defaultCustomEvent, selectedDayTimeInfo: calendarAccessedMode.payload })
        }
    }, [selectedCustomEvent])

    const props = {
        infoCustomEvent: infoCustomEvent,
        setInfoCustomEvent: setInfoCustomEvent,
        closeModal: closeModal,
        selectedCustomEvent: selectedCustomEvent,
        calendarOperator: calendarOperator,
        refreshDataCalendar: refreshDataCalendar,
    }

    // console.log(infoCustomEvent?.selectedDayTimeInfo)
    return <>{infoCustomEvent && <CreateEditCustomEvent props={props} />}</>
}
